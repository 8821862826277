import { useEffect, useState } from "react";
import { FirstOnboarding } from "./FirstOnboarding";
import { SecondOnboarding } from "./SecondOnboarding";
import { ThirdOnboarding } from "./ThirdOnboarding";

const timerSet = 3000

const onBoardScreen = {
    0: <FirstOnboarding />,
    1: <SecondOnboarding />,
    2: <ThirdOnboarding />,
}

export function OnboardingFlow(){
    const [currentState, setCurrentState] = useState(0)

    useEffect(()=> {
       const timer = setTimeout(()=>{
            if(currentState === 2){
                setCurrentState(0);
            } else {
                setCurrentState((prev) => prev + 1);
            }
        }, timerSet)

        return () => {
            clearTimeout(timer.current);
          };
    },[currentState])

    return(
        <>
        {onBoardScreen[currentState]}
        </>
    )
}