import { Stack, Typography, CircularProgress } from '@mui/material';
import FormModal from '../../../../../Widgets/Animation/FormModal';
import { font } from '../../../../../Widgets/Styles/font';
import { OutlinedButton, FillButton } from '../../../../../Widgets/FormUtils/FormUtils';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '329px',
  height: '198px',
  // border: '2px solid #000',
  borderRadius: '2px',
  overflowY: 'auto',
  overflowX: 'hidden',
};

function ConfirmDeleteShiftType({ open = false, handleClose=()=>{}, isLoading = false, handleSubmit=()=>{}}){
  return (
    <FormModal open={open} handleClose={handleClose}>
      <Stack sx={style}>
        <div className='flex flex-col bg-white dark:bg-138 h-full px-4 py-2 justify-center items-center w-full'>

          <p className='dark:text-white text-[#FF1B1B] font-bold text-[20px]' >
              Delete Shift Template
            </p>

            <p className="text-center text-grey-800 mt-2 dark:text-white">Are you sure you want to delete the Shift type?</p>
            <div
              className='flex justify-between mt-6 w-full'
              
            >
              <OutlinedButton
                text="Cancel"
                className='dark:border-333 dark:text-white'
                callBack={() => {
                  handleClose();
                }}
              />
              {!isLoading && (
                <FillButton text="Delete" callBack={handleSubmit} />
              )}

              {isLoading && <CircularProgress />}
            </div>
        </div>
      </Stack>

    </FormModal>
  )
}

export default ConfirmDeleteShiftType