import { Modal } from "@material-ui/core";
import { Stack } from "@mui/material";



export function SignUpModal({ open = false, handleClose }) {
  return (
    <Modal open={open} onClose={handleClose}>
      <Stack
       direction="column"
       alignItems="center"
       sx={{ height: '100vh' }}
       justifyContent="center"
      >
        <div className="px-[20px] flex flex-col rounded-[11px] justify-end py-[50px] bg-white w-[612px] h-[335px] text-center">
          <p className="text-[28px]">Thank you for signing up with us.</p>
          <p className="text-base text-A6A">Please keep an eye on your inbox for important emails regarding your onboarding process.</p>
        </div>
      </Stack>
    </Modal>
  )
}