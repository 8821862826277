/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { SideBar } from '../../../Widgets/SideBar';
import { useNavigate } from 'react-router';
import { BsArrowLeft, BsSearch } from 'react-icons/bs';
import { BranchesHeader } from '../../BranchScreen/BranchesHeader/BranchesHeader';
import CustomButton from '../../../Widgets/CustomButton';
import { useEffect } from 'react';
import {
  getBranchesAsync,
  fetchJobRolesAsync,
  bulkInviteAsync,
} from '../../../../Store/Branches';
import CustomDropdown from '../../../Widgets/CustomDropdown';
import { FiMoreHorizontal } from 'react-icons/fi';
import ConfirmInvite from './Modal/ConfirmInvite';
import EditTeamInfo from './Modal/EditTeamInfo';
import { toast } from 'react-toastify';
import LiveUsers from '../../LiveUsers/LiveUsers';
import { NewSideBar } from '../../../Widgets/NewSideBar';

const rowStyle = 'py-4 px-2 border-y-1 border-9FD';
const ViewInvitesScreen = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [employeeDetails, setEmployeeDetails] = useState({})
  const [openModal, setOpenModal]= useState(false)
  const [openEditModal, setOpenEditModal]= useState(false)
  const [searchItem, setSearchItem] = useState('');
  const invites = useSelector((state) => state.branches)?.invitedTeams;
  const branches = useSelector((state) => state.branches)?.branches;
  const jobRoles = useSelector((state) => state.branches)?.jobRoles;
  const isLoading = useSelector((state) => state.branches)?.loading;
  const [storeInvites, setStoreInvites] = useState(invites);

  useEffect(() => {
    dispatch(getBranchesAsync());
    dispatch(fetchJobRolesAsync());
    dispatch(getBranchesAsync());
  }, []);

  const handleBranchBack = () => {
    navigate('/branches');
  };

  const handleSendInvite = () => {
    dispatch(bulkInviteAsync({members: storeInvites})).then((res)=> {
      if(res?.payload?.status === 400){
        toast.error(res?.payload?.data?.message);
      }

      if(res?.payload?.status === 200){
        toast.success('Invites have been sent successfully');
        setOpenModal(false);
        handleBranchBack();
      }

    });
  }

  const handleSearchAction = (e) => {
    setSearchItem(e.target.value);
  };

  const handleFilterItem = (itemToSearch) => {
    const filtered = storeInvites?.filter(
      (invite) =>
        invite?.email?.includes(itemToSearch) ||
        invite?.name?.includes(itemToSearch)
    );
    return filtered;
  };

  const handleSelectOption = (con, prop, val) => {
    const updateData = storeInvites.map((newData) => {
      if (newData.email === con) {
        if(Array.isArray(prop)){
          return {
            ...newData,
            ...prop[0],
          }
        }
        return {
          ...newData,
          [prop]: val,
        };
      }
      return newData;
    });

    setStoreInvites(updateData);
  };

  const handleOpenOptionMoreClick = (data) => {
    setEmployeeDetails(data);
    setOpenEditModal(true);
  }

  const handleCloseOptionMoreClick = () => {
    setOpenEditModal(false);
  }

  return (
    <>
      <section>
        <NewSideBar customTitle="Company">
          <div
            onClick={handleBranchBack}
            className="cursor inline-flex items-center rounded border-1 border-black p-1"
          >
            <BsArrowLeft /> <span className="ml-2">Back</span>
          </div>
          <div className='relative'>
            <LiveUsers/>
          </div>
          <BranchesHeader
            title="Invite team members"
            subtitle="Invite team members to the company"
          />
          <div className="flex justify-end w-full">
            <CustomButton
              onClick={() => setOpenModal(true)}
              className="bg-base-color text-white w-[162px] h-[50px]"
              title="Send invites"
            />
          </div>

          <div className="rounded border-1 border-9FD px-[26px] py-[40px] mt-[17px] mb-[20px]">
            <div className="flex items-center mb-[33px]">
              <div className="flex items-center rounded border-1 border-9FD dark:bg-828 w-[245px] p-[10px]">
                <BsSearch className="mr-3 dark:text-white" />
                <input
                  onChange={handleSearchAction}
                  type="text"
                  className="outline-none"
                  placeholder="Search location name"
                />
              </div>
              <p className="font-medium text-sm text-A5A border-1 border-9FD p-[5px] ml-[32px] rounded">
                Total:{' '}
                <span className="text-black font-semibold">
                  {handleFilterItem(searchItem)?.length}
                </span>
              </p>
            </div>
            <table>
              <thead className=" bg-0F5">
                {[
                  'Employee name',
                  'Email',
                  'Assigned branch',
                  'Privilege',
                  'Job Role',
                  '',
                ]?.map((item) => (
                  <th className="py-[24px] text-left pl-2">{item}</th>
                ))}
              </thead>
              <tbody className="border-1">
                {handleFilterItem(searchItem)?.map((data, index) => (
                  <tr>
                    <td className={rowStyle}>{data?.name}</td>
                    <td className={rowStyle}>{data?.email}</td>
                    <td className={rowStyle}>
                      <CustomDropdown
                        optionClass="z-10"
                        dropdownTitleClass="text-sm text-black"
                        onChangeOption={(val) =>
                          handleSelectOption(
                            data?.email,
                            'assignedBranchId',
                            val?.value
                          )
                        }
                        value={{
                          label:
                            branches?.data?.filter(
                              (y) =>
                                y.id ===
                                storeInvites?.filter(
                                  (x) => x.email === data?.email
                                )[0]?.assignedBranchId
                            )[0]?.name || 'Assign branch',
                          value:
                            storeInvites?.filter(
                              (x) => x.email === data?.email
                            )[0]?.assignedBranchId || 'Select',
                        }}
                        data={branches?.data?.map((item) => ({
                          label: item.name,
                          value: item.id,
                        }))}
                      />
                    </td>
                    <td className={rowStyle}>
                      <CustomDropdown
                        dropdownTitleClass="text-sm text-black"
                        onChangeOption={(val) =>
                          handleSelectOption(
                            data?.email,
                            'privilege',
                            val?.value
                          )
                        }
                        optionClass="z-10"
                        value={{
                          label:
                            [
                              { id: 1, name: 'Super Admin' },
                              { id: 2, name: 'Admin' },
                              { id: 3, name: 'Member' },
                            ].filter(
                              (y) =>
                                y.id ===
                                storeInvites?.filter(
                                  (x) => x.email === data?.email
                                )[0]?.privilege
                            )[0]?.name || 'Assigned privilege',
                          value:
                            storeInvites?.filter(
                              (x) => x.email === data?.email
                            )[0]?.privilege || 'Select',
                        }}
                        data={[
                          { id: 1, name: 'Super Admin' },
                          { id: 2, name: 'Admin' },
                          { id: 3, name: 'Member' },
                        ]?.map((item) => ({
                          label: item.name,
                          value: item.id,
                        }))}
                      />
                    </td>
                    <td className={rowStyle}>
                      <CustomDropdown
                        dropdownTitleClass="text-sm text-black"
                        onChangeOption={(val) =>
                          handleSelectOption(
                            data?.email,
                            'jobRoleId',
                            val?.value
                          )
                        }
                        value={{
                          label:
                            jobRoles?.filter(
                              (y) =>
                                y.id ===
                                storeInvites?.filter(
                                  (x) => x.email === data?.email
                                )[0]?.jobRoleId
                            )[0]?.title || 'Assigned job roles',
                          value:
                            storeInvites?.filter(
                              (x) => x.email === data?.email
                            )[0]?.jobRoleId || 'Select',
                        }}
                        optionClass="z-10 h-[100px] overflow-y-scroll"
                        data={jobRoles?.map((item) => ({
                          label: item.title,
                          value: item.id,
                        }))}
                      />
                    </td>
                    <td className={rowStyle}>
                      <FiMoreHorizontal
                        onClick={(e) => {
                          e.stopPropagation();
                          handleOpenOptionMoreClick(data);
                        }}
                        className="cursor-pointer"
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            <ConfirmInvite
              submitInvite={handleSendInvite}
              open={openModal}
              setOpenModal={setOpenModal}
              isLoading={isLoading}
            />
            <EditTeamInfo
              handleSelectOption={handleSelectOption}
              open={openEditModal}
              setOpenModal={setOpenEditModal}
              branches={branches}
              storeInvites={storeInvites}
              jobRoles={jobRoles}
              data={employeeDetails}
              handleClose={handleCloseOptionMoreClick}
            />
          </div>
        </NewSideBar>
      </section>
    </>
  );
};

export default ViewInvitesScreen;
