import React, { useState, useEffect } from 'react';
import { SketchPicker } from 'react-color';
import { OutlinedButton } from './FormUtils/FormUtils';

const ColorPicker = ({
  onChangeColor,
  openPicker = false,
  defaultColor = '#000000',
}) => {
  const [color, setColor] = useState(defaultColor);
  const [showPicker, setShowColorPicker] = useState(openPicker);

  const handleColorChange = (newColor) => {
    setColor(newColor.hex);
    onChangeColor(newColor.hex);
  };

  useEffect(() => {
    onChangeColor('#000000');
  }, []);

  return (
    <div className="w-full">
      {/* <p className="text-[12px] font-[500]">Color Picker</p> */}
      {showPicker && (
        <SketchPicker color={color} onChange={handleColorChange} />
      )}
      <div className="w-full  mt-1">
        <div className="flex items-center gap-3 mb-3">
        <p className="text-[12px]">
          Default Color: 
        </p>
        <div style={{ backgroundColor: color, width: "20px", height: "20px" }}></div>
        <p>{color}</p>
        </div>
      
        <OutlinedButton
          themeColor={color}
          text={!showPicker ? 'Show color picker' : 'Hide picker'}
          callBack={() => setShowColorPicker(!showPicker)}
        />
        
      </div>
    </div>
  );
};

export default ColorPicker;
