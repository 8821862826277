import React from 'react'

import { Stack } from '@mui/material'
import FormModal from '../../../../../Widgets/Animation/FormModal';
import { Loader } from '../../../../../Widgets/Loader';


const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',

  maxHeight: '90vh',
  width: '400px',
  bgcolor: 'background.paper',
  // border: '2px solid #000',
  boxShadow: 24,
  p: 4,
  borderRadius: '5px',
  overflowY: 'auto',
  overflowX: 'hidden'
};

export const ConfirmShiftModal = ({ open, handleClose, loading, callback }) => {

  const handleConfirmShift = () => {
    callback()
  }

  return (
    <FormModal open={open} handleClose={handleClose}>
      <Stack className='dark:bg-138 dark:text-white' direction="column" alignItems="center" sx={style} gap={3} >
        <div>
          <p className="font-medium text-lg">Are you sure you want to create a shift with default template ?</p>
          {!loading ? (<div className="flex items-center justify-between mt-10 h-[40px] gap-3">
            <button onClick={handleClose} className="rounded w-full h-full border border-color-424">No</button>
            <button onClick={handleConfirmShift} className="bg-171 text-white h-full font-semibold rounded w-full">Yes</button>
          </div>): <Loader />}
        </div>
      </Stack>
    </FormModal>
  )
}