import React,{useEffect, useState} from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { CircularProgress } from '@mui/material';

import { useDispatch } from 'react-redux';
import FormModal from '../../Widgets/Animation/FormModal';
import { toast } from 'react-toastify';
import { fetchJobRolesAsync, getBranchesAsync } from '../../../Store/Branches';
import { TextAndField, Text, Field, FieldSelect, FillButton, OutlinedButton } from '../../Widgets/FormUtils/FormUtils';
import { Storage } from '../../../Network/StorageClass/StorageClass';
import { createHolidayThunk, getHolidaysNoLoadingThunk } from '../../../Store/holiday';

const storage = new Storage()
function CreateHoliday({ open, handleClose, callback }) {

    const dispatch = useDispatch()
    const [branches, setBranches] = useState([])
    const [activeCompany, setActiveCompany] = useState(storage.getActiveCompany()?.id)
    const [isLoading, setisLoading] = useState(false)




    const formik = useFormik({
        initialValues: {
            name: '',
            branchId: branches[0]?.id ,
            companyId:  activeCompany,
            date:''
        },
        validationSchema: Yup.object({
            name: Yup.string().min(2, "Holiday name is too short")
                .required('Please enter holiday name'),
            date: Yup.string().required()    
        }),
       
        onSubmit: (value,action) => {
            setisLoading(true)
            dispatch(createHolidayThunk(value))
            .then(val => {
                if(val.payload?.response?.status === 400){
                    toast.error(val.payload?.response?.data?.message)
                }else if(val.payload?.status === 200){
                    toast.success('Holiday Created')
                    dispatch(getHolidaysNoLoadingThunk())
                    action.resetForm()
                    handleClose()
                }else {
                    toast.error('Try again')

                }
                setisLoading(false)
            }).catch(err => {
                setisLoading(false)})
        }
    })

   

    useEffect(()=>{
        setActiveCompany(storage.getActiveCompany()?.id)
        dispatch(getBranchesAsync())
        .then(val => {
            setBranches(val?.payload?.data)
        })

    },[])

   


    return (
       <FormModal open={open} handleClose={handleClose}>
           <div className='bg-[white] h-[52vh] overflow-y-auto flex flex-col items-center gap-2 px-10 py-4 mt-[10%] rounded-md w-[400px] m-auto dark:bg-138'>

                <p className='font-semibold mb-2 text-[22px] dark:text-white'>Create Holiday</p>

                <TextAndField space={0.5}>
                    <Text className='dark:text-white' text={'Holiday'}/>
                    <Field className='dark:border-333 dark:border-solid dark:border' text={'Enter Holiday'} 
                        value={formik.values.name} 
                        formik={formik} 
                        name='name' width={'300px'}/>
                </TextAndField>

                <TextAndField space={0.5}>
                    <Text className='dark:text-white' text={'Location'}/>
                    <FieldSelect className='dark:border-333 dark:border-solid dark:border' list={branches} 
                        value={formik.values.branchId} 
                        formik={formik} name='branchId'
                        width={'320px'}/>
                </TextAndField>

               
                <TextAndField space={0.5}>
                    <Text className='dark:text-white' text={'Date'}/>
                    <Field className='dark:border-333 dark:border-solid dark:border' text={'Enter Date'} 
                        value={formik.values.date} 
                        formik={formik} 
                        name='date' type={'date'}
                        width={'300px'}/>
                </TextAndField>

                <div className='w-full flex justify-between mt-4'>
                    <OutlinedButton className='dark:border-333 dark:text-white'  text={'Cancel'} callBack={()=>{callback(); formik.resetForm()}}/>
                    {!isLoading && <FillButton text={'Submit'} callBack={formik.handleSubmit}/>}
                    {isLoading && <CircularProgress/>}
                </div>

           </div>
       </FormModal>
    )
}

export default CreateHoliday