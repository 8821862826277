import React from 'react';
import CustomButton from '../../../../Widgets/CustomButton';
import CustomModal from '../../../../Widgets/CustomModal';
import CustomDropdown from '../../../../Widgets/CustomDropdown';
import { useSelector } from 'react-redux';
import { Formik, Form } from 'formik';
import { Loader } from '../../../../Widgets/Loader';
import FormModal from '../../../../Widgets/Animation/FormModal';
import { toast } from 'react-toastify';

const CreateWriteUpModal = ({ handleClose, open, submitWriteup, loading }) => {
  const [selectedOption, setSelectedOption] = React.useState({
    label: 'Select',
    value: 'Select',
  });
  const store = useSelector((state) => state.writeup);

  const handleOnChange = (val) => {
    setSelectedOption(val);
  };

  return (
    <CustomModal
      modalClassName="max-w-[600px] pt-10 "
      open={open}
      handleClose={handleClose}
    >
      <Formik
        initialValues={{
          userId:
            selectedOption.value === 'Select' ? '' : selectedOption.value || '',
          title: `Writeup ${selectedOption.value}`,
          body: '',
        }}

        validate={(values) => {
          const errors = {};

          if (values.userId?.length < 1) {
            errors['userId'] = 'userId is required';
          }

          if (values.body?.length < 1) {
            errors['body'] = 'Description is required';
          }

          return errors;
        }}
        onSubmit={(values) => {
          setTimeout(() => {
            const data = {
              ...values,
            }
            submitWriteup(data)
            toast('New write up')
            setSelectedOption({ label: 'Select', value: 'Select' });
          }, 400);
        }}
      >
        {({ errors, setFieldValue }) => (
          <Form>
            <div className="flex flex-col justify-between min-h-[300px] dark:bg-138 dark:text-white">
              <div>
                <div className="flex items-center justify-between mb-7">
                  <p className="font-medium text-[22px]"> Write up </p>
                  <div className="w-[40%]">
                    <p className="font-medium text-[10px] text-767">Assigned to</p>
                    <CustomDropdown
                      optionClass='h-[250px] overflow-y-auto'
                      data={store?.members?.map((member) => ({
                        label: `${member.user.firstName} ${member.user.lastName}`,
                        value: member.user.id,
                      }))}
                      onChangeOption={(e) => {
                        handleOnChange(e);
                        setFieldValue('userId', e.value);
                      }}
                      value={selectedOption}
                    />
                    {errors.userId && (
                      <p className="text-[8px] text-red-500">
                        {errors.userId}
                      </p>
                    )}
                  </div>
                </div>
                <textarea
                  placeholder="type your writeup ..."
                  className="border-0 w-full mb-7 h-[150px] outline-none dark:bg-138"
                  onChange={(e) => setFieldValue('body', e.target.value)}
                />
                {errors.body && (
                  <p className="text-[8px] text-red-500">
                    {errors.body}
                  </p>
                )}
              </div>
              <hr />
              {loading ? <Loader /> :(<div className="flex justify-end mt-2">
                <CustomButton
                  onClick={handleClose}
                  className="border-black bg-white text-black text-semibold text-sm mr-[10px] py-3 px-5 dark:border-333 dark:bg-138 dark:text-white"
                  showIcon={false}
                  title="Cancel"
                />
                <CustomButton
                  className="!border-none bg-base-color text-white text-semibold text-sm py-3 px-5"
                  showIcon={false}
                  title="Send"
                  type="submit"
                />
              </div>)}
            </div>
          </Form>
        )}
      </Formik>
    </CustomModal>
  );
};

export default CreateWriteUpModal;
