import {ReactComponent as LoginTable} from '../../../Assets/login_table.svg'


export function FirstOnboarding(){
    return (
        <div className='flex items-center flex-col justify-center'>
            <LoginTable className='w-[50%]' />
            <div className='p-10 bg-white'>
                <p className="text-[40px] font-semibold">Inventory Control</p>
                <p className="text-2xl text-272">With our system, you can effortlessly track and manage your stock levels, streamline purchasing processes, and optimize warehouse operations.</p>
                <div className='mt-2 flex items-center gap-2'>
                    <div className='w-[39px] h-[8px] bg-D57 rounded-3xl' />
                    <div className='h-2 w-4 bg-7E0 rounded-3xl' />
                    <div className='h-2 w-4 bg-7E0 rounded-3xl' />
                    <div />
                </div>
            </div>
        </div>
    )
}