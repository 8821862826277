import {useState} from 'react'

import CustomModal from '../../../Widgets/CustomModal'
import {AiOutlineArrowLeft, AiOutlineArrowRight} from 'react-icons/ai'


const ViewDocs = ({openModal, handleCloseModal, data}) => {

  const [attachementList, setAttachmentList] = useState(data?.attachments)
  const [selectedIndex, setSelectedIndex] = useState(0)

  const checkDocType = (name) => {
    return name.split('.').reverse()[0].toLowerCase()
  }

  return (
    <div>
        <CustomModal 
            modalClassName = 'w-[70%] dark:bg-138'
            open={openModal} 
            handleClose={()=>{
                handleCloseModal()
            }}>
              <div className='w-full h-full flex flex-col gap-4 items-center'>

                <div className='flex items-center gap-3 w-full'>
                  <ArrowButton 
                    handleClick={() => {
                      if(selectedIndex >= 1){
                        setSelectedIndex(selectedIndex - 1)
                      }
                    }}
                    Icon={AiOutlineArrowLeft}
                  />

                  <div className='w-full h-[70vh]'>

                    {checkDocType(attachementList[selectedIndex]?.url) === 'pdf' ?
                      <PDFViewer pdfUrl={attachementList[selectedIndex]?.url}/>
                      :
                    <img 
                      className='h-full' 
                      src={attachementList[selectedIndex]?.url} 
                      alt="" />
                    }
                  </div>

                  <ArrowButton 
                    handleClick={() => {
                      if(selectedIndex < (attachementList.length - 1)){
                        setSelectedIndex(selectedIndex + 1)
                      }
                    }}
                    Icon={AiOutlineArrowRight}
                  />
                </div>

                <div className='flex gap-2'>

                  {attachementList.map((_,index) => (
                    <p
                      onClick={()=> setSelectedIndex(index)} 
                      className={`w-3 h-3 rounded-full ${selectedIndex === index ? 'bg-blue-600' : 'border-1 border-gray-500'} `} />
                  ))}
                </div>
              </div>
        </CustomModal>
    </div>
  )
}

export default ViewDocs

const ArrowButton = ({Icon, handleClick=()=>{}}) => {
  return (
    <div 
      onClick={handleClick}
      className='px-3 py-3 rounded-full cursor-pointer hover:bg-gray-100'>
        <Icon size={18} />
    </div>
  )
}

const PDFViewer = ({ pdfUrl }) => {
  
  return (
        <iframe 
          className='h-full w-full'  
          style={{ objectFit: "fill" }}
          src={pdfUrl} 
          title='File'
          >
          
        </iframe>
   
  );
}