import {ReactComponent as ForgotIcon} from "../../../Assets/forgot_password_logo.svg"
import {ReactComponent as NewSupserLogoIcon} from "../../../Assets/new_sync_sync_logo.svg"
import { FaArrowLeftLong } from "react-icons/fa6";
import { Field, FillButton, Text, TextAndField } from "../../Widgets/FormUtils/FormUtils";
import { useFormik } from "formik";
import * as Yup from 'yup';
import { useNavigate } from "react-router";
import { useState } from "react";
import { Service } from "../../../Network/ServiceClass/Auth";
import { CircularProgress, Stack, Box, Typography } from '@mui/material';
import {MdOutlineCancel} from 'react-icons/md'
import { font } from "../../Widgets/Styles/font";
import "./ForgottenPassword.css"
import { ForgotPasswordModal } from "./Widget/ForgotPasswordModal";

const service = new Service();

export function NewPasswordPage(){

    const navigate = useNavigate();
    const [modalStatus, setModalStatus] = useState(false)

    const [isLoading, setIsLoading] = useState(false);
    const [isError, setIsError] = useState(false)
    const [errorMessage, setErrorMessage] = useState('')


    const formik = useFormik({
        initialValues:{
          email:"",
        },
    
        validationSchema: Yup.object({
          email: Yup.string().email('Please enter valid email').required("Please enter email")
        }),
        onSubmit:(value)=>{
          ForgotPassword(value.email)
        }
      }) 

      const ForgotPassword = (email)=>{
        if(email!==""){
        setIsLoading(true);
        service.ForgotPassword(email).then((value)=>{
            setIsLoading(false);
            setErrorMessage(value.data.message);
            handleOpenModal()
            setIsError(true)            
        }).catch((err)=>{
            console.log(err); 
            setIsLoading(false);
            setErrorMessage(err.data.message);
            setIsError(true)
        });
     }
    }

    const handleOpenModal = () => {
        setModalStatus(true)
      }

      const handleCloseModal = () => {
        setModalStatus(false)
      }

    return (
        <section className="w-screen h-full flex items-center">
            <div className="w-1/2 h-full bg-forgot-bg bg-no-repeat bg-cover">
            <ForgotIcon className="ml-10 mt-4" />
            </div>
            <div className="w-1/2 h-full flex justify-center items-center">
            <div className="rounded-[30px] shadow-small p-16 w-[648px] h-[725px] flex items-center justify-start ml-[-60%] z-50 bg-white rounded-input">

                <div>
                <div className="flex items-center gap-2 mb-10 cursor-pointer">
            <FaArrowLeftLong className="cursor-pointer" onClick={()=>navigate(-1)} />
            <p>Back</p>
            </div>

            <NewSupserLogoIcon />

            <p className="text-[32px] my-4 font-semibold font-jakarta">Forgot Password</p>
            <p className="text-sm font-medium text-A6A">Having trouble remembering your password? No worries, it happens to the best of us! Let's get you back into your account quickly. </p>

            <div className="my-8">
            <TextAndField>
                    <Text text={"Enter your email address"} />
                    <Field text={'Enter email address'}
                    value={formik.values.email}
                    handleChange={formik.handleChange}
                    name={'email'}
                    formik={formik} size="large"/>
                </TextAndField>
            </div>

            {isError && 
                <Box sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    borderRadius: '0.2rem',
                    boxShadow: '0 3px 10px rgb(0 0 0 / 0.2)',
                    padding: '0.5rem'
                }}>
                    <Typography sx={{...font,fontSize:'12px',color: 'red', textAlign:'center' }}>{errorMessage}</Typography>
                    <MdOutlineCancel style={{color: 'red', fontSize:'23px', cursor:'pointer'}} onClick={()=>setIsError(false)}/>
                </Box>}

                <div>
                    {!isLoading && <FillButton text={'Recover Password'} width={'100%'}  height={'50px'} callBack={formik.handleSubmit} sx={{ borderRadius: 100}} />}
                    {isLoading && <Stack direction='row' justifyContent='center' ><CircularProgress/></Stack> }
                </div>


            </div>


            </div>

            </div>
            {modalStatus && (<ForgotPasswordModal navigate={navigate} open={modalStatus} handleClose={handleCloseModal} />)}
        </section>
    )
}