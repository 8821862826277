/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import timeRangeTable from '../../../../../../utils/timeDataTable';
import _ from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import {
  checkScheduleTimeTable,
  wholeDayTable,
} from '../../../../../../utils/checkTimeTable';
import DateLabel from '../../../Widget/DateLabel/DateLabel';
import {
  getShiftScheduleAsync,
  setDailyTime,
} from '../../../../../../Store/Shift';
import moment from 'moment';
import { Loader } from '../../../../../Widgets/Loader';
import EmptyPlaceholder from '../../../../../../Assets/boardIcon.png';
import InitialsGen from '../../../../../Widgets/InitialsGen';
import { BsAlarm } from 'react-icons/bs';
import { BiUser } from 'react-icons/bi';
import TablePagination from '@mui/material/TablePagination';
import tinycolor from 'tinycolor2';

function ScheduleDayView({
  scheduleDate,
  branchId,
  searchText,
  page,
  displayMode,
  handleChangePage,
  rowsPerPage,
  handleChangeRowsPerPage,
}) {
  const dispatch = useDispatch();
  const timeSchedule = useSelector((state) => state.shift)?.dailyTime;
  const shiftSchedules = useSelector((state) => state?.shift)?.schedules;
  const loading = useSelector((state) => state?.shift)?.loading;

  const checkTime = _.slice(
    timeRangeTable,
    timeSchedule.from,
    timeSchedule.to + 1
  );

  const handleSchedules = () => {
    let filterSchedules = shiftSchedules?.filter(
      (x) => !Object.keys(x).includes('totalPages')
    );
    if (searchText.length >= 3) {
      const pattern = new RegExp(`\D*${searchText}\D*`, 'i');
      filterSchedules = shiftSchedules?.filter(
        (x) => x.user.firstName.match(pattern) || x.user.lastName.match(pattern)
      );
    }

    return filterSchedules;
  };

  useEffect(() => {
    dispatch(
      setDailyTime({
        to: 23,
        from: 0,
      })
    );
  }, [displayMode.value]);

  useEffect(() => {
    dispatch(
      getShiftScheduleAsync({
        branchId,
        from: moment(scheduleDate).format('yyyy-MM-DDTHH:mm'),
        to: `${moment(scheduleDate).add(1, 'days').format('yyyy-MM-DDTHH:mm')}`,
      })
    );
  }, [branchId, displayMode.value, timeSchedule?.from, timeSchedule?.to, scheduleDate]);
  const time = checkTime.length === 0 ? checkScheduleTimeTable : checkTime;

  const formatTimeDiferent = () => {
    const timeRange = `${moment(timeSchedule.from, 'HH:mm').format(
      'HH:mm A'
    )} - ${moment(timeSchedule.to, 'HH:mm').format('HH:mm A')}`;
    return timeRange;
  };

  return (
    <div className=" mb-40">
      <table className="relative">
        <thead className="bg-white dark:bg-138">
          <td className="td-padding border bg-white dark:bg-138 dark:border-333">
            <p className="text-xs dark:text-white">Employee name</p>
          </td>
          {wholeDayTable.map((value, index) => {
            return (
              <td className="border dark:border-333">
                <p className="text-center text-sm font-medium dark:text-white">{value}</p>
              </td>
            );
          })}
        </thead>
        {loading && (
          <tr>
            <td colSpan={23}>
              <div className="flex justify-center w-full h-[400px] items-center">
                <Loader />
              </div>
            </td>
          </tr>
        )}
        {!loading && handleSchedules()?.length === 0 && (
          <tr>
            <td colSpan={23}>
              <div className="flex flex-col justify-center w-full h-[400px] items-center">
                <img src={EmptyPlaceholder} alt="img" />
                <p className="mt-3 font-bold text-sm dark:text--white">
                  No Schedule within {formatTimeDiferent()}
                </p>
              </div>
            </td>
          </tr>
        )}
        {!loading &&
          handleSchedules()?.length > 0 &&
          React.Children.toArray(
            handleSchedules()?.map((schedule) => {
              const startTime = moment(new Date(schedule.start), 'HH:mm')
                .utc().hour();
              const endTime = moment(new Date(schedule.end), 'HH:mm')
                .utc().hour();
              const colNumber = Math.abs(endTime - startTime) + 1;
              const timeRange = Array.from(Array(colNumber).keys())?.map(
                (x) => x + startTime
              );

              let isFirst = null;

              return (
                <tr>
                  <td className="border w-full h-[50px] flex p-1 items-center sticky left-0 bg-white dark:bg-138 dark:border-333">
                    {schedule?.user?.picture ? (
                      <img
                        className="rounded-full h-10 w-10 mr-2"
                        src={schedule?.user?.picture?.url}
                        alt="logo"
                      />
                    ) : (
                      <InitialsGen
                        width={40}
                        name={`${schedule?.user?.firstName} ${schedule?.user?.lastName}`}
                        bgColor="#00AD57"
                      />
                    )}
                    <p className="w-max text-xs px-3 flex items-center border-none dark:text-white">
                      {schedule?.user?.firstName} {schedule?.user?.lastName}{' '}
                      <span>
                        {schedule?.user?.skills?.filter(
                          (x) => x?.name === 'Spanish Speaker'
                        )?.length > 0
                          ? ' (SP)'
                          : ''}
                      </span>
                    </p>
                    <div>
                      <p className="text-xs absolute text-[7px] left-16 bottom-0 dark:text-white">
                        {schedule?.jobRole?.title}
                      </p>
                      <div
                        style={{
                          backgroundColor:
                            schedule?.jobRole?.hexcode !== 'NULL'
                              ? schedule?.jobRole?.hexcode
                              : 'white',
                        }}
                        className={`w-2 h-2 rounded-full bg-[${
                          schedule?.jobRole?.hexcode !== 'NULL'
                            ? schedule?.jobRole?.hexcode
                            : 'white'
                        }] absolute bottom-1 right-1`}
                      />
                    </div>
                  </td>

                  {time.map((value, index) => {
                    const splitEle = value?.split(' ');
                    const currentCelltime = moment(
                      `${splitEle[0]} ${splitEle[1]}`,
                      'hh:mm A'
                    ).utc().hour();

                    let colsPan = 1;


                    if (timeRange.includes(currentCelltime)) {
                      if (isFirst === null) {
                        const remainingCol = 24 - index;
                        const numOfCell =
                          colNumber -
                          timeRange.findIndex((x) => x === currentCelltime);
                        colsPan =
                          numOfCell >= remainingCol ? remainingCol : numOfCell;
                        isFirst = true;
                      } else {
                        return null;
                      }
                    }
                    return (
                      <td colSpan={colsPan} className={`border p-1 dark:border-333`}>
                        <div
                          className={`h-full rounded flex items-center justify-start px-4 `}
                          style={{
                            backgroundColor: timeRange.includes(currentCelltime)
                              ? schedule?.slot?.branch?.hexcode
                              : 'red',
                              color: tinycolor(schedule?.slot?.branch?.hexcode).isDark() ? "white": "black"
                          }}
                        >
                          {timeRange.includes(currentCelltime) ? (
                            <div>
                              <p className="text-xs text-left">
                                {schedule?.slot?.branch?.name}
                              </p>
                              <p className="text-xs flex items-center gap-3 text-ellipsis whitespace-nowrap overflow-hidden">
                                <BsAlarm /> {moment(schedule?.start).format("HH:mm")} -{' '}
                                {moment(schedule?.end).format("HH:mm")}{' '}
                                <span className="flex items-center gap-1">
                                  <BiUser />{' '}
                                  {schedule?.availability?.staffBooked || 0}{' '}
                                </span>
                              </p>
                            </div>
                          ) : (
                            ''
                          )}
                        </div>
                      </td>
                    );
                  })}
                </tr>
              );
            })
          )}

        <tr></tr>
      </table>
      {handleSchedules()?.length > 0 && (<div className="flex mt-10 mb-20 justify-end">
        <TablePagination
          component="div"
          count={handleSchedules()?.length || 0}
          className='dark:text-grey-600'
          page={page}
          onPageChange={handleChangePage}
          rowsPerPage={rowsPerPage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </div>)}
    </div>
  );
}

export default ScheduleDayView;
