import { BsSearch } from "react-icons/bs"


const CustomSearchInput = ({ onChange, placeholder = 'Search', className='', inputStyle='', iconStyle='', value, ...rest }) => {
    return (
        <div className="header w-full mb-5" style={{ marginTop: '0px' }}>
            <div className="search-box !justify-start items-center dark:bg-828 !w-full !rounded-3xl !ml-0" {...rest}>
                <div>
                    <BsSearch className="dark:text-white" />
                </div>
                <input className='dark:text-white' value={value} onChange={onChange} type="text" placeholder={placeholder} />
            </div>
        </div>
    )
}

export default CustomSearchInput