import React from 'react'
import AppLayout from '../../Widgets/AppLayout'
import { BsPlus, BsCheck } from 'react-icons/bs'
import { MdArrowBackIosNew } from "react-icons/md";
import { useLocation, useNavigate } from 'react-router'
import {ReactComponent as PayPal} from '../../../Assets/subscriptions/paypal_logo.svg'
import {ReactComponent as AppleIcon} from '../../../Assets/subscriptions/apple_pay.svg'
import {ReactComponent as GooglePay} from '../../../Assets/subscriptions/google_pay.svg'
import {ReactComponent as PaymentInfo} from '../../../Assets/subscriptions/payment_info.svg'
import {ReactComponent as PlanIcon} from '../../../Assets/subscriptions/plan_icon.svg'

export const SubscriptionDetails = () => {
    const location = useLocation()
    const navigate = useNavigate()

    return (
        <section>
            <AppLayout
            title="Payment & Billings"
            custom="subscriptions"
            customTitle=""
            subtitle="Update payment and billing methods."
            RightComponent={<div className='flex items-center gap-4'>
                <BsPlus className='text-E42' />
                <p className="text-18 text-E42">Add a new billing method</p>
            </div>}
            >
                <div className="w-full flex items-center h-screen">
                    <div className='w-[40%] p-2 h-full'>
                        <div className="flex items-center gap-3 mb-10">
                            <MdArrowBackIosNew className='cursor-pointer' onClick={()=>navigate(-1)} />
                            <p className="text-72D">Back to Pricing & Plans</p>
                        </div>
                        <p className='text-18 font-jakarta font-medium'>{`Upgrade Plan  to ${location?.state?.plan}`}</p>
                        <div className="flex items-center mt-4">
                            <p className="font-semibold text-xl">${location?.state?.amount}</p>
                            <p className='text-A6A'>per month</p>
                        </div>
                        <p className='text-sm text-A6A mb-5'>We will bill you $79 monthly + taxes, unless you cancel.</p>

                        <div className='flex items-start gap-2'>
                            <PlanIcon />
                            <div>
                                <p className="font-semibold text-18">{`${location?.state?.plan}`}</p>
                                <p className="text-xs font-jakarta w-[295px] mb-4">Up to 50 users, 10 job roles and 5 locations allowed. Great for  larger enterprises</p>
                                <input placeholder='Enter promo code' className="w-3/4 h-[55px] pl-2 outline-none border-2 border-DDD rounded-md" />
                            </div>
                        </div>
                    </div>
                    <div className='w-[60%] p-10 border-l-1 h-full border-l-BEB'>
                        <p className='font-semibold font-jakarta mb-3'>Billing Frequency</p>
                        <div className="flex items-center gap-3 mb-4">
                            <div className="p-4 border-2 border-DDD rounded-[4px] w-fit">
                                <p>Pay Monthly</p>
                                <p className='font-semibold'>${location?.state?.amount}/month</p>
                            </div>
                            <div className="p-4 border-2 border-DDD w-[212px] rounded-[4px]">
                                <div className="flex items-center justify-between"><p>Pay Yearly</p> <div className='rounded-full w-[18px] h-[18px] bg-D57 flex items-center justify-center'><BsCheck className='text-white font-body' /></div></div>
                               <div className='flex items-center gap-2'>
                               <p className='font-semibold'>${location?.state?.amount * 12}/year</p>
                                <p className="bg-7E0 p-1 rounded-md text-D57 font-medium text-xs text-center w-fit">Save $5</p>
                                </div> 
                            </div>
                        </div>
                        <p className='font-semibold font-jakarta mb-3'>Payment Method</p>
                        <div className='flex items-center gap-2'>
                            <div className='rounded-md border-[1px] border-D57 bg-5EB p-2 text-center'>
                                <p>Credit or Debit card</p>
                            </div>
                            <div className='border-[1px] border-DDD rounded p-3'>
                                <PayPal />
                            </div>
                            <div><AppleIcon /></div>
                            <div><GooglePay /></div>
                        </div>

                        <p className='font-semibold font-jakarta my-5'>Payment Information</p>
                        <PaymentInfo />

                        <div>
                            <div className='flex items-center gap-2 mt-5'>
                            <div className='w-3/4'>
                                <p className='text-sm font-medium'>Card Number</p>
                                <input placeholder='00000000' className='rounded-md pl-3 outline-none border-[1px] border-DDD h-[55px] w-full' />
                            </div>
                            <div className='w-1/4'>
                                <p className='text-sm font-medium'>Expiring Date</p>
                                <input placeholder='MM/YY' className='rounded-md pl-3 outline-none border-[1px] border-DDD h-[55px] w-full' />
                            </div>
                            <div className='w-1/4'>
                                <p className='text-sm font-medium'>CVV</p>
                                <input placeholder='3-digit-code' className='rounded-md outline-none pl-3 border-[1px] border-DDD h-[55px] w-full' />
                            </div>

                            </div>

                            <div className="mt-6 grid grid-cols-4 gap-2">
                            <div className='w-full col-span-4'>
                                <p className='text-sm font-medium'>Name on card</p>
                                <input placeholder='00000000' className='rounded-md pl-3 outline-none border-[1px] border-DDD h-[55px] w-full' />
                            </div>
                            <div className='w-full col-span-4 my-5'>
                                <p className='text-sm font-medium'>Address</p>
                                <input placeholder='Street address or P.O box' className='mb-5 rounded-md pl-3 outline-none border-[1px] border-DDD h-[55px] w-full' />
                                <input placeholder='Apt., suite, unit, building(optional)' className='rounded-md pl-3 outline-none border-[1px] border-DDD h-[55px] w-full' />
                            </div>
                            <div className='w-full col-span-2'>
                                <p className='text-sm font-medium font-jakarta'>City</p>
                                <input placeholder='City' className='mb-5 rounded-md pl-3 outline-none border-[1px] border-DDD h-[55px] w-full' />
                            </div>
                            <div className='w-full col-span-2'>
                                <p className='text-sm font-medium font-jakarta'>State</p>
                                <input placeholder='State, Province, Region' className='mb-5 rounded-md pl-3 outline-none border-[1px] border-DDD h-[55px] w-full' />
                            </div>
                            </div>

                        </div>

                    </div>

                </div>

            </AppLayout>
        </section>
    )
}