import { Navbar } from "../../Widgets/Navbar.jsx"
import './WelcomeScreen.css';
import glogo from '../../../Assets/glog.png';
import { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { Storage } from "../../../Network/StorageClass/StorageClass.js";
import { Service } from "../../../Network/ServiceClass/Auth.js";
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { Text, TextAndField, Field, FillButton } from '../../Widgets/FormUtils/FormUtils'
import { CircularProgress, Stack, Box, Typography, InputAdornment } from '@mui/material';
import { MdOutlineCancel } from 'react-icons/md'
import { font } from '../../Widgets/Styles/font'
import { FiArrowUpRight } from 'react-icons/fi'
import { setLoggedIn } from '../../../Store/socketSlice.js';
import { useDispatch } from "react-redux";
import { FaRegEyeSlash } from 'react-icons/fa'
import { LuEye } from 'react-icons/lu'
import './WelcomeScreen.css';

export const WelcomeScreen = () => {

    const dispatch = useDispatch()

    const navigate = useNavigate();
    const storage = new Storage();
    const userDetails = storage.getUserDetails();

    const service = new Service();

    const [isLoading, setIsLoading] = useState(false);
    const [isError, setIsError] = useState(false)
    const [errorMessage, setErrorMessage] = useState('')
    const [showPassword, setPsdVisibility] = useState(false)

    useEffect(() => {
        if (userDetails === null)
            navigate("/login");
    },[])

    const formik = useFormik({
        initialValues: {
            password: "",
        },

        validationSchema: Yup.object({
            password: Yup.string().required("Please enter a passoword")
        }),
        onSubmit: (value) => {
            LoginUser(value.password)
        }
    })

    const LoginUser = async (password) => {
        setIsLoading(true);
        service.LoginUser(userDetails.email, password)
            .then((value) => {
                storage.AuthStorage(value.data).then((value) => {
                    setIsLoading(false);
                    dispatch(setLoggedIn(true))
                    navigate("/home");
                });
            }).catch((err) => {
                setIsLoading(false);
                setErrorMessage(err?.response?.data?.message);
                setIsError(true)
            });

    }




    return <div className="welcome-screen">
        <Navbar />

        <div className='flex flex-col gap-10 mt-10 w-[356px] m-auto'>
            <p className="self-center text-[22px]">Welcome back {userDetails !== null && userDetails.firstName}</p>

            {/* <div className="flex flex-row justify-center items-center border-1 py-2 gap-2">
                <span><img alt="glog" src={glogo} className='w-[25px] h-[25px]' /></span>
                <span className='text-base'>Log in with google</span>
            </div> */}

            <div className='flex flex-row items-center gap-4'>
                <div className='border-b-1 w-[32%]'></div>
                <p className='text-[#696969]  text-[10px]'>Enter your password</p>
                <div className='border-b-1 w-[32%]'></div>
            </div>

            <div className='flex flex-col gap-10 rounded-input'>

                <TextAndField>
                    <Text text={"Password"} />
                    <Field text={'Password'}
                        value={formik.values.password}
                        handleChange={formik.handleChange}
                        type={!showPassword ? 'password' : 'text'}
                        name="password"
                        InputProps={{
                            endAdornment: (
                                <InputAdornment onClick={() => setPsdVisibility(prev => !prev)} position="end">
                                    {
                                        !showPassword ? <FaRegEyeSlash className="cursor-pointer" /> : <LuEye className="cursor-pointer" />
                                    }

                                </InputAdornment>
                            ),
                        }}
                        formik={formik} size="large" />
                </TextAndField>

                <div className="flex flex-row justify-between text-[10px]">
                    <div className="flex flex-row items-center gap-1">
                        <input type="checkbox" /><p>Remember Me </p>
                    </div>

                    <div className="border-b-1 border-black cursor-pointer">
                        <span onClick={() => navigate('/login')}>Not {userDetails?.firstName}? Login here</span>
                    </div>
                </div>

                {isError &&
                    <Box sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        borderRadius: '0.2rem',
                        boxShadow: '0 3px 10px rgb(0 0 0 / 0.2)',
                        padding: '0.5rem'
                    }}>
                        <Typography sx={{ ...font, fontSize: '12px', color: 'red', textAlign: 'center' }}>{errorMessage}</Typography>
                        <MdOutlineCancel style={{ color: 'red', fontSize: '23px', cursor: 'pointer' }} onClick={() => setIsError(false)} />
                    </Box>}

                <div>
                    {!isLoading && <FillButton text={'Log in'} width={'100%'} height={'50px'} callBack={formik.handleSubmit} />}
                    {isLoading && <Stack direction='row' justifyContent='center' ><CircularProgress /></Stack>}
                    <div className='flex flex-row items-center gap-1 mt-5 text-[10px]'>
                        <p className='text-[#696969]'>Not registered yet,</p>
                        <p className='ml-[10px] cursor-pointer' onClick={() => navigate("/signup")}>Create an account</p>
                        <FiArrowUpRight />
                    </div>

                </div>

            </div>
        </div>
    </div>;

}