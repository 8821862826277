import axios from "axios";
import { conn_url } from "../conn_consts";
import { Storage } from "../StorageClass/StorageClass";

const config = {
  headers: {
    "content-type": "application/json",
  },
};

const storage = new Storage();

export class Service {
  async LoginUser(user, password) {
    const response = await axios.post(
      conn_url + "/api/v1/users/signin",
      {
        email: user,
        password: password,
        device: {
          type: "web",
          token: "1234567",
        },
      },
      config
    );
    return { data: response.data, status: response.status };
  }

  async getCountries() {
    const response = await axios.get(conn_url + "/api/v1/countries", config);
    return response.data;
  }

  async getSkillSet() {
    const response = await axios.get(
      conn_url + "/api/v1/company/get-skill-sets",
      config
    );
    return { data: response.data, status: response.status };
  }

  async getAllSkillSet() {
    const response = await axios.get(
      conn_url + "/api/v1/company/skill-sets",
      config
    );
    return { data: response.data, status: response.status };
  }

  async createAccount(data) {
    const response = await axios.post(
      conn_url + "/api/v1/users/signup",
      data,
      config
    );
    return { data: response.data, status: response.status };
  }

  async sendComfirmationEmail(email) {
    const response = await axios.post(
      conn_url + "/api/v1/users/resend-confirm-email",
      { email: email },
      config
    );
    return { data: response.data, status: response.status };
  }

  async confirmMail(hash) {
    const response = await axios.post(
      conn_url + "/api/v1/users/confirm-email",
      { hash: hash },
      config
    );
    return { data: response.data, status: response.status };
  }

  async ForgotPassword(email) {
    const response = await axios.post(
      conn_url + "/api/v1/users/forgot-password",
      { email: email }
    );
    return { data: response.data, status: response.status };
  }

  async ResetPassword(data) {
    const response = await axios.post(
      conn_url + "/api/v1/users/reset-password",
      data,
      config
    );
    return { data: response.data, status: response.status };
  }

  async VerifyInvite(data) {
    const response = await axios.post(
      conn_url + "/api/v1/users/verify-invite",
      data,
      config
    );
    return { data: response.data, status: response.status };
  }

  async getGoogleAuthUrl() {
    try {
      const response = await axios.get(
        conn_url + "/api/v1/users/google-auth-url",
        storage.getConfig()
      );
      window.open(response.data, "_top");
      return { data: response.data, status: response.status };
    } catch (error) {
      throw new Error(error);
    }
  }

  async getMSAuthUrl() {
    try {
      const response = await axios.get(
        conn_url + "/api/v1/users/ms-auth-url",
        storage.getConfig()
      );
      window.open(response.data, "_top");
      return { data: response.data, status: response.status };
    } catch (error) {
      throw new Error(error);
    }
  }

  async linkMSAuthUrl(data) {
    try {
      const response = await axios.post(
        conn_url + "/api/v1/users/link-ms-account",
        data,
        storage.getConfig()
      );
      let user = JSON.parse(localStorage.getItem("user"));
      user = {
        ...user,
        linkedEmails: {
          ...response.data,
        },
      };

      localStorage.setItem("user", JSON.stringify(user));
      return { data: response.data, status: response.status };
    } catch (error) {
      throw new Error(error);
    }
  }

  async linkGoogleAuthUrl(data) {
    try {
      const response = await axios.post(
        conn_url + "/api/v1/users/link-google-account",
        data,
        storage.getConfig()
      );
      let user = JSON.parse(localStorage.getItem("user"));
      user = {
        ...user,
        linkedEmails: {
          ...response.data,
        },
      };

      localStorage.setItem("user", JSON.stringify(user));
      return { data: response.data, status: response.status };
    } catch (error) {
      throw new Error(error);
    }
  }

  async scheduleGoogleSync(data) {
    try {
      const response = await axios.post(
        conn_url + "/api/v1/users/schedule-google-sync",
        data,
        storage.getConfig()
      );
      return { data: response.data, status: response.status };
    } catch (error) {
      throw new Error(error);
    }
  }

  async scheduleMsSync(data) {
    try {
      const response = await axios.post(
        conn_url + "/api/v1/users/schedule-ms-sync",
        data,
        storage.getConfig()
      );
      return { data: response.data, status: response.status };
    } catch (error) {
      throw new Error(error);
    }
  }

  async updateUserInfo(data) {
    try {
      const response = await axios.put(
        conn_url + "/api/v1/users/update-userInfo",
        data,
        storage.getConfig()
      );
      return { data: response.data, status: response.status };
    } catch (error) {
      return { data: error.response.data, status: error.response.status };
    }
  }
}
