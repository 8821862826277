import React, { useState } from 'react';
import { Modal, CircularProgress } from '@mui/material';
import { Box } from '@mui/material';
import { GrUploadOption } from 'react-icons/gr';
import { Loader } from '../../../Widgets/Loader';
import { Home } from '../../../../Network/ServiceClass/Home';
import { useDispatch } from 'react-redux';
import { setDp } from '../../../../Store/Company';
import {
  OutlinedButton,
  FillButton,
} from '../../../Widgets/FormUtils/FormUtils';
import FormModal from '../../../Widgets/Animation/FormModal';

const ProfileModal = ({ open, handleClose }) => {
  const home = new Home();
  const dispatch = useDispatch();

  const [file, setFile] = React.useState(null);
  const [isloading, setIsLoading] = React.useState(false);

  const [msg, setMsg] = useState('');

  const chooseFile = () => {
    const choosenfile = document.querySelector('.img-file');
    choosenfile.click();
    setMsg('');
  };

  const UploadDp = () => {
    if (file !== null) {
      setIsLoading(true);
      const formdata = new FormData();
      formdata.append('file', file);
      home
        .uploadDp(formdata)
        .then((val) => {
          dispatch(setDp({ url: val.data.url }));
          handleClose();
          setIsLoading(false);
        })
        .catch((err) => {
          console.log(err);
          setMsg(err.response.data.message);
          setIsLoading(false);
        });
    } else {
      setMsg('Select image file');
    }
  };
  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '400px',
    maxHeight: '100vh',
    // border: '2px solid #000',
    boxShadow: 24,
    p: 4,
    borderRadius: '5px',
    overflowY: 'auto',
    overflowX: 'hidden',
  };

  return (
    <FormModal open={open} handleClose={handleClose}>
      <Box sx={{ ...style }} className="create-account dark:bg-138 bg-white dark:text-white">
        <div className="upload-area" onClick={chooseFile}>
          <input
            type="file"
            className="img-file"
            onChange={(e) => setFile(e.target.files[0])}
            style={{ display: 'none' }}
          />

          <p className="flex items-center" style={{ fontSize: '12px', cursor: 'pointer' }}>
            <span className="mr-1" style={{ color: 'cornflowerblue' }}>
              <GrUploadOption />{' '}
            </span> Upload Profile image
          </p>
        </div>

        <div
          style={{
            width: '85%',
            textAlign: 'left',
            fontSize: '13px',
            marginTop: '10px',
            marginBottom: '50px',
          }}
        >
          {file === null
            ? 'File should not be larger than 3mb'
            : '1 file picked'}
        </div>

        <div
          className="flex justify-between"
          style={{ paddingTop: '0vh', width: '80%' }}
        >
          <OutlinedButton
            text={'Cancel'}
            width={'100px'}
            height={'45px'}
            callBack={handleClose}
          />
          {!isloading && (
            <FillButton
              text={'Upload'}
              width={'100px'}
              height={'45px'}
              callBack={UploadDp}
            />
          )}
          {isloading && <CircularProgress />}
        </div>

        <center>
          <p className="error-msg">{msg}</p>
        </center>
      </Box>
    </FormModal>
  );
};

export default ProfileModal;
