import React, { useState } from 'react';
import Dialog from '@mui/material/Dialog';
import { CircularProgress, DialogContent } from '@mui/material';
import { Box, Typography, Button } from '@mui/material';
import { font } from '../../../../../../Widgets/Styles/font';
import UserDropdown from '../../../../../../Widgets/UserDropdown';
import { Formik } from 'formik';
import * as Yup from 'yup';
import successImage from '../../../../../../../Assets/success_circle.png';
import { useDispatch } from 'react-redux';
import { copyRotationASync } from '../../../../../../../Store/Rotation';
import { Loader } from '../../../../../../Widgets/Loader';
import FormModal from '../../../../../../Widgets/Animation/FormModal';
import { toast } from 'react-toastify';

export default function ViewCopyRotation({
  openViewCopyRotation,
  handleCloseViewCopyRotation,
  stateStore,
  year,
  store,
  defaultRotation
}) {
  const [openSuccess, setSuccess] = useState(false);
  const dispatch = useDispatch();

  const handleCloseModal = () => {
    handleCloseViewCopyRotation();
  };

  return (
    <div>
      <FormModal open={openViewCopyRotation} handleClose={handleCloseViewCopyRotation}>
          <Formik
            initialValues={{
              from: {
                year: defaultRotation?.from?.year || '',
                rotationNumber: defaultRotation?.from?.rotationNumber || '',
              },
              to: {
                year: defaultRotation?.to?.year || '',
                rotationNumber:  defaultRotation?.to?.rotationNumber || '',
              },
            }}
            validationSchema={Yup.object({
              from: Yup.object().shape({
                year: Yup.string().required('Required'),
                rotationNumber: Yup.number().required('Required'),
              }),
              to: Yup.object().shape({
                year: Yup.string().required('Required'),
                rotationNumber: Yup.number().required('Required'),
              }),
            })}
            onSubmit={(values) => {
              setTimeout(() => {
                handleCloseModal();
                dispatch(
                  copyRotationASync(values)
                ).then((error) => {
                  setSuccess(true)
                })
              }, 400);
            }}
            enableReinitialize={Object.keys(defaultRotation)?.length > 0}
          >
            {({ errors, setFieldValue, handleSubmit, values }) => (
              <Box
                sx={{
                  position: 'absolute',
                  top: '50%',
                  left: '50%',
                  transform: 'translate(-50%, -50%)',
                  width: '485px',
                  maxHeight: '100vh',
                  bgcolor: 'background.paper',
                  // border: '2px solid #000',
                  boxShadow: 24,
                  p: 4,
                  borderRadius: '5px',
                  overflowY:'auto',
                  overflowX:'hidden',
                  py: '53px',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  px: '44px',
                  ...font,
                }}
              >
                
                <div className="w-full">
                  <Typography
                    sx={{
                      fontSize: '24px',
                      color: '#242424',
                      fontWeight: '700',
                      marginTop: '3rem',
                      textAlign: 'center',
                    }}
                  >
                    Copy rotation
                  </Typography>

                  <div className="mt-[35px] grid grid-cols-2 w-full gap-x-[26px]">
                    <div>
                      <Typography
                        variant="span"
                        className="text-[10px] font-medium mb-2"
                      >
                        Copying From
                      </Typography>
                      <UserDropdown
                        data={year}
                        onChangeOption={(e) => {
                          setFieldValue('from.year', e.value);
                        }}
                        showAvatar={false}
                        className="h-[51px]"
                        defaultLabel={!!values.from?.rotationNumber ? {label: values.from.year, value: values.from.year } :{ label: 'Select year', value: 'select' }}
                        headerClass="text-xs p-0"
                      />
                      {errors?.from?.year ? (
                        <p className="text-red-500 text-[10px]">
                          {errors?.from?.year}
                        </p>
                      ) : (
                        <p className="text-[10px] text-white">required</p>
                      )}
                    </div>
                    <div className="flex flex-col justify-end">
                      <UserDropdown
                        data={Array.from(
                          Array(stateStore.rotationNumber).keys()
                        ).map((rotationNum) => ({
                          label: `ROTATION ${rotationNum + 1}`,
                          value: rotationNum + 1,
                          color: '#242424',
                        }))}
                        onChangeOption={(e) => {
                          setFieldValue('from.rotationNumber', e.value);
                        }}
                        
                        showAvatar={false}
                        className="h-[51px]"
                        defaultLabel={!!values.from?.rotationNumber ? {
                          label: `ROTATION ${Number(values.from?.rotationNumber || 0)}`,
                          value: Number(values.from.rotationNumber),
                          color: '#242424',
                        }: {
                          label: 'Select Rotation',
                          value: 'select',
                        }}
                        headerClass="text-14 p-0"
                      />
                      {errors?.from?.rotationNumber ? (
                        <p className="text-red-500 text-xs">
                          {errors?.from?.rotationNumber}
                        </p>
                      ) : (
                        <p className="text-[10px] text-white">required</p>
                      )}
                    </div>
                    <div className="mt-[34px]">
                      <Typography
                        variant="span"
                        className="text-[10px] font-medium mb-2"
                      >
                        Copying To
                      </Typography>
                      <UserDropdown
                        data={year}
                        onChangeOption={(e) => {
                          setFieldValue('to.year', e.value);
                        }}
                        showAvatar={false}
                        className="h-[51px]"
                        defaultLabel={{ label: 'Select year', value: 'select' }}
                        headerClass="text-sm p-0"
                      />
                      {errors?.to?.year ? (
                        <p className="text-red-500 text-[10px]">{errors?.to?.year}</p>
                      ) : (
                        <p className="text-[10px] text-white">required</p>
                      )}
                    </div>
                    <div className="flex flex-col justify-end">
                      <UserDropdown
                        data={Array.from(
                          Array(stateStore.rotationNumber).keys()
                        ).map((rotationNum) => ({
                          label: `ROTATION ${rotationNum + 1}`,
                          value: rotationNum + 1,
                          color: '#242424',
                        }))}
                        onChangeOption={(e) => {
                          setFieldValue('to.rotationNumber', e.value);
                        }}
                        showAvatar={false}
                        className="h-[51px]"
                        defaultLabel={{
                          label: 'Select Rotation',
                          value: 'select',
                        }}
                        headerClass="text-xs p-0"
                      />
                      {errors?.to?.rotationNumber ? (
                        <p className="text-red-500 text-[10px]">
                          {errors?.to?.rotationNumber}
                        </p>
                      ) : (
                        <p className="text-[10px] text-white">required</p>
                      )}
                    </div>
                  </div>
                </div>

                {!store.loading ? (<Box
                  sx={{
                    display: 'flex',
                    gap: '10rem',
                    justifyContent: 'space-between',
                    width: '100%',
                  }}
                >
                  <Button
                    onClick={handleCloseModal}
                    variant="outlined"
                    sx={{
                      textTransform: 'none',
                      borderColor: 'black',
                      color: 'black',
                    }}
                  >
                    Cancel
                  </Button>
                  <Button
                    onClick={handleSubmit}
                    variant="contained"
                    sx={{
                      textTransform: 'none',
                      backgroundColor: 'black',
                      color: 'white',
                    }}
                    disabled={
                      Object.values(values.from).filter((value) => value !== '')
                        .length < 2 ||
                      Object.values(values.to).filter((value) => value !== '')
                        .length < 2
                    }
                  >
                    Copy
                  </Button>
                </Box>) : <Loader />}
              </Box>
            )}
          </Formik>
      </FormModal>

      <FormModal open={openSuccess} handleClose={() => setSuccess(false)}>
          <DialogContent
            sx={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              right: '44%',
              width: '250px',
              top: '35vh',
              backgroundColor: 'white',
            }}
          >
            <center>
              <div style={{ width: '200px', padding: '20px' }}>
                <p className="dialog-header">Success</p>
                <p className="dialog-text" style={{ color: '#A5A5A5' }}>
                  Copying rotation was successful
                </p>
                <br></br>

                <img src={successImage} width="50%" alt="success" />
                <br />
                <br />

                <button
                  type="button"
                  onClick={() => {
                    setSuccess(false)
                    handleCloseModal()
                  }}
                  style={{
                    padding: '7px 12px',
                    fontSize: '16px',
                    color: 'white',
                    borderRadius: '5px',
                    border: '1px solid black',
                    backgroundColor: 'black',
                  }}
                >
                  Done
                </button>
              </div>
            </center>
          </DialogContent>
      </FormModal>
    </div>
  );
}
