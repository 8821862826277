import { useEffect, useState } from "react";
import AppLayout from "../../Widgets/AppLayout";
import CustomSearchInput from "../../Widgets/CustomSearch";
import { useDispatch, useSelector } from "react-redux";
import { getGlobalSearchAsync } from "../../../Store/dashboard";
import { Loader } from "../../Widgets/Loader";
import { useNavigate } from "react-router";
import LocationIcon from "../../../Assets/location_icon.png"
import SpeakerIcon from "../../../Assets/speaker.png"
import moment from "moment"

export function GlobalSearch(){
    const dispatch = useDispatch(0)
    const navigation = useNavigate();
    const [searchedWord, searchWord] = useState('')
    const searchResult = useSelector(state => state.dashboard.globalSearch) || []
    const loading = useSelector(state => state.dashboard.loading)

    useEffect(()=>{
        if(searchedWord?.length > 1){
          dispatch(getGlobalSearchAsync(searchedWord))
        }
      },[dispatch, searchedWord])

    const handleGlobalSearch = (e) => {
        searchWord(e.target.value)
      }

      const convLabel = {
        "location": "Locations",
        "teamMembers": "Team members",
        "jobRoles": "Job Roles",
        "announcements": "Announcements",
        "manufacturers": "Manufacturers",
        "suppliers": "Suppliers"
      }

    return (
        <div>
            <AppLayout
            custom="global-search"
            subCustom=""
            title=""
            customTitle=" "
            subtitle=""
            >

                <CustomSearchInput
                    onChange={handleGlobalSearch}
                    placeholder="i'm search for something"
                    value={searchedWord}
                />

                <div>
                    {searchedWord?.length === 0 && <div className=" h-48 flex items-center justify-center"><p>Nothing was searched</p></div>}
                {searchedWord?.length > 1 &&  (<div className="max-h-[400px] z-50 py-[40px] bg-white overflow-y-scroll left-0 top-full w-full dark:bg-primary">
                  {
                    !loading && Object.entries(searchResult)?.map((elem) => elem[1]?.length > 0 && (
                      selectContainer(elem, convLabel, navigation)
                    ))
                  }
                  {loading && (<Loader />)}
                  {
                    !loading && !Object.values(searchResult)?.some((x) => x.length > 0) && <p className="p-4 h-[200px] text-center dark:text-white">No Search Data</p>
                  }
                </div>)}
                </div>

            </AppLayout>

        </div>
    )
}

const selectContainer = (data, conv,nav) => {
    switch(data[0]) {
      case 'location':
        return (<div className="mb-[36px] dark:bg-primary">
           <p className='p-2 text-D6D font-bold text-xs dark:text-white'>{conv[data[0]]}</p>
           {
            data[1]?.map((x) => (<div onClick={()=>nav("/branches")} className="flex cursor-pointer ml-14 mb-[27px] items-center gap-3">
              <img src={LocationIcon} alt="loc" />
              <div>
                <p className="font-bold text-base dark:text-white">{x?.name}</p>
                <p className=" font-semibold text-10 text-727 dark:text-white">{x?.address}</p>
              </div>
            </div>))
           }
        </div>)
      case 'jobRoles':
        return (<div className="mb-[36px] dark:bg-primary">
           <p className='p-2 text-D6D font-bold text-xs dark:text-white'>{conv[data[0]]}</p>
           {
            data[1]?.map((x) => (<div onClick={()=>nav("/jobs")} className="flex cursor-pointer ml-14 mb-[27px] items-center gap-3">
              <div className="w-6 h-6 rounded-full" style={{ backgroundColor: x?.hexcode || "#000000"}} />
              <div>
                <p className="font-bold text-base dark:text-white">{x?.title}</p>
                <p className=" font-semibold text-10 text-727 dark:text-white">{x?.desc}</p>
              </div>
            </div>))
           }
        </div>)
      case 'teamMembers':
        return (<div className="mb-[36px] dark:bg-primary">
           <p className='p-2 text-D6D font-bold text-xs dark:text-white'>{conv[data[0]]}</p>
           {
            data[1]?.map((x) => (<div onClick={()=>nav("/branches")} className="flex cursor-pointer ml-14 mb-[27px] items-center gap-3">
              <div className="w-6 h-6 rounded-full" style={{ backgroundColor: x?.jobRole?.hexcode || "#000000"}} />
              <div>
                <p className="font-bold text-base dark:text-white">{x?.user?.firstName} {x?.user?.lastName}</p>
                <p className=" font-semibold text-10 text-727 dark:text-white">{x?.jobRole?.title}</p>
              </div>
            </div>))
           }
        </div>)
      case 'announcements':
        return (<div className="mb-[36px] dark:bg-primary">
           <p className='p-2 text-D6D font-bold text-xs dark:text-white'>{conv[data[0]]}</p>
           {
            data[1]?.map((x) => (<div onClick={()=>nav("/announcement")} className="flex cursor-pointer ml-14 mb-[27px] items-center gap-3">
              <img src={SpeakerIcon} alt="icon" />
              <div>
                <p className="font-bold text-base dark:text-white">{x?.title}</p>
                <p className=" font-semibold text-10 text-878 dark:text-white">{moment(x.createdOn).format("MMM do yyyy")}-{x?.createdBy?.firstName} {x?.createdBy?.lastName}</p>
              </div>
            </div>))
           }
        </div>)
      default:
        <div></div>
    }
  }