/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { useState, useEffect } from 'react';
import { useFormik } from 'formik';
import { Stack, Typography, CircularProgress } from '@mui/material';
import { useDispatch } from 'react-redux';
import {
  Field,
  TextAndField,
  FillButton,
  OutlinedButton,
} from '../../../Widgets/FormUtils/FormUtils';
import FormModal from '../../../Widgets/Animation/FormModal';
import * as Yup from 'yup';
import { Service } from '../../../../Network/ServiceClass/Auth';
import { toast } from 'react-toastify';
import { Storage } from '../../../../Network/StorageClass/StorageClass';
import { getATeammemberAsync } from '../../../../Store/Branches';

const schema = Yup.object().shape({
  firstName: Yup.string().required('First Name is required'),
  lastName: Yup.string().required('Last Name is required'),
  userName: Yup.string().required('Username is required'),
  email: Yup.string().email('Invalid email address').required('Email is required'),
  phone: Yup.string().required('Phone is required'),
});


const service = new Service()
function EditProfile({ open, handleClose, data }) {

  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState(false)

  const formik = useFormik({
    initialValues: {
      firstName: data?.firstName,
      lastName: data?.lastName,
      email: data?.email,
      phone: data?.phone,
      userName: data?.userName,

    },

    validationSchema: schema,
    onSubmit: (value, action) =>{
        setIsLoading(true)
        service.updateUserInfo(value)
        .then(val => {
            if (val?.status === 200){
                toast.success('Updated')
                handleClose()
                dispatch(getATeammemberAsync(data?.id)) 
            }else {
                toast.error(val?.data?.message)
            }
            setIsLoading(false)
        })
    }
   
  });

 
  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '450px',
    maxHeight: '100vh',
  
    // border: '2px solid #000',
    boxShadow: 24,
    p: 4,
    borderRadius: '5px',
    overflowY: 'auto',
    overflowX: 'hidden',
  };

  const handleCheckChanges = () => {
    return Object.keys(formik.values)?.every((x) => data[x] === formik.values[x])
  }

  

  return (
    <div>
      <FormModal open={open} handleClose={handleClose}>
        <div className='dark:text-white'>
          
            <Stack
                direction="column"
                alignItems="center"
                sx={style}
                gap={3}
                className='dark:bg-138 bg-white'
            >
              
               <p className='text-[18px] font-medium'>Edit Profile</p>

               <TextAndField>
                <p className='text-[12px]'>Username</p>
                <Field
                    text={'Kindly enter username'}
                    value={formik.values.userName}
                    formik={formik}
                    name="userName"
                />
                </TextAndField>

                <TextAndField>
                <p className='text-[12px]'>First Name</p>
                <Field
                    text={'Kindly enter first name'}
                    value={formik.values.firstName}
                    formik={formik}
                    name="firstName"
                />
                </TextAndField>

                <TextAndField>
                <p className='text-[12px]'>Last Name</p>
                <Field
                    text={'Kindly enter last name'}
                    value={formik.values.lastName}
                    formik={formik}
                    name="lastName"
                />
                </TextAndField>

                <TextAndField>
                <p className='text-[12px]'>Email</p>
                <Field
                    text={'Kindly enter email'}
                    value={formik.values.email}
                    formik={formik}
                    name="email"
                />
                </TextAndField>

                <TextAndField>
                <p className='text-[12px]'>Phone</p>
                <Field
                    text={'Kindly enter phone'}
                    value={formik.values.phone}
                    formik={formik}
                    name="phone"
                />
                </TextAndField>
              
                <div className='w-full flex justify-between mt-6'>
                    <OutlinedButton
                        text="Cancel"
                        callBack={() => {
                        handleClose();
                        formik.resetForm();
                        }}
                    />
                    {!isLoading ? <FillButton
                        text="Continue"
                        disable={handleCheckChanges()}
                        callBack={formik.handleSubmit}
                    /> :
                    <CircularProgress/> }
                
                </div>    
            </Stack>
         
        </div>
      </FormModal>
    </div>
  );
}

export default EditProfile;

//
