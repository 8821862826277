import React, { useState, useEffect } from 'react'
import SyncdarkLogo from "../../Assets/menu/new_logo_dark.svg";
import Synclogo from "../../Assets/light_logo.svg";
import { sideMenu } from './util';
import { useLocation, useNavigate } from 'react-router';
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
// import { Accordion } from '@material-ui/core';
import { styled } from "@mui/material/styles";
import MuiAccordion from "@mui/material/Accordion";
import { useDispatch, useSelector } from 'react-redux';
import trimText from '../../utils/trimText';
import { FiChevronDown } from 'react-icons/fi';
import { MenuItem, Menu } from '@mui/material';
import { Home } from '../../Network/ServiceClass/Home';
import { Storage } from "../../Network/StorageClass/StorageClass";
import { setActiveCompany } from '../../Store/Company';
import SwitchMode from "@mui/material/Switch";
import { Badge } from '@mui/material';
import { getNotificationAsync } from '../../Store/dashboard';

export function SideBarLayout(props) {
  const location = useLocation()
  const navigate = useNavigate()
  const activeCompany = useSelector((state) => state.company.activeCompany);
  const companies = useSelector((state) => state.company.companies);
  const socketState = useSelector((state) => state?.socket?.socket);
  const [dropdownState, setDropdown] = useState("")
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const home = new Home();
  const storage = new Storage();
  const dispatch = useDispatch()

  const [styleMode, switchMode] = useState(false);

  const mode = React.useMemo(
    () => localStorage.getItem("color-theme"),
    [styleMode]
  );

  const members =
  useSelector((state) => state?.company)?.activeCompany?.memberships || [];
  const notifications = useSelector(state => state.dashboard)?.notifications?.data || []

  const user =
    localStorage.getItem("user") && JSON.parse(localStorage.getItem("user"));

  
  useEffect(() => {
    if (mode === "dark") {
      switchMode(true);
    } else {
      switchMode(false);
    }
  }, [styleMode, mode]);

  useEffect(()=>{
    dispatch(getNotificationAsync())
  },[])


  const Accordion = styled((props) => (
    <MuiAccordion disableGutters elevation={0} square {...props} />
  ))(({ theme }) => ({
    // backgroundColor: active === true ? "#F5F5F5" : "",
    "&:not(:last-child)": {
      borderBottom: 0,
    },
    "&:before": {
      display: "none",
    },
  }));

  

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const switchCompany = async (id) => {
    home
      .switchCompany({ companyId: id })
      .then((value) => {
        storage.AuthStorage(value.data);
        dispatch(setActiveCompany(value.data.activeCompany));
        if (socketState && value.data?.activeCompany !== null) {
          const payload = {
            userId: value?.data?.user?.id,
            teamMemberId: value.data?.activeCompany?.memberships[0]?.id,
            branchId:
              value.data?.activeCompany?.memberships[0]?.assignedBranchId,
            companyId: value.data?.activeCompany?.id,
          };
          socketState.emit("join-company-group", payload);
          // console.log(value.data?.activeCompany?.id )
        }
        navigate("/home");
      })
      .catch((err) => {
        console.log(err);
      });
  }

  const handleSwitchMode = (e) => {
    if (e.target.checked) {
      localStorage.setItem("color-theme", "dark");
    } else {
      localStorage.setItem("color-theme", "light");
    }
    switchMode(e.target.checked);
    window?.location?.reload();
  };


  return (
    <section className='w-[264px] h-screen p-5 bg-FF9 overflow-y-scroll dark:bg-C21'>

      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        {companies !== null &&
          companies.map((value, index) => (
            <MenuItem
              key={index}
              sx={{ fontSize: "15px" }}
              onClick={() => {
                handleClose();
                switchCompany(value.id);
              }}
            >
              {value.name}
            </MenuItem>
          ))}
      </Menu>

      <div className=" w-[100%] dark:bg-C21">
        <img
          src={localStorage.getItem("color-theme") === "dark" ? SyncdarkLogo : Synclogo}
          alt="logo"
          className="my-0"
        />
      </div>

      <section className="bg-white shadow-sideMenuLogo flex items-center justify-between gap-3 px-1 py-2 my-5 dark:bg-564 rounded-[4px] dark:border-0">
        {activeCompany !== null && (
          <>
            <div className="flex items-center gap-2">
              {activeCompany?.logo?.url?.length > 0 ? (
                <img
                  className="rounded-full user-img "
                  src={activeCompany?.logo?.url}
                  alt="logo"
                ></img>
              ) : (
                <div className="user-img dark:bg-white"></div>
              )}

              <div className="text-[black] dark:text-white">
                <p className="font-semibold text-[14px] dark:text-white">
                  {activeCompany !== null && trimText(activeCompany?.name)}
                </p>
                <p className="text-sm font-bold">
                  {activeCompany !== null &&
                    trimText(activeCompany?.category?.name)}
                </p>
              </div>
            </div>

            <div
              className="text-black text-[22px] cursor-pointer dark:text-white"
              onClick={handleClick}
            >
              <FiChevronDown className="" />
            </div>
          </>
        )}
      </section>

      <div className='mt-5'>
        {
          React.Children.toArray(
            sideMenu.map((eachMenu) => (<div className='flex items-center'>
              {
              eachMenu.permission?.includes(members.filter((x) => x.userId === user.id)[0]?.privilege) && eachMenu?.icon && (<div onClick={() => navigate(eachMenu.route)} className={`flex py-3 pl-2 w-full cursor-pointer items-center gap-2 ${location?.pathname.includes(eachMenu.domain) ? "bg-BEF rounded-md" : ""}`}>
                  <eachMenu.icon size={16} className='dark:text-DDD' /> <p className={`${location?.pathname.includes(eachMenu.domain) ? "text-A0C" : "text-A7A"} dark:text-DDD text-xs !font-jakarta font-medium`}>{eachMenu.label}</p>
                </div>)
              }
              {
                eachMenu?.children && (
                <div className='w-full'>
                  <Accordion

                    // className={`hover:bg-gray-100 ${!styleMode ? "!bg-90F" : "!bg-A06"}  mb-[34px] `}
                    defaultExpanded={eachMenu.children.map((child) => child.domain).includes(location.pathname) ? true : false}
                  >
                    <AccordionSummary
                      className="!bg-FF9 dark:!bg-C21"
                      expandIcon={
                        <ExpandMoreIcon
                        className='dark:text-DDD'
                        />
                      }
                    >

                      <p className='text-A7A dark:text-DDD !font-jakarta text-xs cursor-pointer font-medium'>{eachMenu.label}</p>

                    </AccordionSummary>

                    {
                      React.Children.toArray(
                        eachMenu.children.map((eachChild) => (
                          eachChild.permission?.includes(members.filter((x) => x.userId === user.id)[0]?.privilege) &&  <AccordionDetails
                            onClick={() => navigate(eachChild.route)}
                            className="bg-FF9 !pb-0 dark:bg-C21"
                          >
                            <div className={`p-2 py-3 w-full cursor-pointer flex items-center gap-2 ${location?.pathname.includes(eachChild.domain) ? "bg-BEF !text-A0C dark:bg-291 rounded-md" : ""}`}>
                              {eachChild?.icon && (<eachChild.icon size={16} className='dark:text-DDD' />)}
                              <div className="flex items-center gap-4">
                              <p className={`${location?.pathname.includes(eachChild.domain) ? "text-A0C": "text-A7A"} dark:text-DDD text-xs !font-jakarta font-medium`}>{eachChild.label}</p>
                              {eachChild?.domain === "/notifications" && (<Badge sx={{
                                "svg": {
                                  color: "grey"
                                }
                              }} badgeContent={notifications?.length || 0} color="primary">
                                {/* <NotificationsNoneIcon color="action" /> */}
                              </Badge>)}
                              </div>
                            </div>
                          </AccordionDetails>

                        ))
                      )
                    }

                  </Accordion>
                </div>)
              }
            </div>))
          )
        }
        <div className="flex items-center mt-5 gap-3 pl-[35px]">
            <p className="font-bold text-xs text-CAC dark:text-DDD">Dark Mode</p>
            <SwitchMode checked={styleMode} onChange={handleSwitchMode} />
          </div>
      </div>
    </section>
  )
}