import { useState } from "react"
import {ReactComponent as CompanyLogo} from "../../../../Assets/super_sync_logo.svg";
import { SignGetStart } from './index.jsx';
import { OnboardingFlow } from "../../../Widgets/Auth";
import { useFormik } from "formik";
import * as Yup from 'yup';
import { ChooseRole } from "./ChooseRole.jsx";
import { AddSignUpInfo } from "./AddSignUpinfo.jsx";
import { CompleteSignup } from "./CompleteSignup.jsx";



export function NewSignUpScreen(){
    const [currFlow, setCurrFlow] = useState(0)
    const [signData, setSignData] = useState({})


  const handleSaveDate = (data) => {
    setSignData((prev)=> ({...prev, ...data}))
  }

  
const SignUpFlow = {
    0: <SignGetStart handleSaveDate={handleSaveDate} setCurrFlow={setCurrFlow} />,
    1: <ChooseRole handleSaveDate={handleSaveDate} setCurrFlow={setCurrFlow} />,
    2: <AddSignUpInfo handleSaveDate={handleSaveDate} setCurrFlow={setCurrFlow} />,
    3: <CompleteSignup signData={signData} setCurrFlow={setCurrFlow} />
}



    return (
        <div className='bg-AFA h-screen'>
    <div className='p-4 w-full'><CompanyLogo /></div>
    <div className='h-full flex items-start'>
        <div className='w-1/2 h-full'>
        <OnboardingFlow />
        </div>
        <div className='w-1/2 pr-20 h-full'>
        <div className='bg-white rounded-[30px] h-[90%] p-10 max-w-[80%]'>

            {SignUpFlow[currFlow]}
        
        </div>
        </div>
    </div>
   </div>
    )
}