import React from 'react';
import Box from '@mui/material/Box';
import './NewJobRoleModal.css';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { Job } from '../../../../Network/ServiceClass/Job';
import { useState } from 'react';
import { Stack, Typography, CircularProgress } from '@mui/material';
import { font } from '../../../Widgets/Styles/font';
import { MdOutlineCancel } from 'react-icons/md';
import FormModal from '../../../Widgets/Animation/FormModal';
import { toast } from 'react-toastify';
import ColorPicker from '../../../Widgets/ColorPicker';

import {
  Text,
  Field,
  TextAndField,
  FillButton,
  OutlinedButton,
  FieldSelect,
} from '../../../Widgets/FormUtils/FormUtils';
import { IoCloseOutline } from 'react-icons/io5';

const job = new Job();

export const NewJobRoleModal = ({ open, handleClose, callback }) => {
  const [isloading, setLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const formik = useFormik({
    initialValues: {
      title: '',
      desc: '',
      weeklyHours: 0,
      hexcode: '',
      symbol: '',
      dailyRequired: false
    },
    validationSchema: Yup.object({
      title: Yup.string().required('Please input job title'),
      symbol: Yup.string().required('Please input job symbol'),
      weeklyHours: Yup.number().required('Please weekly jhours'),
      desc: Yup.string()
        .min(10, 'Job description is too short')
        .required('Enter Job description'),
    }),
   
    onSubmit: (value, { resetForm}) => {
      setLoading(true);
      job
        .createJobRole(JSON.stringify(value))
        .then((value) => {
          resetForm()
          setLoading(false);
          toast('New job role added successfully');
          handleClose();
          console.log(value);
          callback();
        })
        .catch((err) => {
          console.log(err);
          setLoading(false);
          setErrorMessage(err.response.data.message);
          setIsError(true);
        });
    },
  });

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '400px',
    maxHeight: '90vh',
    bgcolor: 'background.paper',
    // border: '2px solid #000',
    boxShadow: 24,
    p: 4,
    borderRadius: '5px',
    overflowY:'auto',
    overflowX:'hidden'
  };

  return (
    <FormModal open={open} handleClose={handleClose}>
      <Stack className="dark:bg-138 dark:text-white" direction="column" sx={style} gap={3}>
      <div className="flex items-center justify-between w-full">
        <p className='text-[18px] font-medium text-center' >
          New Job Roles
        </p>
        <IoCloseOutline onClick={handleClose} size={25} className='cursor-pointer' />
        </div>

        <ColorPicker
          onChangeColor={(color) => {
            formik.setFieldValue('hexcode', color);
          }}
        />

        <TextAndField>
          <Text text={'Job Title'} />
          <Field
            text={'Kindly enter job title'}
            value={formik.values.title}
            formik={formik}
            name="title"
          />
        </TextAndField>

        <TextAndField>
          <Text text={'Job Symbol'} />
          <Field
            text={'Kindly enter job abbreviation'}
            value={formik.values.symbol}
            formik={formik}
            name="symbol"
          />
        </TextAndField>

        <TextAndField>
          <Text text={'Weekly hours'} />
          <Field
            value={formik.values.weeklyHours}
            formik={formik}
            name="weeklyHours"
            type="number"
          />
        </TextAndField>

        <TextAndField>
          <Text text={'Job Description'} />
          <Field
            text={'Kindly enter description'}
            value={formik.values.desc}
            formik={formik}
            name="desc"
            multiline={true}
            maxRows={3}
          />
        </TextAndField>

        <TextAndField>
          <Text text={'Must Be Filled Daily'} />
          <FieldSelect
            list={[
              { id: false, name: 'No' },
              { id: true, name: 'Yes' },
            ]}
            value={formik.values.dailyRequired}
            formik={formik}
            name="dailyRequired"
          />
        </TextAndField>

        {isError && (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              borderRadius: '0.2rem',
              boxShadow: '0 3px 10px rgb(0 0 0 / 0.2)',
              padding: '0.5rem',
            }}
          >
            <Typography
              sx={{
                ...font,
                fontSize: '12px',
                color: 'red',
                textAlign: 'center',
              }}
            >
              {errorMessage}
            </Typography>
            <MdOutlineCancel
              style={{ color: 'red', fontSize: '23px', cursor: 'pointer' }}
              onClick={() => setIsError(false)}
            />
          </Box>
        )}

        <div
          className='flex justify-between mt-6 w-full'
        >
          {/* <OutlinedButton
            text="Cancel"
            callBack={() => {
              handleClose();
              formik.resetForm();
            }}
          /> */}
          {!isloading && (
            <FillButton text="Submit" width="100%" height={52} callBack={formik.handleSubmit} />
          )}
          {isloading && <CircularProgress />}
        </div>
      </Stack>
    </FormModal>
  );
};
