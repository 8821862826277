import { useState, useEffect, useCallback } from 'react';
import './LoginScreen.css';

import Synclight from "../../../Assets/sync_light.svg";
import SyncdarkLogo from "../../../Assets/sync_logo.svg";
import {ReactComponent as SelectedOption} from "../../../Assets/icons/selected_login_icon.svg";
import {ReactComponent as UnSelectedOption} from "../../../Assets/icons/unselected_login_icon.svg";
import {ReactComponent as CompanyLogo} from "../../../Assets/super_sync_logo.svg";
import {ReactComponent as LoginTable} from "../../../Assets/login_table.svg";
import {ReactComponent as NewSupserLogoIcon} from "../../../Assets/new_sync_sync_logo.svg";

import { useNavigate } from 'react-router';
import { Service } from '../../../Network/ServiceClass/Auth';
import { Storage } from '../../../Network/StorageClass/StorageClass.js';
import { useDispatch } from 'react-redux';
import { setActiveCompany, setDp } from '../../../Store/Company.js';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import {
  Text,
  TextAndField,
  Field,
  FillButton,
} from '../../Widgets/FormUtils/FormUtils';
import { CircularProgress, Stack, Box, Typography, InputAdornment } from '@mui/material';
import { MdOutlineCancel } from 'react-icons/md';
import { font } from '../../Widgets/Styles/font';
import { FiArrowUpRight } from 'react-icons/fi';
import { setLoggedIn } from '../../../Store/socketSlice.js';
import { FaRegEyeSlash} from 'react-icons/fa'
import { LuEye} from 'react-icons/lu'
import { OnboardingFlow } from '../../Widgets/Auth/index.jsx';


export const LoginAuth = () => {
  const dispatch = useDispatch();
  const [showPassword, setPsdVisibility] = useState(false)
  const [selectedOption, selectOption] = useState(true)

  const mode = localStorage.getItem('color-theme') ?? 'light'

  const clearStorage = useCallback(()=>{
    localStorage.removeItem("authtoken")
    localStorage.removeItem("user")
    localStorage.removeItem("activeCompany")
    localStorage.removeItem("companies")
    localStorage.removeItem("picture")

  },[])

  const handleMemberSelect= () => {
    selectOption(false)
  }

  const handleManagerSelect= () => {
    selectOption(true)
  }

  useEffect(() => {
    clearStorage()
    dispatch(setActiveCompany(null));
    dispatch(setDp(null));
  },[]);

  const navigate = useNavigate();
  const serviceClass = new Service();
  const storage = new Storage();

  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
    },

    validationSchema: Yup.object({
      email: Yup.string().required('Please enter valid email address or user name'),
      password: Yup.string().required('Please enter a passoword'),
    }),
    onSubmit: (value) => {
      LoginUser(value.email, value.password);
    },
  });

  const LoginUser = async (user, password) => {
    setIsLoading(true);
    serviceClass
      .LoginUser(user, password)
      .then((value) => {
        storage.AuthStorage(value.data);
        setIsLoading(false);
        // console.log(value.data)
        if (
          value.data.emailVerificationRequired === true &&
          value.data.emailVerificationRequired !== null
        ){
          navigate('/home');
          window.location.reload()
        }
        else{
          
          dispatch(setLoggedIn(true))
          navigate('/home');
          window.location.reload()
        } 
      })
      .catch((err) => {
        setErrorMessage(err?.response?.data?.message);
        setIsError(true);
        setIsLoading(false);
      });
  };

  const handleKeypress = e => {
    //it triggers by pressing the enter key
  if (e.keyCode === 13) {
    formik.handleSubmit();
  }
};

  return (
   <div className='bg-AFA h-screen'>
    <div className='p-4 w-full'><CompanyLogo /></div>
    <div className='h-full flex items-start'>
        <div className='w-1/2 h-full'>
        <OnboardingFlow />
        </div>
        <div className='w-1/2 pr-20 h-full'>
        <div className='bg-white rounded-[30px] p-10 max-w-[80%]'>

            <NewSupserLogoIcon />
            
        <p className="text-[32px] mt-7 font-semibold">Welcome Back to SuprSync</p>
        <p className='text-sm font-medium text-A6A mb-6'>Enter your credentials to access your account.</p>
        <div className="flex flex-col mt-5 gap-10 rounded-input">
          <TextAndField>
            <Text text={'User Name'} />
            <Field
              text={'Kindly enter an email or user name'}
              value={formik.values.email}
              handleChange={formik.handleChange}
              name={'email'}
              formik={formik}
              size="large"
              onKeyDown={handleKeypress}
            />
          </TextAndField>

          <TextAndField>
            <Text text={'Password'} />
            <Field
              text={'Password'}
              value={formik.values.password}
              handleChange={formik.handleChange}
              name={'password'}
              formik={formik}
              size="large"
              type={!showPassword ? 'password' : 'text'}
              onKeyDown={handleKeypress}
              InputProps={{
                endAdornment: (
                  <InputAdornment onClick={()=> setPsdVisibility(prev => !prev)} position="end">
                    {
                      !showPassword ? <FaRegEyeSlash className="cursor-pointer" /> : <LuEye className="cursor-pointer" />
                    }
                    
                  </InputAdornment>
                ),
              }}
            />
          </TextAndField>

          <div className="flex flex-row justify-between text-[10px]">
            <div className="flex flex-row items-center gap-1">
              <input type="checkbox" />
              <p className="text-black">Remember Me </p>
            </div>

            <div className="border-b-1 border-black cursor-pointer">
              <span className='text-black' onClick={() => navigate('/forgot-password')}>
                Forgotten Password
              </span>
            </div>
          </div>

          {isError && (
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                borderRadius: '0.2rem',
                boxShadow: '0 3px 10px rgb(0 0 0 / 0.2)',
                padding: '0.5rem',
              }}
            >
              <Typography
                sx={{
                  ...font,
                  fontSize: '12px',
                  color: 'red',
                  textAlign: 'center',
                }}
              >
                {errorMessage}
              </Typography>
              <MdOutlineCancel
                style={{ color: 'red', fontSize: '23px', cursor: 'pointer' }}
                onClick={() => setIsError(false)}
              />
            </Box>
          )}

          <div>
            {!isLoading && (
              <FillButton
                type="submit"
                text={'Log in'}
                width={'100%'}
                height={'50px'}
                callBack={formik.handleSubmit}
              />
            )}
            {isLoading && (
              <Stack direction="row" justifyContent="center">
                <CircularProgress />
              </Stack>
            )}
            <div className="flex flex-row items-center gap-1 mt-5 text-[10px]">
              <p className="text-[#696969]">Not registered yet,</p>
              <p
                className="ml-[10px] cursor-pointer"
                onClick={() => navigate('/signup')}
              >
                Create an account
              </p>
              <FiArrowUpRight />
            </div>
          </div>
        </div>
        </div>
        </div>
    </div>
   </div>
  );
};
