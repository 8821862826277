import React, { useEffect, useState } from "react";
import AppLayout from "../../Widgets/AppLayout";
import CustomSearchInput from "../../Widgets/CustomSearch";
import { useDispatch, useSelector } from "react-redux";
import { getNotificationAsync, markNotificationAsReadAsync } from "../../../Store/dashboard";
import {ReactComponent as NotificationIcon } from '../../../Assets/notification_icon.svg'
import {ReactComponent as NotificationIconTitle } from '../../../Assets/notification_side_menu.svg'

import moment from 'moment';
import { useNavigate } from "react-router";
import { CircularProgress } from "@material-ui/core";

export function NotificationPage() {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [searchedJobRole, setSearchedJobRoles] = useState('');
  const notifications = useSelector(state => state.dashboard)?.notifications?.data || []
  const loading = useSelector(state => state.dashboard)?.loading

  useEffect(()=>{
    dispatch(getNotificationAsync())
  },[])

  const handleOnSearchJobRole = (e) => {
    setSearchedJobRoles(e.target.value);
  }

  
  const handleMarkAllAsRead = () => {
    const ids = notifications.map((not) => not.id)
    const id = {
      ids
    }
    dispatch(markNotificationAsReadAsync(id)).then((res) => {
      if(res.meta.requestStatus === "fulfilled"){
        dispatch(getNotificationAsync())
      }
    })
  }

  
  const handleClick = (e) => {
    // e.stopPropagation();
    // setIsLoadingMark(true)
    const id = {
      ids: [e.id]
    }

    dispatch(markNotificationAsReadAsync(id)).then((res) => {
      if(res.meta.requestStatus === "fulfilled"){
        dispatch(getNotificationAsync()).then((res) => {
          
          if(e.type === "new_announcement"){
            navigate("/announcement")
            return
          }
          
          
          if(e.type === "shift_reminder"){
            navigate("/schedules")
            return
          }
          
          if(e.type === "shift_cancelled"){
            navigate("/schedules")
            return
          }
          
          if(e.type === "shift_clock_in_late"){
            navigate("/clock-in-clock-out")
            return
          }
          
          if(e.type === "shift_abandoned"){
            navigate("/schedules")
            return
          }
          
          if(e.type === "new_write_up"){
            navigate("/write-ups")
            return
          }
          
          if(e.type === "task_completed"){
            navigate("/tasks")
            return
          }

        })
      }
    })

    // markNotificationAsSeen(id).then(val=>{
    //   setIsLoadingMark(false)
    //   console.log(val)
    // }).catch(err => setIsLoadingMark(false))
  }
  

  return (
    <section>
      <AppLayout
        custom="notifications"
        subCustom=""
        title="See all notifications here"
        customTitle=""
        titleIcon={NotificationIconTitle}
        pageTitle="Notification"
        subtitle="All notifications within your organisation can be seen here."
        RightComponent={
          notifications?.length > 0 && <button onClick={handleMarkAllAsRead} data-tour="step1" className="bg-DF3 rounded-md p-5 text-D57">Mark as Read</button>
        }
      >

        <CustomSearchInput
          data-tour="step2"
          placeholder='im searching for.....'
          onChange={handleOnSearchJobRole}
        />

        <section className="mt-10">

          {
            loading && <div className="flex items-end justify-center h-80"><CircularProgress  /></div>
          }

          {
            !loading && notifications?.length === 0 && <div className="flex items-end justify-center h-72"><p>No Notifications available</p></div>
          }

          {
            !loading && notifications?.length > 0 && React.Children.toArray(
              notifications?.map((not) => (<div onClick={() => handleClick(not)} className="flex cursor-pointer items-start justify-between mb-8">
                <div className="flex items-start gap-2">
                  <NotificationIcon />
                  <div>
                  <p className="text-sm font-medium font-jakarta">{not?.title}</p>
                  <p className="text-[10px] text-060">{not?.message}</p>
                  </div>
                </div>
                <p>{moment(not?.createdOn).format("Do MMMM, yyyy")}</p>
              </div>))
            )
          }

        </section>

      </AppLayout>
    </section>
  )
}