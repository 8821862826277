
export const ticketTourGuide = [
    {
        selector: "[data-tour='step1']",
        content: <div>
            <p className="font-semibold text-2xl !font-jakarta">Create new ticket</p>
            <p className="text-base text-060 !font-jakarta">Click here to create new ticket.</p></div>,
        position: 'top',
        style: {
            left: '-1%',
            top: '-3%'
        },
        // observe: '.home-page-announcement-card'
    },
 
    {
        selector: '[data-tour="step2"]',
        content: <div>
            <p className="font-semibold text-lg !font-jakarta">Search</p>
            <p className="text-base text-060 !font-jakarta">Filter search easily</p></div>,
        position: 'top',
        style: {
            left: '60%',
            top: '33%'
        },
        mutationObservables: ['[data-tour="step3"]']
    }
]


const Layout = ({ children, className, content, ...props }) => {
    return (
        <div className={`bg-white w-[300px] rounded-xl p-4 ${className}`}>
            <div className="w-full">
                {content}
                {children}
            </div>
        </div>
    )
};


export const ticketCustomizeHelper = {
    0: ({ ...props }) => <Layout content={props.content} className="left-arrow" {...props}>
        <div className="flex justify-between items-center mt-2 pt-2 border-t-[0.4px] border-CEC">
            {/* {props.current > 0 ? (<div onClick={() => props.gotoStep(props.current - 1)} className="w-[79px] h-[42px] rounded-[40px] border-1 border-4F9 flex justify-center items-center cursor-pointer"><p className="m-0 text-sm text-767 !font-jakarta">Back</p></div>) : <div className="w-[79px]" />}
            <p className="text-767 text-sm !font-jakarta">{props.current + 1} of {props.totalSteps}</p> */}
            <div onClick={() => {
               props.handleCloseTour();
            }}><div  className="w-[79px] h-[42px] rounded-[40px] border-1 border-72D flex justify-center items-center cursor-pointer"><p className=" text-sm !font-jakarta text-72D">Skip</p></div></div>
            <div onClick={() => {
                props.gotoStep(props.current + 1)
            }}><div  className="w-[79px] h-[42px] rounded-[40px] border-1 bg-D57 text-white flex justify-center items-center text-sm cursor-pointer"><p className=" text-sm !font-jakarta">Next</p></div></div>
        </div>
    </Layout>,
    1: ({ ...props }) => <Layout content={props.content} className="top-arrow" {...props}>
       <div className="flex justify-between items-center mt-2 pt-2 border-t-[0.4px] border-CEC">
            {/* {props.current > 0 ? (<div onClick={() => props.gotoStep(props.current - 1)} className="w-[79px] h-[42px] rounded-[40px] border-1 border-4F9 flex justify-center items-center cursor-pointer"><p className="m-0 text-sm text-767 !font-jakarta">Back</p></div>) : <div className="w-[79px]" />}
            <p className="text-767 text-sm !font-jakarta">{props.current + 1} of {props.totalSteps}</p> */}
            <div onClick={() => {
               props.gotoStep(0);
            }}><div  className="w-[79px] h-[42px] rounded-[40px] border-1 border-72D flex justify-center items-center cursor-pointer"><p className=" text-sm !font-jakarta text-72D">Restart</p></div></div>
            <div onClick={() => {
                props.handleCloseTour();
            }}><div  className="w-[79px] h-[42px] rounded-[40px] border-1 bg-D57 text-white flex justify-center items-center text-sm cursor-pointer"><p className=" text-sm !font-jakarta">Done</p></div></div>
        </div>
    </Layout>,
    2: ({ ...props }) => <Layout content={props.content} className="top-arrow" {...props}>
       <div className="flex justify-between items-center mt-2 pt-2 border-t-[0.4px] border-CEC">
            {/* {props.current > 0 ? (<div onClick={() => props.gotoStep(props.current - 1)} className="w-[79px] h-[42px] rounded-[40px] border-1 border-4F9 flex justify-center items-center cursor-pointer"><p className="m-0 text-sm text-767 !font-jakarta">Back</p></div>) : <div className="w-[79px]" />}
            <p className="text-767 text-sm !font-jakarta">{props.current + 1} of {props.totalSteps}</p> */}
            <div onClick={() => {
                props.gotoStep(0)
            }}><div  className="w-[79px] h-[42px] rounded-[40px] border-1 border-72D flex justify-center items-center cursor-pointer"><p className=" text-sm !font-jakarta text-72D">Restart</p></div></div>
            <div onClick={() => {
                props.handleCloseTour();
            }}><div  className="w-[79px] h-[42px] rounded-[40px] border-1 bg-D57 text-white flex justify-center items-center text-sm cursor-pointer"><p className=" text-sm !font-jakarta">Done</p></div></div>
        </div>
    </Layout>,
   
}