import React from 'react';
import Box from '@mui/material/Box';
import { LiaTimesSolid } from "react-icons/lia";
import FormModal from './Animation/FormModal';

const CustomModal = ({ children, open = false, handleClose, modalClassName='', parentContainer = "" }) => {
  return (
    <div>
      <FormModal open={open} handleClose={handleClose}>
        <div className={`flex items-center w-full h-full mt-[90px] justify-center ${parentContainer}`}>
        <Box className={`bg-white dark:bg-138 w-1/2 py-4 overflow-x-auto px-9 min-h-[200px] rounded-md relative ${modalClassName}`}>
          
          <div onClick={handleClose} className="absolute cursor-pointer rounded-full p-2 right-3 top-10 bg-white flex justify-center items-center dark:bg-138">
            <LiaTimesSolid className='dark:text-white' size={20} />
          </div>
        {children}
        </Box>
        </div>

      </FormModal>
    </div>
  );
};

export default CustomModal;
