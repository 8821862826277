import React,{useState,useEffect} from 'react';
import Dialog from '@mui/material/Dialog';
import { Box, Divider} from '@mui/material';
import {IoClose} from 'react-icons/io5'
import cameraIcon from '../../../../Assets/cameraIcon.png'
import {OutlinedButton} from '../../../Widgets/FormUtils/FormUtils'
import FormModal from '../../../Widgets/Animation/FormModal';

export const LicenseModal = ({open, handleClose, id, callback, openDeleteModal, url, readOnly = false }) => {
  
  
 
  return (
    <div>
      <FormModal open={open} handleClose={handleClose}>
        <Box className='dark:bg-138' sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          bgcolor: 'background.paper',
          width: '623px',
          height: '90vh',
          display: 'flex',
          flexDirection: 'column',
          padding: '0 1rem',
          borderRadius: '10px'
          
        }}>
            {/* first row */}
            <Box sx={{
              alignSelf: 'flex-end',
              marginTop: '-10px',
              marginRight: '6px',
              borderRadius: '20px',
              padding: '2px 4px',
              position: 'fixed',
              cursor:'pointer',
            }} className='dark:bg-292 bg-white' onClick={handleClose}>
              <IoClose className='mt-[0.2rem] text-[#242424] text-[23px] dark:text-white'/>

            </Box>

            <div className='mt-3 flex flex-col items-center dark:text-white gap-2 px-6'>
                <p className='text-base font-semibold'>License</p>
                <p className='text-[10px]'>License name</p>
                <div className='w-[561px] h-[361px] border-1 mt-10'>
                    <center>
                    <img className='w-[500px] h-[300px] mt-10' src={url} alt="camera" />
                    </center>
                        
                </div>

                {!readOnly  && (<div className='flex flex-row justify-between w-[100%] mt-20'>
                    <OutlinedButton text='Upload' width='91px' height={'40px'}/>
                    <OutlinedButton text={'Delete'} width='91px' height={'40px'} themeColor={'#C15353'} bg={'#FAF2F2'} callBack={()=> {openDeleteModal(true); handleClose()}}/>
                </div>)}

            </div>

            

        </Box> 

      </FormModal>
     
    </div>
  );
}

