import { useNavigate } from "react-router";
import {ReactComponent as NewSupserLogoIcon} from "../../../../Assets/new_sync_sync_logo.svg";
import {ReactComponent as SelectedOption} from "../../../../Assets/icons/selected_login_icon.svg";
import {ReactComponent as UnSelectedOption} from "../../../../Assets/icons/unselected_login_icon.svg";
import { FaArrowLeftLong } from "react-icons/fa6";
import { Field, FieldSelect, FillButton, Text, TextAndField } from "../../../Widgets/FormUtils/FormUtils";
import { useEffect, useState } from "react";
import { Service } from "../../../../Network/ServiceClass/Auth";
import { useFormik } from "formik";
import * as Yup from 'yup';
import { useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";
import { Box, Typography } from "@material-ui/core";
import {MdOutlineCancel} from 'react-icons/md'
import { CircularProgress, Stack } from "@mui/material";
import { font } from "../../../Widgets/Styles/font";
import "../SignUpScreen.css";
import { SignUpModal } from "./components/SignUpModal";

export function CompleteSignup({ signData, setCurrFlow }){
    const navigate = useNavigate()
    const [modalStatus, setModalStatus] = useState(false)
    const [selectedOption, selectOption] = useState(true)
    const [countries, setCountries] = useState([])
    const [searchParams, setSearchParams] = useSearchParams();
    const [isLoading, setIsLoading] = useState(false)
    const [isError, setIsError] = useState(false)
    const [errorMessage, setErrorMessage] = useState('')

    const auth = new Service();
    const phoneRegExp = /^(\+\d)\s?-?\s?(\d{0,4})+\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{1,4}\)?)?$/

    const formik = useFormik({
      initialValues: {
        gender: '',
        countryId: '',
        phone: ''
      },
  
      validationSchema: Yup.object({
        gender: Yup.string().notOneOf(['none',null],'Choose a gender').required('Choose a gender'),
        countryId: Yup.string().notOneOf(['none',null],'Select a country'),
        phone: Yup.string().required("Enter phone number").matches(phoneRegExp, 'Phone number is not valid: +172727272 | +1-111-111-11'),
      }),
      onSubmit: (value) => {
        setIsLoading(true);

        const data = {
          ...value,
          ...signData,
          device: {
            "type": "web",
            "token": "NmM3Yy0xNTEzLTRlNDItOGI0Yi1mMTI4"
        }
        }


        delete data.confirmPassword

        
        localStorage.setItem("auth", JSON.stringify(data));

        const numberOfDelay = 4000;

        if(searchParams.get('hash')===null){
            auth.createAccount(JSON.stringify(data)
            ).then((value)=>{
              toast.success('Please check your email for verification')
              setIsLoading(false);
              handleOpenModal()
            setTimeout(()=>{
              handleCloseModal()
              navigate('/login');
            },numberOfDelay)
              // navigate('/login');
            }).catch((err)=>{
              console.log(err); setIsLoading(false);
              setErrorMessage(err.response.data.message);
              setIsError(true)
            });
        }
        else{
          auth.createAccount(JSON.stringify({...data, inviteHash: searchParams.get('hash')}
          )).then((value)=>{
            toast.success('Please check your email for verification')
            setIsLoading(false);
            handleOpenModal()
            setTimeout(()=>{
              handleCloseModal()
              navigate('/login');
            },numberOfDelay)
            
          }).catch((err)=>{
            console.log(err);
            setIsLoading(false);
            setErrorMessage(err.response.data.message);
            setIsError(true)
          });
      }
     
      },
    });


      useEffect(()=>{
        auth.getCountries().then((value)=>{
          setCountries(value);
        }).catch((err)=>{
          console.log(err);
        });   
    
      },[])

      const handleOpenModal = () => {
        setModalStatus(true)
      }

      const handleCloseModal = () => {
        setModalStatus(false)
      }

    return (
        <div className="mt-12">
            <div className="flex items-center gap-2 mb-10">
            <FaArrowLeftLong className="cursor-pointer" onClick={()=>setCurrFlow(2)}  />
            <p>Back</p>
            </div>
        <NewSupserLogoIcon />
        <p className="text-[32px] mt-10 font-semibold">Create your SuprSync Account</p>
        <p className='text-sm font-medium text-A6A mb-6'>Fill in the forms below with the correct details.</p>
        <div className="flex flex-col mt-5 gap-10 dark:text-white rounded-input">
        <form className='flex flex-col gap-[20px] rounded-input'>
            <div className="flex items-center gap-5">
            <TextAndField>
              <Text text={"Gender"} />
              <FieldSelect 
                list={[{name:'Male', id:'male'},{name: 'Female', id: 'female'}]}
                value={formik.values.gender}
                name={'gender'}
                placeholder={'Select gender'}
                formik={formik} size="large" width='100%' height='50px'/>
              </TextAndField>
              <TextAndField>
                    <Text text={"Phone"} />
                    <Field text={'Enter phone number'}
                      value={formik.values.phone}
                      handleChange={formik.handleChange}
                      name={'phone'}
                      formik={formik} size="large"/>
                  </TextAndField>
            </div>

            <TextAndField>
                    <Text text={"Country"} />
                    <FieldSelect text={'Select Country'}
                      list={countries}
                      value={formik.values.countryId}
                      name={'countryId'}
                      placeholder={'Choose country'}
                      formik={formik} size="large" width='100%' height='50px'/>
                  </TextAndField>

                  {isError && 
                    <Box sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      borderRadius: '0.2rem',
                      boxShadow: '0 3px 10px rgb(0 0 0 / 0.2)',
                      padding: '0.5rem'
                    }}>
                      <Typography sx={{...font,fontSize:'12px',color: 'red', textAlign:'center' }}>{errorMessage}</Typography>
                      <MdOutlineCancel style={{color: 'red', fontSize:'23px', cursor:'pointer'}} onClick={()=>setIsError(false)}/>
                    </Box>}

                  {!isLoading && <FillButton text={'Continue to Proceed'} width={'100%'} height={'50px'} callBack={formik.handleSubmit} sx={{ borderRadius: 100}} />}
                  {isLoading && <Stack direction='row' justifyContent='center' ><CircularProgress/></Stack> }
                  

        </form>

        {modalStatus && (<SignUpModal open={modalStatus} handleClose={handleCloseModal} />)}

          {/* <div>
            <FillButton
                type="submit"
                text={'Next'}
                width={'100%'}
                height={'50px'}
                callBack={() =>{
                  formik.handleSubmit();

                }}
                sx={{ borderRadius: 100}}
              />
          </div> */}
        </div>
        </div>
    )
}