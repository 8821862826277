/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import LinearProgress from '@mui/material/LinearProgress';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';

import AppLayout from '../../Widgets/AppLayout';
import {
  fetchJobRolesAsync,
  getBranchesAsync,
  getTeammemberAsync,
} from '../../../Store/Branches';
import {
  cancelScheduleAsync,
  getAllJobGroupAsync,
  getLimboScheduleAsync,
  getShiftTemplateAsync,
  updateLimoScheduleAsync,
} from '../../../Store/Shift';
import { ClickAwayListener } from '@mui/material';
import CustomDropdown from '../../Widgets/CustomDropdown';
import NavigateCalender from '../ShiftScreen/Widget/NavigateCalender/NavigateCalender';
import { getDatesArray } from '../../../utils/dateArray';
import getDates from '../../../utils/rangeOfData';
import DateLabel from '../ShiftScreen/Widget/DateLabel/DateLabel';
import InitialsGen from '../../Widgets/InitialsGen';
import { toast } from 'react-toastify';

export const LimboSchedule = () => {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false)
  const [selectedShift, selectShift] = useState({})
  const memberships = useSelector((state) => state?.company)?.activeCompany
  ?.memberships;
  const privilege =
    useSelector((state) => state?.company)?.privilege || 3;  


  const [displayMode, setDisplayMode] = useState({
    value: 'date-picker',
    label: 'Date picker',
  });

  const [selectedBranch, setBranch] = useState({
    label: 'All Branches',
    value: '',
  });

  const branches =
    useSelector((state) => state?.branches)?.branches?.data || [];

  const jobRoles = useSelector((state) => state?.branches)?.jobRoles;

  const dateCreated = useSelector(
    (state) => state?.company?.activeCompany?.createdOn
  );

  const shiftTemplates =
    useSelector((state) => state?.shift)?.shiftTemplates || [];

  const loading = useSelector((state) => state?.shift)?.loading;
  const limboCancelLoading = useSelector((state) => state?.shift)?.limboCancelLoading;

  const limboSchedules =
    useSelector((state) => state?.shift)?.limboSchedules || [];

  const roleTags = useSelector((state) => state?.shift)?.roleTags;

  const teamMembers = useSelector((state) => state?.branches)?.teamMembers;

  const [selectedRoleTag, setRoleTag] = useState({
    label: 'All role tags',
    value: '',
  });

  const [yearSelected, setYearSelected] = useState({
    label: moment().format('yyyy'),
    value: moment().format('yyyy'),
  });

  const [selectedTemplate, selectTemplate] = useState({
    label: 'Default Shift Template',
    value: '',
  });

  const [selectedJobRole, setJobRole] = useState({
    label: 'All job roles',
    value: '',
  });

  const shiftStateWeeklyDateLabel = useSelector(
    (state) => state.shift.weeklyDateLabel
  );

  const dates =
    (shiftStateWeeklyDateLabel?.from &&
      shiftStateWeeklyDateLabel?.to &&
      getDates(
        shiftStateWeeklyDateLabel?.from,
        shiftStateWeeklyDateLabel?.to
      )) ||
    [];

  useEffect(() => {
    dispatch(getBranchesAsync());
    dispatch(fetchJobRolesAsync());
    dispatch(getTeammemberAsync({}));
    dispatch(getShiftTemplateAsync());
    dispatch(getAllJobGroupAsync());
  }, []);

  useEffect(() => {
    handleGetLimboSchedule()
  }, [shiftStateWeeklyDateLabel]);

  const handleGetLimboSchedule = () => {
    if (shiftStateWeeklyDateLabel.from) {
      dispatch(
        getLimboScheduleAsync({
          from: moment(shiftStateWeeklyDateLabel.from)
            .add(0, 'minutes')
            .add(0, 'hours')
            .format('yyyy-MM-DDTHH:mm'),
          to: moment(shiftStateWeeklyDateLabel.to)
            .add(59, 'minutes')
            .add(23, 'hours')
            .format('yyyy-MM-DDTHH:mm'),
        })
      );
    }
  }

  const handleSelectBranch = (e) => {
    setBranch(e);
  };

  const handleSelectRoleTag = (e) => {
    setRoleTag(e);
  };

  const handleJobRole = (e) => {
    setJobRole(e);
  };

  const handleOnDateChange = (data) => {
    setYearSelected(data);
  };

  const handleSelectedShiftTemplate = (e) => {
    selectTemplate(e);
  };

  const handleViewDisplay = (e) => {
    setDisplayMode(e);
  };

  const selectTeamWithSameJobRole = () => {
    return teamMembers.filter((team) => {
      let roles = [team.jobRole.id];
      roles = [...roles, ...team?.secondaryJobroles?.map((x) => x?.jobroleId)];
      return roles.includes(selectedShift?.slot?.jobRole?.id) && team?.user?.id !== selectedShift?.user.id;
    });
  };

  const handleJobRoleReassign = (team) => {
    dispatch(updateLimoScheduleAsync({ id: selectedShift.id, data: { userId: team?.user?.id}})).then((res) => {
      if(res.meta.requestStatus === "fulfilled"){
        toast.success("Shift was successfully reassigned")
        handleGetLimboSchedule()
        setOpen(false)
      } else {
        toast.error(res?.error?.message)
      }
    })
  }

  const handleCancelSchedule = (id) => {
    const admins = memberships
      .filter((x) => [1, 2].includes(x.privilege))
      ?.map((x) => x.userId);
    dispatch(cancelScheduleAsync({ id, privileges: admins })).then((res) => {
      if (res?.payload?.response?.status === 404) {
        toast.error(res?.payload?.response?.data?.message);
        return;
      }
      if (res?.meta?.requestStatus === 'fulfilled') {
        handleGetLimboSchedule()
        toast.success('Your shift has been cancelled successfully');

      }
    });
  };

  return (
    <div>
      <AppLayout
        custom="shift"
        subCustom="limbo-schedule"
        title="Shifts in Limbo"
        customTitle=" "
        subtitle="Shifts that needs re-assigning"
      >
        <div className=" flex items-center gap-3 mt-16 sticky top-[4.32rem] bg-white dark:bg-primary">
          <CustomDropdown
            data={[{ name: 'All branches', value: '' }, ...branches]?.map(
              (branch) => ({
                label: branch?.name,
                value: branch.id,
                hexcode: branch?.hexcode,
              })
            )}
            className="w-44 h-[43px] dark:border-333"
            value={selectedBranch}
            dropdownTitleClass="text-A5A dark:text-white"
            optionClass="z-[1000] shadow-md min-h-[50px] max-h-[200px] overflow-y-scroll"
            onChangeOption={handleSelectBranch}
          />

          <CustomDropdown
            data={[{ name: 'All role tags', value: '' }, ...roleTags]?.map(
              (roleTag) => ({
                label: roleTag?.name,
                value: roleTag.id,
              })
            )}
            className="w-36 h-[43px] dark:border-333"
            value={selectedRoleTag}
            dropdownTitleClass="text-A5A dark:text-white"
            optionClass="z-[1000] shadow-md min-h-[50px]"
            onChangeOption={handleSelectRoleTag}
          />

          <CustomDropdown
            data={[{ title: 'All job roles', value: '' }, ...jobRoles]?.map(
              (job) => ({ label: job?.title, value: job.id })
            )}
            className="w-[120px] h-[43px] dark:border-333"
            value={selectedJobRole}
            dropdownTitleClass="text-A5A dark:text-white"
            optionClass="z-[1000] shadow-md min-h-[50px] w-[max-content]"
            onChangeOption={handleJobRole}
          />

          <CustomDropdown
            data={[
              { templateName: 'Default Shift Template', id: '' },
              ...(shiftTemplates?.length > 0 ? shiftTemplates : []),
            ]?.map((shift) => ({
              label: shift?.templateName,
              value: shift?.id,
              smallLabel: shift?.jobRole?.title,
            }))}
            className=" w-52 rounded h-[43px] dark:border-333"
            smallLabelClassName="text-gray-400"
            value={selectedTemplate}
            dropdownTitleClass="text-A5A dark:text-white"
            optionClass="z-[1000] shadow-md min-h-[50px] max-h-[200px] overflow-y-scroll"
            onChangeOption={handleSelectedShiftTemplate}
          />
          <NavigateCalender
            layoutProps={{
              headerClass: 'dark:text-white',
              subHeaderClass: 'dark:text-969',
            }}
            diff={7}
          />

          <CustomDropdown
            data={['Date picker'].map((mode) => ({
              label: mode,
              value: mode.toLowerCase().split(' ').join('-'),
            }))}
            className="rounded w-[180px] ml-10 h-[43px] dark:border-333"
            value={displayMode}
            dropdownTitleClass="text-A5A dark:text-white"
            optionClass="z-[1000]"
            onChangeOption={handleViewDisplay}
          />

          <CustomDropdown
            data={getDatesArray(dateCreated, new Date().toString())?.map(
              (dates) => ({ label: dates, value: dates })
            )}
            className="w-28 rounded h-[43px] dark:border-333"
            value={yearSelected}
            dropdownTitleClass="text-A5A dark:text-white"
            optionClass="z-[1000]"
            onChangeOption={handleOnDateChange}
          />
        </div>
        <section className="mt-9">
          {(loading || limboCancelLoading) && <LinearProgress className="mb-1" />}
          <table className="table-fixed">
            <thead className="bg-white dark:bg-138 sticky top-16 z-50">
              {dates?.map((value) => (
                <td className="border z-50 dark:bg-138 dark:border-333 sticky">
                  <DateLabel
                    padding={'10px'}
                    className="shadow-small-select py-3 text-center"
                    detailsWeek={value}
                    type="week"
                    layoutProps={{
                      headerClass:
                        'dark:text-white font-medium text-base text-center',
                      subHeaderClass: 'dark:text-969',
                    }}
                  />
                </td>
              ))}
            </thead>
            <tbody>
              {limboSchedules.length === 0 && (
                <tr>
                  <td colSpan={7}>
                    <div className="w-full h-[300px] flex justify-center items-center dark:text-white">
                      <p>No Shifts available in limbo</p>
                    </div>
                  </td>
                </tr>
              )}

              {limboSchedules.length > 0 && (
                <tr>
                  {dates?.map((x) => (
                    <td className="p-3 border dark:border-333">
                      {limboSchedules
                        ?.filter(
                          (limb) =>
                            moment(new Date(x)).format('yyyy-MM-DD') ===
                            moment(limb.start).format('yyyy-MM-DD')
                        )
                        ?.map((schedule) => (
                          // <ClickAwayListener
                          // onClickAway={()=> setOpen(false)}
                          // >
                          <div
                            style={{
                              backgroundColor:
                                schedule?.slot?.branch?.hexcode || 'white',
                            }}
                            className=" group/info relative rounded-md mb-5 h-full text-sm cursor-pointer"
                            onClick={(e)=> {
                              e.stopPropagation()
                              if(open){
                                setOpen(false)
                              }
                            }}
                          >
                            <div className="relative hover:bg-424 hover:rounded-md">
                              <div>
                            <div className='pl-3 py-3'>
                            <p className="font-semibold">
                              {schedule?.slot?.branch?.name}
                            </p>
                            <div className="flex items-center gap-2">
                              <p className="font-medium">
                                {schedule?.slot?.jobRole?.title}
                              </p>
                              <div
                                className="w-2 h-2 rounded-full"
                                style={{
                                  backgroundColor:
                                    schedule?.slot?.jobRole?.hexcode,
                                }}
                              />
                            </div>
                            <p className="font-medium">
                              {schedule?.slot?.startTime}-
                              {schedule?.slot?.endTime}
                            </p>
                            <div className="flex items-center">
                              <div className="group-hover/info:invisible">
                                {schedule?.user?.picture?.url ? (
                                  <img
                                    className="rounded-full h-10 w-10 mr-2"
                                    src={schedule?.user?.picture?.url}
                                    alt="logo"
                                  />
                                ) : (
                                  <InitialsGen
                                    width={20}
                                    name={`${schedule?.user?.firstName} ${schedule?.user?.lastName}`}
                                    bgColor="#00AD57"
                                  />
                                )}
                              </div>
                              <p className="font-semibold text-[10px]">
                                {schedule?.user?.firstName}{' '}
                                {schedule?.user?.lastName}
                              </p>
                            </div>
                            </div>
                            {open && selectedShift.id === schedule.id && [1,2].includes(privilege) && (<div className="absolute bg-white dark:bg-292 z-50 top-[90%] min-h-[50px] max-h-[200px] overflow-y-auto left-5 w-max shadow-select">
                              {
                                selectTeamWithSameJobRole()?.map((team) => (
                                  <p onClick={()=>handleJobRoleReassign(team)} className="flex items-center gap-3 px-4 py-2 cursor-pointer hover:bg-787">{team?.user?.firstName} {team?.user?.lastName}</p>
                                ))
                              }
                            </div>)}
                              </div>
                              <div className=" hidden absolute top-8 left-[33%] group-hover/info:flex flex-col gap-4 z-30 items-center group/sel text-center text-white">
                                <p 
                                onClick={()=>handleCancelSchedule(schedule.id)}
                                className="hover:text-B1B font-bold">Cancel</p>
                                <p
                                onClick={() => {
                                  setOpen((prev) => !prev)
                                  selectShift(schedule)
                                }}
                                className="hover:text-D57 font-bold"
                                >Reassign</p>
                              </div>
                            </div>
                          </div>
                          // </ClickAwayListener>
                        ))}
                    </td>
                  ))}
                </tr>
              )}
            </tbody>
          </table>
        </section>
      </AppLayout>
    </div>
  );
};
