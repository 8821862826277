/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useCallback } from 'react';
import { SideBar } from '../../../../Widgets/SideBar';
import { BranchesHeader } from '../../../BranchScreen/BranchesHeader/BranchesHeader';
import { BsChevronDown } from 'react-icons/bs';
import { Shift } from '../../../../../Network/ServiceClass/Shift';
import './ShiftSlotPage.css';
import { useSelector } from 'react-redux';
import NavigateCalender from '../../Widget/NavigateCalender/NavigateCalender';
import getDates from '../../../../../utils/rangeOfData';
import DateLabel from '../../Widget/DateLabel/DateLabel';
import { Loader } from '../../../../Widgets/Loader';
import SlotCard from './Widgets/SlotCard/SlotCard';
import BookSlot from './Widgets/BookSlot/BookSlot';
import AlertDialog from '../../../../Widgets/SuccessDialog';
import { AnimatePresence } from 'framer-motion';
import ButtonWithIcon from '../../../../Widgets/ButtonWithIcon';
import RequestOverTime from './Widgets/RequestOverTime';
import {getWeeklyWorkedBooked} from '../../../../../Network/ServiceClass/OverTimeService'
import { getWeek } from '../../../../../utils/getWeek';
import formatDate from '../../../../../utils/formatDate';
import PlaceHolderOne from '../../../../Widgets/placeholders/PlaceHolderOne';
import { CreateShiftSlot } from './Widgets/CreateShift/CreateShiftSlot';
import { Branch } from '../../../../../Network/ServiceClass/Branch';
import CustomDropdown from '../../../../Widgets/CustomDropdown';
import { ClickAwayListener } from '@material-ui/core';
import { NewSideBar } from '../../../../Widgets/NewSideBar';


export const ShiftSlotPage = () => {
  const shift = new Shift();
  const branch = new Branch()

  const [avalableSlot, setAvailableSlot] = useState(false);
  const [shiftspots, setShiftSpots] = useState(null);
  const [selected, setSelected] = useState(null);
  const [selectedBranch, setBranch] = useState({label: 'All locations', value: ''});
  const [openDrawer, setOpenDrawer] = useState(false);
  const [createSlot, setCreateSlot] = useState(false)
  const [branches, setBranches] = useState([])
  const [shiftSlotData, setShiftSlotData] = useState({})

  const handleOpenCreateSlot = () => {
    setCreateSlot(true);
  };
  const handleCloseCreateSlot = () => {
    setCreateSlot(false);
   
  };

  const handleCloseDrawer = (value) => {
    setOpenDrawer(value);
  };

  const handleSelectRot = (e) => {
    setBranch(e);
  }

  const [isloading, setIsloading] = useState(false);
  const [bookedHours, setBookedHours] = useState(0)

  const shiftStateWeeklyDateLabel = useSelector(
    (state) => state.shift.weeklyDateLabel
  );
  const dates = getDates(
    shiftStateWeeklyDateLabel.from,
    shiftStateWeeklyDateLabel.to
  );

  const shiftStateWeeklyDate = useSelector((state) => state.shift.weeklyDate);

  const loops = Object.values(shiftspots ?? {})
    .map((value) => value.length)
    .reduce((a, b) => (a > b ? a : b), 0);

  const handleShift = useCallback(() => {
    setIsloading(true);
    shift
      .fetchShiftSlotAvailability(
        shiftStateWeeklyDate.from,
        shiftStateWeeklyDate.to,
        selectedBranch.value
      )
      .then((value) => {
        setShiftSpots(value.data);
        setIsloading(false);
      })
      .catch((err) => {
        setIsloading(false);
      });
  },[shift, shiftStateWeeklyDate.from, shiftStateWeeklyDate.to, selectedBranch.value]);

  const from = formatDate(getWeek(0).firstDay.toUTCString())

  useEffect(() => {
    handleShift();
    getWeeklyWorkedBooked(from).then(val => {
      setBookedHours(val[0].hours)
    })
  }, [shiftStateWeeklyDate, selectedBranch, from]);

  useEffect(()=> {
    branch
    .getBranches()
    .then((value) => {
      setBranches(value.data);
    })
    .catch((err) => {
      console.log(err);
    });
  },[])

  const solveDate = (index) => {
    if (index === 6) return '0';
    else return (index + 1).toString();
  };

  const handleOnClickAvailable = (value) => {
    setAvailableSlot(value);
  };

  const [openOverTime, setOpenOverTime] = useState(false)

  return (
    <section className="homepage">
      <AlertDialog />
      {/* <RequestOverTime openModal={openOverTime} setOpenModal={setOpenOverTime}/> */}
      <CreateShiftSlot
        open={createSlot}
        handleClose={handleCloseCreateSlot}
        branches={branches}
        refresh={handleShift}
        shiftSlotData={shiftSlotData}
      />
      <AnimatePresence>
        {openDrawer && (
            <BookSlot
              openDrawer={openDrawer}
              handleCloseDrawer={handleCloseDrawer}
              data={selected}
              setShiftSpots={setShiftSpots}
            />
        )}
        
      </AnimatePresence>

      <NewSideBar custom="shift" subCustom="shift-slot">
        <section className="overview">
          <div className="flex items-center">
            <BranchesHeader
              title="Shift slots"
              subtitle="View availables shift slot and book"
            />

            {/* <div className="bg-6E6 rounded p-1 ml-[30%]">
              <button
                onClick={() => handleOnClickAvailable(false)}
                className={`rounded text-18 p-2 mr-1 ${
                  !avalableSlot ? 'bg-white' : 'bg-6E6 text-grey-600'
                }`}
              >
                Available shifts
              </button>
              <button
                onClick={() => handleOnClickAvailable(true)}
                className={`rounded text-18 p-2 ${
                  avalableSlot ? 'bg-white' : 'bg-6E6 text-grey-600'
                }`}
              >
                Your slots
              </button>
            </div> */}

          </div>

          <section
            className="shift-slot"
            style={{
              display: 'inline-flex',
              width: '100%',
              justifyContent: 'space-between',
            }}
          >
            <div style={{ width: '100%' }}>
              <div className='flex flex-row justify-between mb-2'>
                
                <div className='flex flex-row items-center gap-2'>
                  <div>
                    <CustomDropdown
                    data={[{name: 'All locations', value: ''}, ...branches]?.map((branch) => ({ label: branch?.name, value: branch.id}))}
                    value={selectedBranch}
                    optionClass='z-[1000] shadow-md min-h-[50px]'
                    onChangeOption={handleSelectRot}
                    />
                  </div>
                  <NavigateCalender diff={7} />
                  <p className='text-[#A5A5A5] text-base'>Hours Booked : {Math.round(bookedHours)} </p>
                </div>
                <ButtonWithIcon title='Create Available Shifts' p='7px' width='250px' handleClick={handleOpenCreateSlot}/>

                {/* <ButtonWithIcon title='Request over time' p='8px' handleClick={()=>setOpenOverTime(true)}/> */}
                
              </div>

              <div
                style={{
                  height: '60vh',
                  border: '1px solid #E6E6E6',
                
                  marginTop: '0px',
                  borderRadius: '10px',
                }}
              >
                {loops !== 0 && <>
                  {(!isloading ) ? (
                    <div className='h-[70vh] overflow-y-auto'>
                      
                      <table cellSpacing={0} cellPadding={0} >
                        <thead className='sticky bg-white top-0'>
                          {dates.map((value, index) => {
                            if (index < 8)
                              return (
                                <td className="cursor-pointer">
                                  {' '}
                                  <DateLabel
                                    padding={'10px'}
                                    detailsWeek={value}
                                    type="week"
                                  />
                                </td>
                              );
                          })}
                        </thead>

                        <tbody>
                          {shiftspots && !isloading
                            ? Array(loops)
                                .fill(0)
                                .map((_, i) => (
                                  <tr>
                                    {dates.map((head, index) => (
                                      <td
                                        className="border p-1"
                                        style={{
                                          padding: '2px',
                                          cursor: 'pointer',
                                        }}
                                        onClick={() => {
                                          const shiftSpot = shiftspots[solveDate(index)][i] ?? null
                                          if(shiftSpot) {
                                            setSelected(
                                              shiftSpot
                                            );
                                            setOpenDrawer(true);
                                          } else {
                                            let branchId = undefined;
                                            if(selectedBranch.value){
                                              branchId = selectedBranch.value
                                            }
                                            setShiftSlotData({date: dates[index], branchId})
                                            setCreateSlot(true);
                                          }
                                        }}
                                      >
                                        <SlotCard
                                          shiftspot={
                                            shiftspots[solveDate(index)][i] ?? null
                                          }
                                        />
                                      </td>
                                    ))}
                                  </tr>
                                ))
                            : null}

                          {isloading
                            ? new Array(7).fill(0).map((_, i) => (
                                <tr>
                                  {new Array(dates.length).fill(0).map((_, j) => (
                                    <td className="border p-1">
                                      <SlotCard />
                                    </td>
                                  ))}
                                </tr>
                              ))
                            : null}
                        </tbody>
                      </table>
                    </div>
                  ) : (
                    <Loader />
                  )}
                </>}
                {(!isloading && loops === 0) && <div className='mt-10'><PlaceHolderOne/></div>}
              </div>
            </div>
          </section>
        </section>
      </NewSideBar>
    </section>
  );
};
