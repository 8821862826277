import React, { useState } from 'react';
import {Box, CircularProgress, Stack, Typography} from '@mui/material';
import Modal from '@mui/material/Modal';
import { Branch } from '../../../../../../Network/ServiceClass/Branch';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import {Text, TextAndField, Field, OutlinedButton, FillButton} from '../../../../../Widgets/FormUtils/FormUtils'
import {MdOutlineCancel} from 'react-icons/md'
import {font} from '../../../../../Widgets/Styles/font'
import { useDispatch } from 'react-redux';
import { setSuccessAlert } from '../../../../../../Store/Company';
import successImage from '../../../../../../Assets/success.png'
import FormModal from '../../../../../Widgets/Animation/FormModal';
import { toast } from 'react-toastify';


export const WriteUpModal = ({ open, handleClose, userId, branchId }) => {
  const branch = new Branch();
  const dispatch = useDispatch()

  const [isloading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')

  const formik = useFormik({
    initialValues: {
      title:"",
      body:"",
    },
    validationSchema: Yup.object({
      title: Yup.string().required("Title required"),
      body: Yup.string().required("Body required"),
    }),
    onSubmit: (value)=>{
      WriteUp(value.title, value.body)
    }
  }) 

  

  const WriteUp = (title, body) => {
      setIsLoading(true);
      branch
        .WriteUp({
          userId: userId,
          title: title,
          body: body,
        })
        .then((value) => {
          console.log(value);
          setIsLoading(false);
          toast('Write Up added successfull')
        //   dispatch(setSuccessAlert({
        //     status:true,
        //     title: 'Successful',
        //     content: 'Added Successfull',
        //     image: successImage

        // }))
          handleClose();
        })
        .catch((err) => {
          console.log(err);
          setIsLoading(false);
          setErrorMessage(err.response.data.message);
          setIsError(true)
        });
  };


  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '423px',
    maxHeight: '100vh',
    bgcolor: 'background.paper',
    // border: '2px solid #000',
    boxShadow: 24,
    p: 4,
    borderRadius: '5px',
    overflowY:'auto',
    overflowX:'hidden'
  };

  return (
    <FormModal open={open} handleClose={handleClose}>
      <Box className="dark:bg-138 dark:text-white" sx={style}>
        <div className='flex flex-col items-center gap-3 dark:bg-138 dark:text-white'>
          <p className='text-[18px] font-semibold'>Write up</p>

          <TextAndField space={0.5}>
            <Text text={'Title'}/>
            <Field text={'Kindly enter title'} 
                value={formik.values.title} 
                formik={formik} 
                name='title'/>
          </TextAndField>

          <TextAndField space={0.5}>
            <Text text={'Body'}/>
            <Field text={'Kindly enter body'} 
                value={formik.values.body} 
                formik={formik} 
                name='body' 
                multiline={true} maxRows={4}/>
          </TextAndField>

          {isError && 
            <Box sx={{
                display: 'flex',
                justifyContent: 'center',
                borderRadius: '0.2rem',
                boxShadow: '0 3px 10px rgb(0 0 0 / 0.2)',
                padding: '0.5rem'
            }}>
                <Typography sx={{...font,fontSize:'12px',color: 'red', textAlign:'center' }}>{errorMessage}</Typography>
                <MdOutlineCancel style={{color: 'red', fontSize:'23px', cursor:'pointer'}} onClick={()=>setIsError(false)}/>
            </Box>}

            <Stack direction='row' width='82%' justifyContent='space-between' mt={6}>
                <OutlinedButton text='Cancel' callBack={()=>{formik.resetForm(); handleClose()}}/>
                {!isloading && <FillButton text='Submit' callBack={formik.handleSubmit}/>  }
                
                {isloading && <CircularProgress/> }
            </Stack>

        </div>
      </Box>
    </FormModal>
  );
};
