import React, { useEffect } from 'react';
import { HomeNavbar } from './HomeNavbar';
import  {SideBar} from './SideBar';
import { HomeLayout } from './HomeLayout';
import { BranchesHeader } from '../Screens/BranchScreen/BranchesHeader/BranchesHeader';
import LiveUsers from '../Screens/LiveUsers/LiveUsers';
import { AnimatePresence } from 'framer-motion';
import CliveChat from '../Screens/CliveChat';
import Tour from 'reactour'
import { customizeHelper, tourGuide } from '../../utils/tourGuide';
import { useLocation, useNavigate } from 'react-router';
import { TourModal } from './TourModal/TourModal';
import { useDispatch } from 'react-redux';
import { finishTourThunk } from '../../Store/Company';
import { NewSideBar } from './NewSideBar';

const AppLayout = ({subCustom, customTitle, titleIcon, pageTitle="", leftComponent=true, layoutClass="", mainClass="", layoutProps={}, RightComponent = <div />, custom, title, subtitle, children, withHeader=true, withoutHeaderTitle}) => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [isTourOpen, setOpenTour] = React.useState(false)
  const [isModalOpen, setModalOpen] = React.useState(false)

  useEffect(()=>{
    const user = localStorage.getItem("new-user") 
    const userPayload = localStorage.getItem("user")
    const activeCompany = localStorage.getItem("activeCompany")
    let usr = {}
    if(userPayload){
      usr = JSON.parse(userPayload)
    }

    if(user === "1"){
      setModalOpen(true)
    }

    if(user === "0"){
      setOpenTour(true)
    }


    if(usr && activeCompany !== "undefined"){
      if(usr.isNew && user === null ){
        setModalOpen(true)
      }
    }
  },[])

  const handleCloseTour = () => {
    setOpenTour(false)
    setModalOpen(false)
    dispatch(finishTourThunk()).then((res)=>{
      localStorage.removeItem("new-user") 
    })
  }

  const handleOpenTourModal = () =>{
    setModalOpen(false)
    navigate("/home")
    localStorage.setItem("new-user", 0)
    setOpenTour(true)
  }

  return (
    <section className='homepage relative'>

        <NewSideBar pageTitle={pageTitle} titleIcon={titleIcon} layoutProps={layoutProps} mainClass={mainClass} layoutClass={layoutClass} window={window} custom={custom} subCustom={subCustom} customTitle={customTitle} withoutHeaderTitle={withoutHeaderTitle}>
        {withHeader ? <section className={`overview ${layoutClass}`}>

          <section className="flex justify-between mb-8 items-center dark:text-white">
               {leftComponent && (<BranchesHeader headerProps={layoutProps} title={title || ''} subtitle={subtitle} />)}
               {RightComponent}
          </section>


              <div>

                {
                  children
                }
              </div>

             
          </section> : <div>{children}</div>}
        </NewSideBar>
{/* 
        <Tour 
        isOpen={isTourOpen}
        steps={tourGuide}
        onRequestClose={handleCloseTour}
        disableDotsNavigation
        closeWithMask={false}
        showNumber={false}
        startAt={Number(localStorage.getItem("step")) || 0}
        showButtons={false}
        CustomHelper={({...props}) => <div>{customizeHelper[props.current]({...props, navigate,handleCloseTour})}  </div>}
        /> */}
   
            <div className='fixed bottom-0 right-0 z-[200] h-16'>

                <AnimatePresence>
                  <LiveUsers />
                </AnimatePresence>


                <AnimatePresence>
                  <CliveChat />
                </AnimatePresence>
            </div>
            {isModalOpen && (<TourModal open={isModalOpen} handleClose={handleCloseTour}  handleTour={handleOpenTourModal} />)}
</section>
  )
}

export default AppLayout;