import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { Service } from '../Network/ServiceClass/Auth';

const ServiceClass = new Service();

export const getCountryAsync = createAsyncThunk('util/getCountries', ServiceClass.getCountries);
export const getAllSkillSetThunk = createAsyncThunk('util/getAllSkillSet', ServiceClass.getAllSkillSet);

const initialState = {
  countries: [],
  skillSets: [],
  loading: false
}

const UtilSlice = createSlice({
  name: 'util',
  initialState,
  extraReducers: {
    [getCountryAsync.pending]: (state, action) => {
      state.loading = true;
    },
    [getCountryAsync.fulfilled]: (state, action) => {
      state.loading = false;
      state.countries = action.payload
    },
    [getCountryAsync.rejected]: (state, action) => {
      state.loading = false;
    },
    [getAllSkillSetThunk.pending]: (state, action) => {
      state.loading = true;
    },
    [getAllSkillSetThunk.fulfilled]: (state, action) => {
      state.loading = false;
      state.skillSets = action.payload.data
    },
    [getAllSkillSetThunk.rejected]: (state, action) => {
      state.loading = false;
    },
  }
})

export default UtilSlice.reducer;