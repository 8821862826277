import { Modal } from "@material-ui/core";
import { Stack } from "@mui/material";
import {ReactComponent as EmailSent } from "../../../../Assets/email-sent.svg"



export function ForgotPasswordModal({ open = false, handleClose, navigate }) {
  return (
    <Modal open={open} onClose={handleClose}>
      <Stack
       direction="column"
       alignItems="center"
       sx={{ height: '100vh' }}
       justifyContent="center"
      >
        <div className="px-[20px] flex flex-col rounded-[11px] justify-end py-[50px] bg-white w-[612px] h-[335px] text-center">
            <EmailSent />
          <p className="text-[28px] font-semibold">Reset password link sent</p>
          <p className="text-base text-A6A">Reset password link has been sent to your email. Follow the instructions to set your password</p>
          <p onClick={()=> navigate("/login")} className="text-E42 text-sm font-semibold cursor-pointer">Back to Home</p>
        </div>
      </Stack>
    </Modal>
  )
}