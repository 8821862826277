import React from 'react';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import './CreateBranchModal.css';
import { useState, useEffect } from 'react';
import { Home } from '../../../../../../Network/ServiceClass/Home';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { Branch } from '../../../../../../Network/ServiceClass/Branch';
import { font } from '../../../../../Widgets/Styles/font';
import {
  Text,
  Field,
  TextAndField,
  FillButton,
  FieldSelect,
  OutlinedButton,
} from '../../../../../Widgets/FormUtils/FormUtils';
import GooglePlacesAutocomplete, { geocodeByPlaceId, getLatLng} from 'react-google-places-autocomplete';
import { CircularProgress, Stack, Typography } from '@mui/material';
import { MdOutlineCancel } from 'react-icons/md';
import FormModal from '../../../../../Widgets/Animation/FormModal';
import { toast } from 'react-toastify';
import ColorPicker from '../../../../../Widgets/ColorPicker';
import { IoCloseOutline } from 'react-icons/io5';

function CreateBranchModal({ open, handleClose, callback }) {
  // const { isLoaded } = useJsApiLoader({
  //   id: 'google-map-script',
  //   apiKey: process.env.REACT_APP_GOOGLE_API_KEY,
  //   libraries: ['places']
  // })

  const home = new Home();
  const branch = new Branch();

  const [countries, setCountries] = useState([]);
  const [comstate, setComState] = useState([]);
  const [cities, setCities] = useState([]);
  const [isloading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [value, setValue] = useState(null);
  
  const [errorMessage, setErrorMessage] = useState('');

  const formik = useFormik({
    initialValues: {
      name: '',
      symbol: '',
      address: '',
      longitude: '',
      latitude: '',
      stateId: '',
      countryId: '',
      cityId: '',
      zipCode: '',
      hexcode: '',
    },
    validationSchema: Yup.object({
      name: Yup.string()
        .min(2, 'branch name is too short')
        .required('Please enter branch name'),
      address: Yup.string().required('Please enter address'),
      symbol: Yup.string().required('Please enter branch symbol'),
      // countryId: Yup.string().required('Please select your country'),
      zipCode: Yup.string().required('Please enter Zipcode'),
      // cityId: Yup.string().required("Please select city")
    }),

    onSubmit: (value) => {
      if (
        city !== '' &&
        country !== '' &&
        value.zipCode !== '' &&
        state !== '' &&
        value.writeUp !== ''
      ) {
        if (value['longitude']) {
          value['longitude'] = Number(value['longitude']);
        } else {
          delete value.longitude;
        }

        if (value['latitude']) {
          value['latitude'] = Number(value['latitude']);
        } else {
          delete value.latitude;
        }

        value['countryId'] = country;
        value['cityId'] = city;
        value['stateId'] = state;

        setIsLoading(true);
        branch
          .createNewBranch(JSON.stringify(value))
          .then((value) => {
            setIsLoading(false);
            toast('New Location has been created successfully');
            callback();
            handleClose();
            setValue(null)
            // console.log(value);
          })
          .catch((err) => {
            console.log(err);
            setIsLoading(false);
            setErrorMessage(err.response.data.message);
            setIsError(true);
          });
      } else {
        setErrorMessage('One of city, state, country is not selected');
        setIsError(true);
      }
    },
  });

  useEffect(() => {
    home
      .getCountries()
      .then((value) => {
        setCountries(value.data);
      })
      .catch((error) => {
        console.log(error.response.message);
      });
  }, []);

  let LoadState = (id) => {
    home
      .getState(id)
      .then((value) => {
        setComState(value.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  let LoadCity = (id) => {
    home
      .getCity(id)
      .then((value) => {
        setCities(value.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '500px',
    maxHeight: '700px',
    bgcolor: 'background.paper',
    // border: '2px solid #000',
    boxShadow: 24,
    p: 4,
    borderRadius: '5px',
    overflowY: 'auto',
    overflowX: 'hidden',
  };
  const [country, setCountry] = useState('');
  const [state, setState] = useState('');
  const [city, setCity] = useState('');

  const handleGetAddress = async (e) => {
    setValue(e)
    const [geocoder] = await geocodeByPlaceId(e.value.place_id);
    const latlng = await getLatLng(geocoder);
    formik.setFieldValue("latitude", latlng.lat);
    formik.setFieldValue("longitude", latlng.lng);
    formik.setFieldValue("address", e.label);
  }

  return (
    <FormModal open={open} handleClose={handleClose}>
      <Stack className='dark:bg-138 dark:text-white' direction="column" alignItems="start" sx={style} gap={3}>
        <div className="flex items-center justify-between w-full">
        <Typography sx={{ ...font, fontSize: '18px', fontWeight: '600', textAlign: 'left' }}>
          Create location
        </Typography>

        <IoCloseOutline onClick={handleClose} size={32} className='cursor-pointer' />
        </div>

        <ColorPicker
          onChangeColor={(color) => {
            formik.setFieldValue('hexcode', color);
          }}
        />

        <TextAndField>
          <Text text={'Location name'} />
          <Field
            text={'Kindly enter location name'}
            value={formik.values.name}
            formik={formik}
            name="name"
          />
        </TextAndField>

        <TextAndField>
          <Text text={'Location symbol'} />
          <Field
            text={'Kindly enter location abbreviation'}
            value={formik.values.symbol}
            formik={formik}
            name="symbol"
          />
        </TextAndField>

        <TextAndField>
          <Text text={'Country'} />
          <FieldSelect
            list={countries}
            value={country}
            formik={formik}
            width='100%'
            name="countryId"
            callback={(val) => {
              setCountry(val.target.value);
              LoadState(val.target.value);
            }}
          />
        </TextAndField>

        <TextAndField>
          <Text text={'State'} />
          <FieldSelect
            list={comstate}
            value={state}
            formik={formik}
            width='100%'
            name="stateId"
            callback={(val) => {
              setState(val.target.value);
              LoadCity(val.target.value);
            }}
          />
        </TextAndField>

        <TextAndField>
          <Text text={'City'} />
          <FieldSelect
            width='100%'
            list={cities}
            value={city}
            formik={formik}
            name="cityId"
            callback={(val) => {
              setCity(val.target.value);
            }}
          />
        </TextAndField>

        <TextAndField>
          <Text text={'Zipcode'} />
          <Field
            text={0}
            value={formik.values.zipCode}
            formik={formik}
            name="zipCode"
          />
        </TextAndField>

        <TextAndField>
          <Text text={'Address'} />
          <div className="w-full">
            <GooglePlacesAutocomplete
              apiKey={process.env.REACT_APP_GOOGLE_API_KEY}
              debounce={500}
              selectProps={{
                value,
                onChange: handleGetAddress,
                placeholder: 'Enter Address',
                styles: {
                  singleValue: (provider) =>({
                    ...provider,
                    fontSize: 12
                  })
                }
              }}
              onLoadFailed={(error) =>
                console.error('Could not inject Google script', error)
              }
            />
          </div>
        </TextAndField>
        <TextAndField>
          <Text text={'Longitude'} />
          <Field
            text={'Kindly enter location longitude'}
            value={formik.values.longitude}
            formik={formik}
            name="longitude"
          />
        </TextAndField>
        <TextAndField>
          <Text text={'Latitude'} />
          <Field
            text={'Kindly enter location latitude'}
            value={formik.values.latitude}
            formik={formik}
            name="latitude"
          />
        </TextAndField>

        {isError && (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              borderRadius: '0.2rem',
              boxShadow: '0 3px 10px rgb(0 0 0 / 0.2)',
              padding: '0.5rem',
            }}
          >
            <Typography
              sx={{
                ...font,
                fontSize: '12px',
                color: 'red',
                textAlign: 'center',
              }}
            >
              {errorMessage}
            </Typography>
            <MdOutlineCancel
              style={{ color: 'red', fontSize: '23px', cursor: 'pointer' }}
              onClick={() => setIsError(false)}
            />
          </Box>
        )}

        <Stack
          direction="row"
          width="100%"
          justifyContent="space-between"
          mt={6}
        >
          {/* <OutlinedButton
            text="Cancel"
            callBack={() => {
              formik.resetForm();
              setValue(null)
              handleClose();
            }}
          /> */}
          {!isloading && (
            <FillButton height={52} width="100%" text="Create location" callBack={formik.handleSubmit} />
          )}

          {isloading && <CircularProgress />}
        </Stack>
      </Stack>
    </FormModal>
  );
}

export default CreateBranchModal;
