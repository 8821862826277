import {
  Typography,
  TextField,
  Button,
  Stack,
  IconButton,
} from "@mui/material";
import { font } from "../Styles/font";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import {AiOutlineCaretDown} from 'react-icons/ai'
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { makeStyles } from "@material-ui/core/styles";
import NativeSelect from "@mui/material/NativeSelect";

const mode = localStorage.getItem("color-theme") ?? "light";

const darkTheme = {
  bg: "#1A1A1A",
  border: "#333333",
  hoverBorder: "#696969",
};

export const Text = ({
  text,
  size = "12px",
  weight = "500",
  className = "",
}) => {
  return (
    <Typography
      className={className}
      sx={{ fontSize: size, fontWeight: weight, ...font }}
    >
      {text}
    </Typography>
  );
};

const useStyles = makeStyles({
  root: {
    "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
      borderColor: "red",
    },
  },
  borderNone: {
    "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
      border: "none",
    },
  },

  darkMode: {
    "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
      borderColor: darkTheme.border,
      "&:hover": {
        borderColor: darkTheme.hoverBorder,
      },
    },
  },
});

export const Field = ({
  text,
  handleChange = () => {},
  disable = false,
  type,
  sx,
  value,
  formik,
  name,
  multiline,
  maxRows,
  minRows,
  width,
  size = "small",
  containerWidth = "100%",
  className,
  onKeyDown,
  ...rest
}) => {
  const classes = useStyles();
  return (
    <>
      <TextField
        id="outlined-basic"
        size={size}
        name={name === undefined ? "" : name}
        disabled={disable}
        type={type === undefined ? "text" : type}
        variant="outlined"
        placeholder={text}
        inputProps={{
          onKeyDown: onKeyDown,
          style: {
            fontSize: 14,
            ...font,
            width: width !== undefined ? width : "100%",
            color:
              formik?.errors[name] && formik?.touched[name]
                ? "red"
                : mode === "light"
                ? "#5A5A5A"
                : "white",
            ...sx,
          },
        }}
        value={value === undefined ? "" : value}
        onChange={formik === undefined ? handleChange : formik.handleChange}
        onBlur={formik === undefined ? () => {} : formik.onBlur}
        className={
          formik?.errors[name] && formik?.touched[name]
            ? classes.root
            : mode === "light"
            ? className
            : classes.darkMode
        }
        multiline={multiline !== undefined ? multiline : false}
        minRows={(minRows || maxRows) !== undefined ? minRows || maxRows : 1}
        maxRows={maxRows !== undefined ? maxRows : 1}
        sx={{ width: containerWidth,  ...sx }}
        {...rest}
      />
      {formik?.errors[name] && formik?.touched[name] && (
        <Typography
          sx={{ fontSize: "10px", ...font, color: "red", p: 0, mt: 2 }}
        >
          {formik.errors[name]}
        </Typography>
      )}
    </>
  );
};

export const FieldSelect = ({
  list,
  value,
  formik,
  name,
  callback,
  size = "small",
  height = "35px",
  width = "330px",
  placeholder = "Choose",
  className = "",
  otherProps,
}) => {
  const classes = useStyles();

  return (
    <FormControl fullWidth>
      {callback !== undefined && (
        <Select
          IconComponent={() => (
            <AiOutlineCaretDown
              style={{ color: mode === "light" ? "black" : "#696969", marginRight: 8, paddingRight: 2 }}
            />
          )}
          size={size}
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={value === undefined ? "" : value}
          name={name === undefined ? "" : name}
          onChange={callback}
          onBlur={formik === undefined ? () => {} : formik.onBlur}
          sx={{
            width: { width },
            height: { height },
            color: mode === "light" ? "black" : "white",
            ...font,
            ...otherProps,
            border: mode === "light" ? "" : "1px solid",
            borderColor: mode === "light" ? "" : darkTheme.border,
          }}
          className={
            `${formik.errors[name] && formik.touched[name]
              ? classes.root
              : className} dark:bg-138 dark:text-white`
          }
        >
          <option value={"none"} className="px-4 py-1 text-[12px] text-opacity-50 dark:bg-138 dark:text-white cursor-not-allowed "> {placeholder}</option>

          {list !== undefined &&
            list.map((val) => (
              <MenuItem className="dark:bg-138 dark:text-white dark:hover:bg-A1A dark:hover:text-green-600" sx={{ ...font, ...otherProps }} value={val.id}>
                {val.name}
              </MenuItem>
            ))}
        </Select>
      )}
      {callback === undefined && (
        <Select
          IconComponent={() => (
            <AiOutlineCaretDown
              style={{ color: mode === "light" ? "black" : "#696969", marginRight: 8, paddingRight: 2  }}
            />
          )}
          size={size}
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={value === undefined ? "" : value}
          name={name === undefined ? "" : name}
          onChange={formik === undefined ? () => {} : formik.handleChange}
          onBlur={formik === undefined ? () => {} : formik.onBlur}
          sx={{
            width: { width },
            height: { height },
            padding: 0,
            color: mode === "light" ? "black" : "white",
            ...font,
            ...otherProps,
            border: mode === "light" ? "" : "1px solid",
            borderColor: mode === "light" ? "" : darkTheme.border,
          }}
          className={
            formik.errors[name] && formik.touched[name]
              ? classes.root
              : className
          }
        >
          
          <option value={"none"} className="px-4 py-1 text-[12px] text-opacity-50 dark:bg-138 dark:text-white cursor-not-allowed "> {placeholder}</option>
          {list !== undefined &&
            list.map((val) => (
              <MenuItem className="dark:bg-138 dark:text-white dark:hover:bg-A1A dark:hover:text-green-600" sx={{ ...font, ...otherProps }} value={val.id}>
                {val.name}
              </MenuItem>
              
            ))}
        </Select>
      )}
      {formik.errors[name] && formik.touched[name] && (
        <Typography sx={{ fontSize: "10px", ...font, color: "red", p: 0 }}>
          {formik.errors[name]}
        </Typography>
      )}
    </FormControl>
  );
};

export const FieldSelectWithOutFormik = ({
  list,
  value,
  disable = false,
  fontSize = "18px",
  callback = () => {},
  size = "small",
  height = "35px",
  width = "330px",
  placeholder = "Choose",
}) => {
  return (
    <FormControl fullWidth>
      {callback !== undefined && (
        <Select
          IconComponent={AiOutlineCaretDown}
          disabled={disable}
          size={size}
          value={value}
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          sx={{ width, height: height, fontSize }}
          onChange={callback}
        >
          <MenuItem sx={{ fontSize: "14px" }} value={"none"} disabled>
            {placeholder}
          </MenuItem>

          {list !== undefined &&
            list.map((val) => (
              <MenuItem
                sx={{ fontSize: "14px" }}
                className="flex items-center gap-1"
                value={val.id}
              >
                {val?.hexcode && (
                  <div
                    className="w-[10px] h-[10px] rounded-full"
                    style={{ backgroundColor: val?.hexcode }}
                  />
                )}{" "}
                <span> {val.name}</span>
              </MenuItem>
            ))}
        </Select>
      )}
    </FormControl>
  );
};

export const FillButton = ({
  text,
  sx,
  callBack,
  disable,
  height,
  width,
  textSize = "14px",
  bg = "#00AD57",
}) => {
  return (
    <Button
      sx={{
        textTransform: "none",
        backgroundColor: bg,
        color: "white",
        height: height !== undefined ? height : "36px",
        width: width !== undefined ? width : "93",
        ...font,
        ...sx,
        ":hover": { backgroundColor: "rgba(0,0,0,0.5)" },
      }}
      variant="contained"
      disabled={disable}
      onClick={callBack}
      className="dark:bg-white dark:text-black"
    >
      {" "}
      <Typography sx={{ ...font, fontSize: textSize }}>{text}</Typography>
    </Button>
  );
};

export const TextAndField = ({ children, space = 1, width = "100%" }) => {
  return (
    <Stack direction="column" gap={space} sx={{ width }}>
      {children[0]}
      {children[1]}
      {children[2] && children[2]}
    </Stack>
  );
};

export const OutlinedButton = ({
  text,
  callBack,
  disable,
  height,
  width,
  textSize = "14px",
  bg = "",
  themeColor = "#242424",
  className = "",
}) => {
  return (
    <Button
      sx={{
        textTransform: "none",
        color: mode === "light" ? themeColor : "white",
        borderColor: mode === "light" ? themeColor : "white",
        height: height !== undefined ? height : "36px",
        width: width !== undefined ? width : "93",
        backgroundColor: mode === "light" ? bg : darkTheme.bg,
        ":hover": { borderColor: "rgba(0,0,0,0.2)" },
      }}
      className={`${className}`}
      variant="outlined"
      disabled={disable}
      onClick={callBack}
    >
      <Typography sx={{ ...font, fontSize: textSize }}>{text}</Typography>
    </Button>
  );
};

export const CustomIconButton = ({
  Icon,
  callBack = () => {},
  disable = false,
  iconStyle = "",
}) => {
  return (
    <IconButton onClick={callBack} disabled={disable}>
      <Icon className={`text-[18px] dark:text-white ${iconStyle}`} />
    </IconButton>
  );
};

export const SimpleSelect = ({
  height = "25px",
  value = "none",
  marginTop = "15px",
  textColor = "#b4b4b4",
  handleChange,
  textSize = "14px",
  list = [{ id: 1, name: "select" }],
  placeholder = "choose",
}) => {
  return (
    <FormControl>
      <Select
        sx={{
          height: height,
          color: textColor,
          fontSize: textSize,
          marginTop: marginTop,
        }}
        IconComponent={AiOutlineCaretDown}
        className="max-h-[200px] overflow-y-scroll"
        size="small"
        labelId="demo-simple-select-label"
        id="demo-simple-select"
        value={value}
        onChange={handleChange}
      >
        <MenuItem value={"none"} disabled>
          <p>{placeholder}</p>
        </MenuItem>
        {list.map((val) => {
          return (
            <MenuItem value={val.id}>
              <div className="flex items-center gap-2">
                {val?.hexcode && (
                  <div
                    className="w-[10px] h-[10px] rounded-full"
                    style={{ backgroundColor: val?.hexcode }}
                  />
                )}
                <p>{val.name}</p>
              </div>
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
};

export const Input = ({ value, onChange, disable }) => {
  return (
    <input
      disabled={disable}
      className=" focus:outline-none border-1 text-[12px] rounded-md dark:bg-1F1 dark:border-333 border-gray-400 hover:dark:border-969 p-2 hover:border-black"
      type="text"
      value={value}
      onChange={onChange}
    />
  );
};

export const TextArea = ({ value, onChange, rows = 4, disable }) => {
  return (
    <textarea
      disabled={disable}
      className=" focus:outline-none border-1 text-[12px] rounded-md dark:bg-1F1 dark:border-333 border-gray-400 hover:dark:border-969 p-2 hover:border-black"
      type="text"
      value={value}
      onChange={onChange}
      rows={rows}
    />
  );
};
