import React, { useEffect, useState } from 'react';
import AppLayout from '../../Widgets/AppLayout';
import { useNavigate } from 'react-router';
import { BsPlus, BsSearch } from 'react-icons/bs';
import { CiLocationOn } from 'react-icons/ci';

import AlertDialog from '../../Widgets/SuccessDialog';
import PlaceHolderOne from '../../Widgets/placeholders/PlaceHolderOne';
import branchesPlaceholderImg from '../../../Assets/placholders/branchesPlaceholder.png'
import MapIcon from '../../../Assets//map_icon.svg'
import ScanCodeIcon from '../../../Assets//scan-barcode.svg'
import FlagIcon from '../../../Assets//flag.svg'
import DeleteIcon from '../../../Assets//trash.svg'
import Tour from 'reactour'

import { FillButton } from '../../Widgets/FormUtils/FormUtils';
import { Loader } from '../../Widgets/Loader';


import Tooltip from '@mui/material/Tooltip';
import CreateBranchModal from './Tabs/Branches/Modal/CreateBranchModal';
import ViewQRCode from './BranchTab/Modal/QRModal';
import GoogleMapView from './BranchTab/Modal/GoogleMapModal';
import { useDispatch, useSelector } from 'react-redux';
import { getBranchesAsync } from '../../../Store/Branches';
import { branchCustomizeHelper, branchTourGuide } from './branchLocationTour';
import { BranchTourModal } from './BranchTour';

export function BranchLocation() {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    let user = localStorage.getItem("user")
    user = user && JSON.parse(user)

    const handleDirectSendBulkAccountCreation = () => {
        navigate("/branches/bulk-account")
    }

    const [openModal, setModalView] = useState(false)
    const [openMapModal, setMapModalView] = useState(false)
    const [isTourOpen, setOpenTour] = React.useState(false)
    const [isModalOpen, setModalOpen] = React.useState(false)

    const [searchBranchName, setSearchBranchName] = useState('');
    const [createBranch, setCreateBranch] = useState(false);
    const [selectedRow, selectBranch] = useState({});
    const [selectGeodata, setGeoData] = useState({});
    const branches = useSelector(state => state.branches)?.branches?.data || []
    const isloading = useSelector(state => state.branches)?.loading

    const handleOpen = () => {
        setCreateBranch(true);
    };
    const handleClose = () => {
        setCreateBranch(false);
    };

    const callBack = () => {
        dispatch(getBranchesAsync()).then((res) => {
        });
    };

    useEffect(() => {
        
        callBack();
        if(user?.new_user_location === 0){
          setModalOpen(true);
        }
    }, []);

    // handling clicking on a branch
    const handleClickBranch = (value) => {
        navigate(`/branches/${value.id}`, {
          state: {
            branchName: value.name
          }
        })
    };

    const handleBranchSearch = (branchName) => {
        const filtereddata = branches?.filter((branch) => branch?.name?.toLowerCase().includes(branchName?.toLowerCase()))
        return filtereddata;
    }

    const handleSearchAction = (e) => {
        setSearchBranchName(e.target.value);
    }

    const handleOpenModal = () => {
        setModalView(true)
    }

    const handleCloseModal = () => {
        setModalView(false)
    }

    const handleOpenMapModal = () => {
        setMapModalView(true)
    }

    const handleCloseMapModal = () => {
        setMapModalView(false)
    }

    const handleCloseTour = () => {
      setOpenTour(false)
      setModalOpen(false)
    }

    const handleOpenTourModal = () =>{
      setModalOpen(false)
      setOpenTour(true)
    }


    return (
        <section className='homepage'>
            <AppLayout
                custom="company"
                subCustom="branch"
                title="Manage Location information"
                customTitle=" "
                subtitle="Manage company’s location and create new location."
                RightComponent={<div className='flex items-center gap-4'>
                    <button data-tour="step1" onClick={handleOpen} className="p-3 rounded bg-5EB text-black flex items-center "> <BsPlus size={23} /> <span className="w-max">Create new Location</span></button>
                    <button data-tour="step2" onClick={handleDirectSendBulkAccountCreation} className="p-3 rounded text-black border-2 flex items-center"> <CiLocationOn size={23} /> <span className="w-max">Create a bulk location</span></button>
                </div>}
            >
                 <section className="branches_ mb-40">
        <AlertDialog />

        <Tour 
        isOpen={isTourOpen}
        steps={branchTourGuide}
        onRequestClose={handleCloseTour}
        disableDotsNavigation
        closeWithMask={false}
        showNumber={false}
        startAt={0}
        showButtons={false}
        CustomHelper={({...props}) => <div>{branchCustomizeHelper[props.current]({...props, navigate,handleCloseTour})}  </div>}
        />

        <div className="header w-full" style={{ marginTop: '0px' }}>
              <div data-tour="step3" className="search-box !justify-start items-center dark:bg-828 !w-full !rounded-3xl !ml-0">
                <div>
                  <BsSearch className="dark:text-white" />
                </div>
                <input className='dark:text-white' onChange={handleSearchAction} type="text" placeholder="I’m searching for..." />
              </div>
              {/* <BranchButton handleOpen={handleOpen}  /> */}
            </div>


        {!isloading ? (<>
          {handleBranchSearch(searchBranchName)?.length > 0 && <section>
            <div className="branch-table" data-tour="step2">
              <table className='text-left' cellPadding={0} cellSpacing={0}>
                <thead className='dark:bg-444 !bg-BF2'>
                  <tr className="dark:hover:bg-transparent !bg-BF2">
                    <th className='dark:text-white border !bg-BF2 border-6E6 dark:border-333'>Location name</th>
                    <th className='dark:text-white border !bg-BF2 border-6E6 dark:border-333'>Location address</th>
                    <th className='dark:text-white border !bg-BF2 border-6E6 dark:border-333 '>Number of team members</th>
                    <th className='dark:text-white border !bg-BF2 border-6E6 dark:border-333 flex justify-center border-b-0 border-l-0 w-[230px]'><p>Members</p></th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {/* onClick={() => handleClickBranch(val)} */}
                  {handleBranchSearch(searchBranchName)?.map((val, index) => (
                    <tr key={index} className=' font-bold dark:hover:bg-transparent bg-AFA dark:bg-828'>
                      <td className='dark:text-white dark:border-333 border border-6E6'>{val.name}</td>
                      <td className='dark:text-white dark:border-333 border border-6E6'>{val.address}</td>
                      <td className='dark:text-white dark:border-333 border border-6E6 text-center'>{val.memberCount}</td>
                      <td className='dark:text-white dark:border-333 border border-6E6 text-center'><p onClick={() => handleClickBranch(val)} className="cursor-pointer font-medium text-D57 underline">See all members</p></td>
                      <td className='dark:border-333 border border-6E6 '>
                        <div className='flex items-center gap-3'>
                          {val["longitude"] !== null && val["latitude"] !== null && (

                            <Tooltip title="View on map" placement='top-start' >
                              <div>
                                <img src={MapIcon} alt="logo" className='cursor-pointer dark:text-white' onClick={() => {
                                setGeoData(val);
                                handleOpenMapModal();

                                }}/>
                              </div>
                            </Tooltip>


                          
                          )}

                          <Tooltip title="Scan Qrcode" placement='top-start'>
                            <div>
                              <img src={ScanCodeIcon} alt="scan_code" className='cursor-pointer dark:text-white' onClick={() => {
                                handleOpenModal();
                                selectBranch(val)
                              }} />

                            </div>
                          </Tooltip>

                          {/* <Tooltip title="View members" placement='top-start'>
                            <div>
                              <img src={ViewIcon} alt="member" className="cursor-pointer dark:text-white"  />
                            </div>
                          </Tooltip> */}

                          <Tooltip title="Benchmark" placement='top-start'>
                            <div>
                              <img src={FlagIcon} alt="flag" className="cursor-pointer dark:text-white" onClick={() => navigate(`/branches/bench-marks/${val.id}`)} />
                            </div>
                          </Tooltip>

                          <Tooltip title="Delete" placement='top-start'>
                            <div>
                              <img src={DeleteIcon} alt="delete" className="cursor-pointer text-red-700 dark:text-white" />
                            </div>
                          </Tooltip>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </section>}
          {handleBranchSearch(searchBranchName)?.length < 1 &&
            <div className='flex flex-col items-center justify-center gap-10'>
              <PlaceHolderOne pic={branchesPlaceholderImg} text='No locations created yet' />
              <FillButton text='Create location' width={'300px'} height={'50px'} callBack={handleOpen} />
            </div>
          }
        </>) : (
          <Loader />
        )}

        {
          openModal && <ViewQRCode data={selectedRow} open={openModal} handleClose={handleCloseModal} />
        }

        {
          openMapModal && <GoogleMapView data={selectGeodata} open={openMapModal} handleClose={handleCloseMapModal}  />
        }


        {createBranch && <CreateBranchModal
          open={createBranch}
          handleClose={handleClose}
          callback={callBack}
        />}

{isModalOpen && (<BranchTourModal open={isModalOpen} handleClose={handleCloseTour}  handleTour={handleOpenTourModal} />)}
      </section>
            </AppLayout>

        </section>
    )
}