import React, { useEffect, useState } from 'react';
import FormModal from '../../../../../Widgets/Animation/FormModal';
import Select from '@mui/material/Select';
import Chip from '@mui/material/Chip';
import MenuItem from '@mui/material/MenuItem';
import Box from '@mui/material/Box';
import OutlinedInput from '@mui/material/OutlinedInput';
import Checkbox from '@mui/material/Checkbox';
import ListItemText from '@mui/material/ListItemText';
import { useSelector } from 'react-redux';
import { Tooltip, Stack } from '@mui/material';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '400px',
  maxHeight: '100vh',
  bgcolor: 'background.paper',
  // border: '2px solid #000',
  boxShadow: 24,
  p: 4,
  borderRadius: '5px',
  overflowY:'auto',
  overflowX:'hidden'
};

function EditSchedule({ open, handleClose, selectedTemplate = {}, schedules = {}, handleSubmitUser }) {
  const [personName, setPersonName] = useState([]);

  useEffect(() => {
    if (schedules?.schedules?.length > 0) {
      const users = schedules?.schedules?.map((x) => x?.userId);
      setPersonName(users);
    }
  }, [schedules?.schedules, schedules?.schedules?.length, open]);

  const store = useSelector((state) => state.branches)?.teamMembers?.filter((mem) => mem?.jobRole.id?.includes(selectedTemplate?.jobId || '') || mem?.secondaryJobroles?.some((x) => x?.jobroleId.includes(selectedTemplate?.jobId || ''))).map(
    (team) => {
      return {
        id: team?.user?.id,
        jobRoleId: team.jobRole.id,
        title: team?.jobRole?.title,
        hexcode:
          team?.jobRole?.hexcode !== 'NULL' &&
          team?.jobRole?.hexcode !== undefined
            ? team?.jobRole?.id
            : '#ffffff',
        name: `${team?.user?.firstName} ${team?.user?.lastName}`,
        gender: team?.user?.gender,
        skillSets: team?.userSkillSets,
      };
    }
  );

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setPersonName(
      // On autofill we get a stringified value.
      typeof value === 'string' ? value.split(',') : value
    );
  };

  return (
    <FormModal open={open} handleClose={handleClose}>
       <Stack direction="column" alignItems="center" sx={style} gap={3}>

        <div className="flex items-center justify-center max-h-[400px] h-screen p-10">
          <div className="bg-white dark:bg-138 dark:text-white py-5 w-[470px] pt-[97px] px-[60px]">
            <p className="w-full text-center font-semibold text-lg mb-[58px]">
              Edit Shift
            </p>

            <p className="font-medium text-xs">Location</p>
            <div className=" bg-5F5 dark:bg-292 border-grey-500 h-[50px] px-[10px] flex items-center rounded-lg">
              <p className="text-base text-color-424 dark:text-white">
                {schedules?.branch?.branchName}
              </p>
            </div>
            {/* <p className='font-medium text-xs mt-6'>Job role</p>
            <div className=' bg-5F5 border-grey-500 h-[50px] px-[10px] flex items-center rounded-lg'>
              <p className='text-base text-color-424'>Software</p>
            </div> */}

            <p className="font-medium text-xs mt-6">Assigned</p>
            <div className="!max-h-[100px] overflow-y-scroll">

            <Select
              labelId="demo-multiple-chip-label"
              id="demo-multiple-chip"
              className="w-full bg-white"
              multiple
              displayEmpty
              value={personName}
              onChange={store?.length === 0 ? null : handleChange}
              input={<OutlinedInput id="select-multiple-chip" label="Chip" />}
              renderValue={(selected) => (
                <Box className='dark:bg-A1' sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5}}>
                  {selected.map((value) => {
                    const assignBg = store?.filter((x) => x.id === value)[0]
                      ?.hexcode;
                    return (
                      <Tooltip
                        title={
                          <BasicTooltip
                            data={store.filter((team) => team.id === value)[0]}
                          />
                        }
                      >
                        <Chip
                          style={{
                            borderRadius: '4px',
                            backgroundColor: assignBg,
                          }}
                          key={value}
                          label={
                            store.filter((team) => team.id === value)[0].name
                          }
                        />
                      </Tooltip>
                    );
                  })}
                </Box>
              )}
            >
              {store?.map((item) => (
                <MenuItem key={item.id} value={item?.id}>
                  <Checkbox checked={personName.indexOf(item?.id) > -1} />
                  <Tooltip title={<BasicTooltip data={item} />}>
                    <ListItemText primary={item?.name} />
                  </Tooltip>
                </MenuItem>
              ))}
              {store?.length === 0 && (
                <MenuItem>No team member with the rules selected</MenuItem>
              )}
            </Select>
            </div>

            <div className="flex items-center justify-center gap-3 my-[100px]">
              {/* <button onClick={() => {
                handleClose();
                setPersonName([])
                }} className='h-[40px] rounded border w-full font-semibold'>Cancel</button> */}
              <button
                onClick={() => {
                  handleSubmitUser(personName);
                  setPersonName([]);
                }}
                className="h-[40px] rounded border w-full bg-424 font-semibold text-white max-w-[200px]"
              >
                Update
              </button>
            </div>
          </div>
        </div>
       </Stack>
    </FormModal>
  );
}

export default EditSchedule;

const BasicTooltip = (data) => {
  return (
    <div className="flex flex-col gap-1 text-[10px]">
      <p>Name: {data?.data?.name}</p>
      <div className="flex gap-1 items-center">
        <p> job Role: {data?.data?.title}</p>
        <p
          className="w-2 h-2 rounded-full"
          style={{ background: data?.data?.hexcode }}
        />
      </div>
    </div>
  );
};
