import React, { useEffect } from 'react';
import { SideBar } from '../../Widgets/SideBar';
import { App as SendBirdKit } from 'sendbird-uikit'
import "sendbird-uikit/dist/index.css";
import SendBird from 'sendbird'; // If required by the library


import { Branch } from '../../../Network/ServiceClass/Branch'
import { NewSideBar } from '../../Widgets/NewSideBar';
const mode = localStorage.getItem("color-theme") ?? 'light'

const branch = new Branch()

class CustomUserPaginatedQuery {
  constructor() {

    // Required public property to determine if more data is available.
    this.hasNext = false;
  }

  myAsyncCallToGenerateMembers = async () => {
    const result = await branch.fetchComapnyMember()
    return result.data.map(val => ({
      userId: val.user.id,
      nickname: `${val.user.firstName} ${val.user.lastName}`,
      profileUrl: val?.user?.picture?.url ?? ''
    }))

  }

  // Required public property.
  async next(callback) {
    // Make async call and get list of users

    const users = await this.myAsyncCallToGenerateMembers();
    // Set this.hasNext
    callback(users);
  }
}

const CustomUserPaginatedQueryFactory = () => new CustomUserPaginatedQuery();


function Chats() {
  const user = JSON.parse(localStorage.getItem("user"))
  const myColorSet = {
    '--sendbird-light-primary-300': '#00AD57',

    '--sendbird-dark-primary-300': '#333333',
    '--sendbird-dark-primary-400': '#333333',
    '--sendbird-dark-secondary-100': '#333333',
    '--sendbird-dark-primary-200': "#00AD57"

  };


  const userSets = [

    [
      { id: '8697e497-5327-4f3c-9094-66e561459168', name: 'User 1', firstName: 'user1', lastName: 'user1' },
      { id: '4b6212a5-4c6e-48bf-ab24-e628a3007461', name: 'User 2', firstName: 'user2', lastName: 'user2' },
    ],
    [
      { id: '0e78eec5-24ee-4590-b6fe-8cc6737bc588', name: 'User 4', firstName: 'user4', lastName: 'user4' },
      { id: 'e96ad6ad-c30e-4559-9446-c64c572d54bf', name: 'User 5', firstName: 'user5', lastName: 'user5' },
    ],
  ];

  const createChannelsForUserSets = async (sets) => {
    const sb = SendBird.getInstance();

    for (const userSet of sets) {
      const userIds = userSet.map(user => user.id);
      const channelName = `Channel for ${userSet.map(user => user.name).join(', ')}`;

      sb.GroupChannel.createChannelWithUserIds(userIds, true, channelName, null, null, null, (channel, error) => {
        if (error) {
          console.error('Error creating channel:', error);
        } else {
          console.log('Channel created');
        }
      });
    }
  };
  useEffect(() => {

    const connectToSendBird = async () => {
      try {
        await SendBird.getInstance().connect(user?.id);  // Ensure you are using getInstance()
        console.log('SendBird connected:', SendBird.getInstance().currentUser);
        // Perform other SendBird operations here
        createChannelsForUserSets(userSets);
      } catch (error) {
        console.error('SendBird connection error:', error);
      }
    };

    connectToSendBird();
  }, [userSets]);




  return (
    <div>
      <NewSideBar custom="messages" subCustom="messages">
        <div className='h-[85vh] dark:bg-138'>

          <SendBirdKit
            appId="C4B25229-A6F4-46A3-884C-D35FBC90A4A7"
            userId={user?.id}
            showSearchIcon
            userListQuery={CustomUserPaginatedQueryFactory}
            nickname={`${user?.firstName} ${user?.lastName}`}
            profileUrl={user?.picture?.url}
            colorSet={myColorSet}
            theme={mode}
          />

        </div>
      </NewSideBar>
    </div>
  )
}




export default Chats;


