import React from 'react'
import { MdArrowForwardIos } from "react-icons/md";
import { Link } from 'react-router-dom';

export function DashboardCard({
    title,
    desc,
    footTitle = "Learn More",
    directLink,
    showbtn = true,
    btnTitle="",
    onClick,
    darkMode=true,
    ...props
}){
    return (
        <div className={`shadow-dashboard dark:shadow-transparent rounded-xl h-[344px] flex flex-col ${darkMode ? "dark:bg-C21" : "dark:bg-white"}`}>
            <div className='w-full h-[122px] flex justify-center items-center'>
                <props.asset />
            </div>
            <div className='p-5 flex flex-col justify-between flex-1'>
                <div>
                <p className={`font-semibold text-2xl ${darkMode ? "dark:text-white": ""} `}>{title}</p>
                <p className={`font-jakarta font-normal text-base ${darkMode ? "dark:text-FCF" : ""} text-A6A`}>{desc}</p>
                </div>
              
                <div className='flex items-center justify-between'>
                    <div  className='flex items-center gap-2'>
                        <p className={`text-sm font-normal ${darkMode ? "dark:text-white": ""}`}>{footTitle}</p>
                        <Link to={directLink}>
                            <MdArrowForwardIos className={`'cursor-pointer ${darkMode ? "dark:text-white" : ""}`} />

                        </Link>
                    </div>
                    {showbtn && (<button onClick={onClick} className={`bg-D57 py-2 px-4 rounded  font-bold ${darkMode ? "text-white" : ""}`}>{btnTitle}</button>)}
                </div>
            </div>

        </div>
    )
}