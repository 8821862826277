/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { BsPlus } from 'react-icons/bs';
import { SideBar } from '../../Widgets/SideBar';
import { BranchesHeader } from '../BranchScreen/BranchesHeader/BranchesHeader';
import CustomDropdown from '../../Widgets/CustomDropdown';
import { months } from '../ShiftScreen/Tabs/BranchSchedule/mock';
import moment from 'moment';
import { getMonth } from '../../../utils/util';
import { getDatesArray } from '../../../utils/dateArray';
import { useDispatch, useSelector } from 'react-redux';
import { getBranchesAsync } from '../../../Store/Branches';
import { ReactComponent as SelectedIcon } from '../../../Assets/select-icon.svg';
import { getUnavailableAsync, blockDaysAsync, unBlockDaysAsync,adminUnBlockDaysAsync  } from '../../../Store/Company';
import { toast } from 'react-toastify';
import UnblockModal from './Widgets/UnblockModal/UnblockModal';
import EditAvailability from './Widgets/EditAvailability/EditAvailability';
import { Loader } from '../../Widgets/Loader';
import AppLayout from '../../Widgets/AppLayout';
import TutorialPanel from '../../Widgets/Tutorial/TutorialPanel';
import { unavailableDaysTutorial } from '../mock';
import { CustomTourModal } from '../../Widgets/CustomTourModal/CustomTourModal';

function UnavailableDays() {
  const dispatch = useDispatch();
  const [unBlockDate, setUnblockDate] = useState('')
  const [unavailableDaysData, setUnavailableDays] = useState([])
  const [editMode, setEditMode] = useState(false);
  const [open, setOpen] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const [selectedDays, selectDays] = useState([]);
  const [isTourOpen, setOpenTour] = useState(false)
  const [isTourModalOpen, setModalOpen] = useState(false)

  const user =
    localStorage.getItem('user') && JSON.parse(localStorage.getItem('user'));
  const branches =
    useSelector((state) => state?.branches)?.branches?.data || [];
  
    const members =
    useSelector((state) => state?.company)?.activeCompany?.memberships || [];
    
    const privilege =
    useSelector((state) => state?.company)?.privilege || 3;
  
    const unavailableDays =
    useSelector((state) => state?.company)?.unavailableDays || [];
  
    const memberships =
    useSelector((state) => state?.company)?.memberships || [];
  
    const isLoading =
    useSelector((state) => state?.company)?.loading;
 
    const [yearSelected, setYearSelected] = useState({
    label: moment().format('yyyy'),
    value: moment().format('yyyy'),
  });

  const dateCreated = useSelector(
    (state) => state.company.activeCompany.createdOn
  );

  const [monthSelected, setMonth] = useState({
    label: moment().format('MMMM'),
    value: getMonth[moment().format('MMMM')],
  });

  const [selectedBranch, setBranch] = useState({
    label: 'All locations',
    value: '',
  });

  useEffect(() => {
    dispatch(getBranchesAsync());
    if(user?.new_user_unavailable === 0){
      setModalOpen(true);
    }
  }, []);

  useEffect(() => {
    handleRefreshUnavail()
  }, [editMode, members?.length, open]);

  const handleRefreshUnavail = () => {
    if (!!user.id && members?.length > 0) {
      const [member] = members.filter((x) => x.userId === user.id);
      dispatch(
        getUnavailableAsync({
          memberId: member.id,
          branchId: [1, 2].includes(member.privilege)
            ? undefined
            : member.assignedBranchId,
        })
      );
    }
  }

  const handleOnChange = (setFunction) => (e) => {
    setFunction(e);
  };

  const generateMonthToDisplay = () => {
    const noOfMonth = moment(
      `${yearSelected.value}-${monthSelected.value}`,
      'yyyy-MM'
    )
      .startOf('month')
      .daysInMonth();

    const weekDay = moment(
      `${yearSelected.value}-${monthSelected.value}`,
      'yyyy-MM'
    )
      .startOf('month')
      .utc()
      .day();

    const monthStart = moment(
      `${yearSelected.value}-${monthSelected.value}`,
      'yyyy-MM'
    )
      .utc()
      .subtract(weekDay, 'days');

    const dateArrays = Array.from(Array(noOfMonth + weekDay).keys())?.map((x) =>
      moment(monthStart)
        .add(x + 1, 'days')
        .format('yyyy-MM-DD')
    );

    return dateArrays;
  };

  const getBackgroundColor = (date) => {
    const blockUsers = unavailableDays.filter(
      (unvaila) =>
        moment(unvaila.blocked_days).format('yyyy-MM-DD') ===
        moment(date).format('yyyy-MM-DD')
    );

    if (blockUsers?.length === 1) {
      return 'bg-6E0';
    }

    if (blockUsers?.length === 2) {
      return 'bg-9E0';
    }

    if (blockUsers?.length === 3) {
      return 'bg-1E0';
    }

    if (blockUsers?.length > 3) {
      return 'bg-3D3';
    }

    return '';
  };

  const formatBorder = (date, index) => {
    const day = moment(date).utc().day();
    let style = '';

    if (day !== 0) {
      style = style + 'border-l-0 ';
    }

    if (index > 6) {
      style = style + 'border-t-0 ';
    }

    return style;
  };

  const getUsers = (date) => {
    let blockUsers = unavailableDays.filter(
      (unavail) =>
        moment(unavail.blocked_days).format('yyyy-MM-DD') ===
        moment(date).format('yyyy-MM-DD')
    );
    if (blockUsers?.length > 3) {
      return (
        <p className="text-[9px] px-2">{blockUsers?.length} Staff Members</p>
      );
    }

    if([3].includes(privilege)){
      blockUsers =blockUsers?.map((x) => ({...x, user}))
    }

    return blockUsers?.map((x) => (
      <p className="text-[9px] px-2">
        {x?.user?.firstName} {x?.user?.lastName}
      </p>
    ));
  };

  const handleBlockToggle = () => {
    setEditMode((prev) => !prev);
  };

  const handleOpenModal = () => {
    setOpen(true);
  }

  const handleCloseModal = () => {
    setOpen(false);
  }

  const handleOpenEditModal = () => {
    setOpenEdit(true);
  }

  const handleCloseEditModal = () => {
    setOpenEdit(false);
  }

  const handleUnblockDay = (date) => {
    dispatch(unBlockDaysAsync({date})).then(()=>{
      handleCloseModal();
      toast.success(`${moment(date).format("yyyy-MM-DD")} was successfully unblocked`)
    })
  }

  const handleStaffUnblockDay = (date, companyId, userId) => {
    dispatch(adminUnBlockDaysAsync({date, companyId, userId})).then(()=>{
      handleCloseEditModal();
      handleRefreshUnavail();
      toast.success(`Unblocking was successfully`)
    })
  }

  const checkIfDayBlocked = (date) => {
    const [member] = members.filter((x) => x.userId === user.id);
    let blockUsers = unavailableDays
    ?.filter(
      (unavail) =>
      moment(unavail.blocked_days).format('yyyy-MM-DD') ===
      moment(date).format('yyyy-MM-DD')
      )

      if(![1,2].includes(member.privilege)){
        blockUsers = blockUsers?.filter((x) => x.userId === user.id);
      }

      
      if(blockUsers?.length > 0 ){
        setUnblockDate(date);
        setUnavailableDays(blockUsers);
        [1,2].includes(member.privilege) ? 
        handleOpenEditModal() : handleOpenModal();
      }
      return null
  }

  const handleSubmit = () => {
    const [member] = members.filter((x) => x.userId === user.id);

      dispatch(
        blockDaysAsync({
          teamMembershipId: member.id,
          branchId: member.assignedBranchId || undefined,
          blockedDays: selectedDays,
        })
      ).then((res) => {
        if ([400, 404, 500].includes(res?.payload?.status)) {
          toast.error(res?.payload?.data?.message);
          return null;
        }
        toast.success('Blocking days was successful');
        setEditMode(false)
        selectDays([])
      });
      
  };

  const getBorderColors = (date) => {
    const blockUsers = unavailableDays
      ?.filter(
        (unavail) =>
          moment(unavail.blockedDays).format('yyyy-MM-DD') ===
          moment(date).format('yyyy-MM-DD')
      )
      ?.filter((x) => x.userId === user.id);
    if (blockUsers?.length === 0 && editMode) {
      return 'border-blue-400';
    }

    return 'border-grey-500 dark:border-333';
  };

  const handleSelectDates = (date) => {
    const blockUsers = unavailableDays
      ?.filter(
        (unavail) =>
          moment(unavail.blockedDays).format('yyyy-MM-DD') ===
          moment(date).format('yyyy-MM-DD')
      )
      ?.filter((x) => x.userId === user.id);
    if (blockUsers?.length > 0) {
      return null;
    }
    const formattedDates = moment(date).format('yyyy-MM-DD');
    if (!selectedDays.includes(formattedDates)) {
      selectDays((prev) => [...prev, formattedDates]);
    } else {
      const filteredDays = selectedDays.filter((day) => day !== formattedDates);
      selectDays(filteredDays);
    }
  };

  const handleTour = () => {
    setOpenTour(true)
    setModalOpen(false)
  }
  
  
  const handleCloseTour = () => {
    setOpenTour(false)
    setModalOpen(false)
  }


  return (
    <section>
      <AppLayout
        custom="company"
        subCustom="unavailable-days"
        title="Unavailable Days"
        customTitle=""
        layoutClass="dark:bg-138"
        subtitle="Block out days that you are unavailable to work"
        layoutProps={{
          headerClass: "dark:text-white",
          subHeaderClass: "dark:text-969"
        }}
        RightComponent={<TutorialPanel items={unavailableDaysTutorial} />}
      >
        <section>
         
          <div className="flex justify-between items-center">
            <div className="flex items-center gap-3">
              <CustomDropdown
                data={months}
                className="w-28 rounded py-[14px] px-5"
                optionClass="z-50"
                value={monthSelected}
                dropdownTitleClass="text-A5A"
                onChangeOption={handleOnChange(setMonth)}
              />
              <CustomDropdown
                data={getDatesArray(dateCreated, new Date().toString())?.map(
                  (dates) => ({ label: dates, value: dates })
                )}
                className="w-28 rounded py-[14px] px-5"
                value={yearSelected}
                optionClass="z-50"
                dropdownTitleClass="text-A5A"
                onChangeOption={handleOnChange(setYearSelected)}
              />
              {members.filter((x) => x.userId === user.id)?.length > 0 &&
                [1, 2].includes(
                  members.filter((x) => x.userId === user.id)[0]?.privilege
                ) && (
                  <CustomDropdown
                    data={[
                      { name: 'All locations', value: '' },
                      ...branches,
                    ]?.map((branch) => ({
                      label: branch?.name,
                      value: branch.id,
                      hexcode: branch?.hexcode
                    }))}
                    className=" w-36 rounded py-[14px] px-5"
                    value={selectedBranch}
                    dropdownTitleClass="text-A5A"
                    optionClass="z-[1000] shadow-md min-h-[50px] max-h-[200px] overflow-y-scroll"
                    onChangeOption={handleOnChange(setBranch)}
                  />
                )}
            </div>
            <button
              onClick={editMode ? handleSubmit : handleBlockToggle}
              className="flex items-center gap-2 border border-btnBorder rounded py-[10px] px-[20px] dark:border-333 dark:text-white"
            >
              <span>
                <BsPlus size={20} />
              </span>{' '}
              <p>{editMode ? 'Save' : 'Block Days'}</p>
            </button>
          </div>
          </section>
          <section>

          {isLoading && (<div className='flex items-center justify-center h-[300px] w-full'>
          {
              isLoading && <Loader />
            }
          </div>)}
          
          <div className="grid grid-cols-7 my-8 gap-0">
            {!isLoading && ["MON", "TUE", "WED", "THU", "FRI", "SAT", "SUN"].map((x) => <div className="h-[47px] bg-FF9 flex items-center justify-center mb-6"><p className='font-xs font-medium font-jakarta'>{x}</p></div>)}

            {!isLoading && generateMonthToDisplay()?.map((avail, index) => (
              <div
                key={index}
                onClick={!editMode ? () => checkIfDayBlocked(avail) : () => handleSelectDates(avail)}
                className={`flex cursor-pointer flex-col justify-between border h-[154px] ${moment(avail).month()+1 < Number(monthSelected.value) ? "bg-past-date text-opacity-40": ""} ${getBorderColors(
                  avail
                )}  p-1 ${formatBorder(avail, index)}`}
              >
                
                <div className="flex items-start gap-2 relative p-2">
                  <p className="font-medium text-[14px] font-jakarta text-777 absolute top-0 left-2  dark:text-white">
                    {moment(avail).format('DD')}
                  </p>
                  {/* <div className="ml-[45px]">
                    <p className="font-medium text-xs dark:text-white">
                      {moment(avail).format('ddd')?.toUpperCase()}
                    </p>
                    <p className="font-medium text-xs  dark:text-white">
                      {moment(avail).format('MMM')}
                    </p>
                  </div> */}
                </div>
                <div
                  className={`h-[40px] w-full flex flex-col relative justify-center ${getBackgroundColor(
                    avail
                  )}`}
                >
                  {getUsers(avail)}
                  {editMode &&
                    selectedDays.includes(
                      moment(avail).format('yyyy-MM-DD')
                    ) && <SelectedIcon className="absolute right-0 bottom-0" />}
                </div>
              </div>
            ))}
          </div>
          <UnblockModal
            handleSubmit={handleUnblockDay}
            date={unBlockDate}
            open={open}
            loading={isLoading}
            handleClose={handleCloseModal}
          />
          <EditAvailability
            handleSubmit={handleStaffUnblockDay}
            date={unBlockDate}
            memberships={memberships}
            open={openEdit}
            data={unavailableDaysData}
            loading={isLoading}
            handleClose={handleCloseEditModal}
          />

{ isTourModalOpen && (<CustomTourModal
      handleClose={handleCloseTour}
      handleTour={handleTour}
      open={isTourModalOpen}
      title='Welcome to Unavailable Days'
      descr='This guide will walk you through creating unavailable'
      />)}
        </section>

      </AppLayout>
     
    </section>
  );
}

export default UnavailableDays;
