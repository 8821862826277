import {ReactComponent as NewSupserLogoIcon} from "../../../../Assets/new_sync_sync_logo.svg";
import {ReactComponent as SelectedOption} from "../../../../Assets/icons/selected_login_icon.svg";
import {ReactComponent as UnSelectedOption} from "../../../../Assets/icons/unselected_login_icon.svg";
import { FaArrowLeftLong } from "react-icons/fa6";
import { FillButton } from "../../../Widgets/FormUtils/FormUtils";
import { useState } from "react";

export function ChooseRole({ setCurrFlow }){
    const [selectedOption, selectOption] = useState(true)


    const handleMemberSelect= () => {
        selectOption(false)
      }
    
      const handleManagerSelect= () => {
        selectOption(true)
      }

    return (
        <div className="mt-20">
            <div className="flex items-center gap-2 mb-10">
            <FaArrowLeftLong className="cursor-pointer" onClick={()=>setCurrFlow(0)} />
            <p>Back</p>
            </div>
        <NewSupserLogoIcon />
        <p className="text-[32px] mt-10 font-semibold">How would you like to use SuprSync?</p>
        <p className='text-sm font-medium text-A6A mb-6'>You can create your company account right away if you’re an employer or refer your employer if you’re an employee.</p>
        <div className="flex flex-col mt-5 gap-10 dark:text-white rounded-input">
        
        <div className='flex items-center flex-col justify-between gap-2'>
          <div onClick={handleManagerSelect} className={`w-full h-[75px] rounded-md border cursor-pointer py-[14px] px-4 flex items-center justify-between gap-3 ${selectedOption ? "bg-AE8 border-539 shadow-active-shadow" : ""}`}>
            <div>
            <p className={`text-base ${selectedOption ? "text-black font-jakarta font-semibold": "text-color-3A3"}`}>Proceed as a Manager</p>
            <p className={`text-sm text-F7F`}>I want to create an account on behalf of my Company</p>

            </div>
            {selectedOption ? <SelectedOption /> : <UnSelectedOption />}
          </div>
          <div onClick={handleMemberSelect} className={`w-full h-[75px] mt-6 rounded-md border py-[14px] px-4 cursor-pointer flex items-center justify-between gap-3 ${!selectedOption ? "bg-AE8 border-539 shadow-active-shadow" : ""}`}>
            <div>
            <p className={`text-base ${!selectedOption ? "text-black font-jakarta font-semibold": "text-color-3A3"}`}>Proceed as a Member</p>
            <p className={`text-sm text-F7F`}>I want to refer my Manger to use SuprSync</p>
            </div>
            {!selectedOption ? <SelectedOption /> : <UnSelectedOption />}
          </div>
        </div>
          <div>
            <FillButton
                type="submit"
                text={'Proceed'}
                width={'100%'}
                height={'50px'}
                callBack={() => {
                  setCurrFlow(2)
                }}
                sx={{ borderRadius: 100}}
              />
          </div>
        </div>
        </div>
    )
}