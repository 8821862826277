import {useState, useEffect, useCallback} from 'react'

import SearchInput from '../../../Widgets/SearchInput'
import { Link, useNavigate, useLocation} from 'react-router-dom';
import { useParams } from 'react-router-dom';
import AppLayout from '../../../Widgets/AppLayout';
import { CircularProgress } from '@mui/material';

import GlobalButton from '../../../Widgets/GlobalButton';
import { useSelector, useDispatch } from 'react-redux';
import { getInventoryMeasurementUnitThunk } from '../../../../Store/Inventory';
import { CustomPopper } from '../../../Widgets/CustomPopper';
import { ClickableItem } from '../../../Widgets/ClickableItem';
import { stockUpOrderRequest } from '../../../../Network/ServiceClass/InventoryService';
import { toast } from 'react-toastify';
import dayjs from 'dayjs';
import { statusColor } from '../../../../utils/inventoryStatus';
import capitalizeFirstLetter from '../../../../utils/capitalizeFirstLetter';
import { inventoryPlaceBackOrder, approveOrderRequest } from '../../../../Network/ServiceClass/InventoryService';
import MainTrackingModal from './Tracking/MainTrackingModal';
import { getInventorySingleOrderRequestThunk } from '../../../../Store/Inventory';
import { TableWrapper, TableHeader, Td, Tr } from '../../../Widgets/Table/TableWrapper';

const OrderRequestItem = () => {

    const location = useLocation()
    const navigate = useNavigate()
    const { itemId } = useParams();
    const dispatch = useDispatch()
 
    const [data, setData] = useState(null)
    const [isLoading, setIsLoading] = useState(true)
    const [isStocking, setIsStocking] = useState(false)

    const [searchValue, setSearchValue] = useState('')
    const measurementUnitsList = useSelector(state => state.inventory)?.measurementUnits

    const [toggleMenu, setToggleMenu] = useState(null)
    const [menuIndex, setMenuIndex] = useState(null)
    const [isLoadingAction, setIsLoadingAction] = useState(false)
    const [isLoadingApprove, setIsLoadingApprove] = useState(false)

    const [openTrackingModal, setOpenTrackingModal] = useState(false)
    const [trackingData, setTrackingData] = useState(null)


   


    useEffect(()=> {
        dispatch(getInventoryMeasurementUnitThunk())
        dispatch(getInventorySingleOrderRequestThunk(itemId))
        .then(val => {
            setIsLoading(false)
            setData(val.payload?.data.map(x => ({...x, received:0, expirationDate: dayjs(x?.expirationDate).format('YYYY-MM-DD')})))
        })

       
    },[])

    const reLoad = () => {
        dispatch(getInventorySingleOrderRequestThunk(itemId))
        .then(val => {
            setData(val.payload?.data.map(x => ({...x, received:0, expirationDate: dayjs().format('YYYY-MM-DD')})))
        })
       
    }

    const placeInBackOrder = (data, status) => {
        setIsLoadingAction(true)
        inventoryPlaceBackOrder({
            orderId: data?.id,
            referenceNumber: data?.referenceNumber,
            status
        })
        .then(val => {
            if(val?.status === 200) {
                toast.success('Success') 
                setToggleMenu(null)
                reLoad()
            }
            else toast.error(val?.data?.message) 
            setIsLoadingAction(false)
        }).catch(err => setIsLoadingAction(false))

    }

    const handleSearch = useCallback(() => {
        let datas = data
        if(searchValue.length !== 0){
          const pattern = new RegExp(`\D*${searchValue}\D*`,'i')
                datas = data.filter((val) => (
                    val.status?.toLowerCase().match(pattern) ||
                    val.packageCost?.toString().match(pattern) ))
        }
        return datas
    
    },[searchValue, data])

    const handleStockItems = () => {
        setIsStocking(true)
        const datas = {
            branchId: data[0]?.branch?.id,
            orderIdentifier: itemId,
            itemsReceived: data?.map(item => ({
                referenceNumber: item?.referenceNumber,
                numberReceived: item?.received,
                measurementUnitId: item?.measurementUnitId,
                lotNumber: item?.lotNumber,
                expirationDate: item?.expirationDate
            }))
            
        }
        stockUpOrderRequest(datas)
        .then(val => {
            if([200,201].includes(val?.status)) {
                toast.success('Items Stocked')
                navigate('/stock-order-request')
            }else {
                toast.error(val?.data?.message)
            }

            setIsStocking(false)
        })
    }

    const handleApprove = () => {
        setIsLoadingApprove(true)
        approveOrderRequest({id: itemId, status:'ordered'})
        .then(val => {
          if(val?.status === 200){
            toast.success('Successfull')
            reLoad()

          } 
          else toast.error(val?.data?.message)
        setIsLoadingApprove(false)
        })
    
        
    
    }
    


  return (
    <section>
        <AppLayout
           custom="inventory"
           subCustom="order-request"
           title={location?.state?.title ?? 'Order Request'}
           customTitle="Inventory"
           subtitle=""
        >
            
            <div className="flex h-[30px] items-center gap-3">
                {/* <IoIosArrowBack onClick={()=>navigate(-1)} size={25} className="cursor-pointer" /> */}
                <p className="font-medium text-base text-5F3 my-6">
                    <span className='cursor-pointer'
                    onClick={()=> navigate(-1)}
                    >
                        {location?.state?.title?.toUpperCase()} /
                    </span>
                    <span className='font-bold'> {itemId}</span>
                </p>
            </div>

            <div className='flex flex-col gap-2 mt-4'>
                <SearchInput 
                    className={'w-[300px] border-gray-200 h-10'}
                    inputStyle={'placeholder:text-gray-400'}
                    iconStyle='text-gray-400'
                    placeholder='Search for items...' 
                    onChange={(e)=>{setSearchValue(e.target.value)}}
                />

                <div className='flex justify-between'>
                    {!isLoading ? <p className='text-[18px] dark:text-white'>Location - {data[0]?.branch?.name}</p>: <div></div> }

                    <div className='flex gap-3'>
                        {!isLoadingApprove ? <GlobalButton 
                            disabled={isLoadingApprove}
                            onClick = {handleApprove}
                            title="Approve Order" 
                            className="rounded-md text-white font-semibold text-[16px] "/>

                            :
                            <div className='mr-5 text-center w-[130px]'><CircularProgress/></div>
                        
                        }

                        {!isStocking ? <GlobalButton 
                            disabled={isLoading}
                            onClick = {handleStockItems}
                            title="Stock Items" 
                            className="rounded-md text-white font-semibold text-[16px] "/>

                            :
                            <div className='mr-5 text-center w-[130px]'><CircularProgress/></div>
                        
                        }

                    </div>
                </div>



            </div>

            <TableWrapper className='mt-6 !max-h-[70vh] !mb-12 w-full'>
                <table className='w-full'>
                    <TableHeader className='z-[1000] w-full'>
                        <Td className='py-4 w-[11%]'>Reference Number</Td>
                        <Td className='w-[14%]'>Name</Td>
                        <Td className='w-[14%] '>Manufacturer</Td>
                        <Td className='w-[14%]'>Expiration Date</Td>
                        <Td className='w-[11%]'>Lot Number</Td>
                        <Td className='w-[8%]'>#Expected</Td>
                        <Td className='w-[11%]'>#Received</Td>
                        <Td className='w-[8%]'>Unit</Td>
                        <Td className='w-[8%] !border-none'>Status</Td>

                    </TableHeader>

                    {!isLoading ? <tbody>
                        {handleSearch()?.length !== 0 ?
                            handleSearch()?.map((val, index) => (
                                <Tr key={index} className='hover:dark:bg-292 dark:text-white font-bold'>
                                        <Td className='py-4'>
                                        {val?.referenceNumber}
                                        </Td>

                                        <Td >{val?.reference?.name}</Td>
                                        <Td >{val?.reference?.manAndSupp[0]?.manufacturer?.name}</Td>

                                        <Td >
                                            {'l' === '' ?<div>d</div>
                                            :
                                            <input
                                                onChange={(e) => {
                                                    const v =  data.map(x => {
                                                        if(x?.id === val?.id){
                                                            x.expirationDate = e.target.value
                                                        }
                                                        return x
                                                    })

                                                    setData(v)
                                                }}
                                                className='focus:outline-none border-1 rounded-md px-1 py-2 hover:border-[black] hover:dark:border-969 dark:border-333 dark:bg-138 dark:text-white cursor-pointer'
                                                type="date" value={val?.expirationDate}
                                            />}
                                        </Td>
                                        <Td >
                                            <input 
                                                onChange={(e)=>{
                                                    const v =  data.map(x => {
                                                        if(x?.id === val?.id){
                                                            x.lotNumber = e.target.value
                                                        }
                                                        return x
                                                    })

                                                    setData(v)
                                                }}
                                                className='border-1 py-2 px-1 rounded-md focus:outline-0 hover:border-[black] hover:dark:border-969 dark:border-333 dark:bg-138 dark:text-white w-[150px]'
                                                type="text"  
                                                value={val?.lotNumber}
                                            />
                                        </Td>

                                        <Td >{val?.packagesToOrder}</Td>


                                        <Td >
                                            <div className='flex text-[18px]'>
                                                <p onClick={()=> {
                                                    const v =  data.map(x => {
                                                            if(x?.id === val?.id){
                                                                if ( x.received !== 0) x.received --
                                                            }
                                                            return x
                                                        })

                                                        setData(v)
                                                    }
                                                }

                                                    className='py-[5px] px-3 cursor-pointer rounded-l-md bg-5F3 text-white'>-</p>
                                                <p className='w-[70px] py-[4px] text-center bg-5F5 dark:bg-138 dark:text-white'>{val?.received}</p>
                                                <p 
                                                    onClick={()=> {
                                                    const v =  data.map(x => {
                                                            if(x?.id === val?.id){
                                                                x.received ++
                                                            }
                                                            return x
                                                        })

                                                        setData(v)
                                                    }}
                                                    className='py-[5px] px-3 cursor-pointer rounded-r-md bg-5F3 text-white'>+</p>
                                            </div>
                                        </Td>
                                        
                                        <Td>
                                          
                                            {val?.measurementUnitId 
                                                ? measurementUnitsList.filter(x => x.id === val?.measurementUnitId)[0]?.name 
                                                : 'choose'
                                            }
                                        </Td>

                                        <Td className={'!border-none'}>
                                            <p 
                                                onClick={(e)=> {
                                                    e.stopPropagation()
                                                    !isLoadingAction && setToggleMenu(toggleMenu ? null : e.currentTarget)
                                                    !isLoadingAction && setMenuIndex(val?.id)
                                                }}
                                                className={`rounded-full cursor-pointer px-2 text-center ${statusColor[val?.status]?.bg} ${statusColor[val?.status]?.color}`}>
                                                    {capitalizeFirstLetter(val?.status)}
                                            </p>
                                            {menuIndex === val?.id &&
                                                <CustomPopper 
                                                    openPopper={toggleMenu} 
                                                    handleClose={()=>{
                                                        !isLoadingAction && setToggleMenu(null)
                                                    }} className='z-[1500]' placement='bottom'>
                                                    <div className='h-[100px] min-w-[150px] mt-1 py-3 font-bold bg-white shadow-shift-shadow-small'>

                                                        {!isLoadingAction ? <div className='flex flex-col gap-2 text-[#738583] text-[12px]'>
                                                            
                                                            {val?.status !== 'backorder' ? <ClickableItem 
                                                                handleClick={()=>placeInBackOrder(val, 'backorder')}
                                                                className={'py-1 text-[black]'} 
                                                                text={'Place In Backorder'} 
                                                            /> : 

                                                            <ClickableItem 
                                                                handleClick={()=>placeInBackOrder(val, 'ordered')}
                                                                className={'py-1 text-[black]'} 
                                                                text={'Remove In Backorder'} 
                                                            />
                                                            }

                                                            <ClickableItem 
                                                                className={'py-1 text-[black]'} 
                                                                text={'Track Order'} 
                                                                handleClick={()=> {
                                                                    setOpenTrackingModal(true)
                                                                    setToggleMenu(null)
                                                                    setTrackingData(val)
                                                                }}
                                                            />
                                                        </div>
                                                        :
                                                        <div className="flex h-full items-center justify-center"><CircularProgress/></div>
                                                        }
                                                    
                                                    </div>          
                                                </CustomPopper>
                                            }
                                        </Td>
                                </Tr>

                            ))

                        :<tr>
                            <td colSpan={9} className='text-center pt-5 text-A5A'>Nothing to see here</td>
                        </tr>
                        }
                    </tbody>
                    : <tbody>
                    <tr>
                        <td colSpan={9} className='text-center pt-10 border-1 dark:border-333 border-white'><CircularProgress /></td>
                    </tr>
                </tbody>}
                </table>

            </TableWrapper>
          
        </AppLayout>

        {openTrackingModal && <MainTrackingModal
         openModal={openTrackingModal}
         data={trackingData}
         callBack={reLoad}
         handleCloseModal={()=> {
            setOpenTrackingModal(false)
            setTrackingData(null)
        }}
        />}
       
    </section>
  )
}

export default OrderRequestItem