import {ReactComponent as OnboardImg} from '../../../Assets/third_auth.svg'


export function ThirdOnboarding(){
    return (
        <div className='flex items-center flex-col justify-center'>
            <OnboardImg  />
            <div className='p-10 mt-10 bg-white'>
                <p className="text-[40px] font-semibold">Multiple Tools for Organizations</p>
                <p className="text-2xl text-272">Explore the core functionalities that power productivity, streamline workflow efficiency  and enable collaboration within SuperSync. </p>
                <div className='mt-2 flex items-center gap-2'>
                    <div className='h-2 w-4 bg-7E0 rounded-3xl' />
                    <div className='h-2 w-4 bg-7E0 rounded-3xl' />
                    <div className='w-[39px] h-[8px] bg-D57 rounded-3xl' />
                    <div />
                </div>
            </div>
        </div>
    )
}