import React, { useState } from 'react'
import CustomModal from '../../../Widgets/CustomModal';
import { CiSearch } from 'react-icons/ci';
import moment from 'moment';
import { FillButton } from '../../../Widgets/FormUtils/FormUtils';
import { LiaTimesSolid } from "react-icons/lia";
import ButtonWithIcon from '../../../Widgets/ButtonWithIcon';

export default function CombineScheduleModal({
  openModal = true,
  searchedMember = '',
  searchTeam = () => {},
  handleClose = () => {},
  handleCreateShift,
  teamMembers,
  branchId,
  date,
  job,
  loading,
  userList,
  handleShowUser,
  handleExtractSchedule,
  handleCancelShift
}) {
 
  return (
    <CustomModal
      open={openModal}
      handleClose={handleClose}
      modalClassName="!w-[30%] dark:bg-292"
    >
      <p className="text-xl font-bold mb-3 text-center">Combined Schedule</p>

      {!userList && (<ButtonWithIcon handleClick={handleShowUser} type="text" title="Assign New Staff" />)}

      <div className="flex flex-wrap items-center gap-2 mt-3">
        {
          handleExtractSchedule(moment(date), job.id, branchId)?.map((x) => (<div className="bg-8F8 rounded-md flex gap-3 w-fit items-center p-2">
            <p>{x?.name}</p>
            <LiaTimesSolid onClick={()=>handleCancelShift(x)} className="cursor-pointer" />
          </div>))
        }
      </div>
      {userList && !loading && (<div className="border-1 dark:border-none flex flex-row gap-1 mt-2 items-center sticky top-0 dark:text-white bg-white dark:bg-138 py-[10px] px-[5px] rounded-md">
        <CiSearch />
        <input
          type="text"
          className=" outline-none dark:bg-138"
          value={searchedMember}
          placeholder="search"
          onFocus={(e) => e.stopPropagation()}
          onChange={(e) => searchTeam(e.target.value)}
        />
      </div>)}
      {userList && !loading && (<div className='max-h-52 overflow-y-scroll mb-5 mt-2'>
      {teamMembers
        ?.filter(
          (team) =>
            team?.jobRole?.id === job?.id ||
            team?.secondaryJobroles?.some((x) => x?.jobroleId === job?.id)
        )
        .filter(
          (x) =>
            x?.user?.firstName
              .toLowerCase()
              .includes(searchedMember.toLowerCase()) ||
            x?.user?.lastName
              .toLowerCase()
              .includes(searchedMember.toLowerCase())
        )
        .map((team) => (
          <p
            onClick={() =>
              handleCreateShift(
                { id: branchId },
                moment(date).format('yyyy-MM-DD'),
                team,
                job
              )
            }
            className="py-2 text-sm cursor-pointer hover:text-green-600 dark:hover:bg-color-333 dark:text-white"
          >
            {team?.user?.firstName} {team?.user?.lastName}
          </p>
        ))}
      </div>)}
      <FillButton callBack={handleClose} sx={{ mt: 4 }} disable={loading} width="100%" text={loading ? "Loading" : "Save"} />
    </CustomModal>
  );
}
