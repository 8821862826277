import React,{useState, useCallback, useEffect, useRef} from 'react'
import { IoIosArrowBack } from 'react-icons/io';
import { Storage } from '../../../../Network/StorageClass/StorageClass';
import { confirmOrder } from '../../../../Network/ServiceClass/InventoryService';
import { toast } from 'react-toastify';
import { CircularProgress } from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';
import { getInventoryMeasurementUnitThunk } from '../../../../Store/Inventory';
import { CustomPopper } from '../../../Widgets/CustomPopper';
import { ClickableItem } from '../../../Widgets/ClickableItem';
import {AiOutlineCaretDown} from 'react-icons/ai'
import { TableWrapper, TableHeader, Tr, Td } from '../../../Widgets/Table/TableWrapper';



const ConfirmRequest = ({setPageIndex, data, branchId, reset}) => {
    const storage = new Storage()
    const dispatch = useDispatch()

    const teamMembershipId = storage.getActiveCompany()?.memberships[0]?.id
    const measurementUnitsList = useSelector(state => state.inventory)?.measurementUnits
    const [isLoading, setIsLoading] = useState(false)
    

    const [toggleMenu, setToggleMenu] = useState(null)
    const [menuIndex, setMenuIndex] = useState(null)


    const [items, setItems] = useState(data)

    
   

    const handleConfirmOrder = useCallback(() => {

        if(branchId === undefined){
            toast.error('Go back and select a location')
            return
        }
        
        const data = {
            branchId: branchId.name,
            teamMembershipId,
            orderData: items.map(val => ({
                referenceNum: val.referenceNumber,
                packagesToOrder: val.packagesToOrder,
                measurementUnitId: val.measurementUnitId
            }))

        }
        setIsLoading(true)
        confirmOrder(data)
        .then(res => {
            setIsLoading(false)
            if (res.status === 201) {
                toast.success('Order confirmed')
                setPageIndex(0)
                reset()
            }
            else toast.error('Something went wrong')
        }).catch((err) => console.log(err))
    },[items]) 

    useEffect(()=>{
        dispatch(getInventoryMeasurementUnitThunk())
    },[])

  return (
    <div>
        {/* {openAttachModal && <CustomModal open={openAttachModal} handleClose={handleCloseAttachModal}/>} */}
        <div className='flex justify-between items-center'>
            <div onClick={()=> setPageIndex(0)}
            className='flex gap-2 items-center mt-6 cursor-pointer dark:text-white'>
                <IoIosArrowBack size={20}/>
                <p className='text-[16px] font-semibold'>Confirm order request</p>
            </div>

            {!isLoading ? <button 
                onClick={handleConfirmOrder}
                disabled ={isLoading || items.length === 0}
                className={`self-end rounded-[3px] px-4 py-2 font-semibold text-[white] ${(isLoading || items.length === 0) ? 'bg-gray-300' : 'hover:shadow-small-select bg-btn-color'} `}>
                {isLoading ? 'Confirming ...' : 'Confirm order'} 
            </button>:
            <div className='mr-7'><CircularProgress/></div> 
            
            }

        </div>

        <TableWrapper className='mt-6 !max-h-[70vh] mb-12 '>
            <table className=''>
                <TableHeader>
                    <Td className='py-4'>Reference number #</Td>
                    <Td className=''>Name</Td>
                    <Td className=''>Manufacturer</Td>
                    <Td className=''>N0. Of Suppliers</Td>
                    <Td className=''>Packages to order</Td>
                    <Td className=''>Items/Pkg</Td>
                    <Td className='!border-none'>Unit</Td>
                </TableHeader>

                <tbody>
                    { (items.length !== 0 ) ?
                        items.map((val,index) => (
                            <Tr key={val?.id} className='hover:bg-5F5 hover:dark:bg-292'>
                                <Td className='py-4'>
                                    <p>{val?.referenceNumber}</p>
                                </Td>

                                <Td>{val?.name}</Td>

                                <Td>{val?.manAndSupp.length === 0 ? '' : val?.manAndSupp[0]?.manufacturer?.name}</Td>
                                <Td>{val?.manAndSupp.length}</Td>
                                <Td>
                                    <div className='flex text-[18px]'>
                                        <p onClick={()=> {
                                                const v =  items.map(x => {
                                                    if(x?.id === val?.id){
                                                        if ( x.packagesToOrder !== 0) x.packagesToOrder --
                                                    }
                                                    return x
                                                })

                                                setItems(v)
                                            }}

                                            className='py-[5px] px-3 cursor-pointer rounded-l-md bg-5F3 text-white'>-</p>
                                        <p className='w-[70px] py-[4px] text-center bg-5F5 dark:bg-138'>{val?.packagesToOrder}</p>
                                        <p 
                                            onClick={()=> {
                                                const v =  items.map(x => {
                                                    if(x?.id === val?.id){
                                                        x.packagesToOrder ++
                                                    }
                                                    return x
                                                })

                                                setItems(v)
                                            }}
                                            className='py-[5px] px-3 cursor-pointer rounded-r-md bg-5F3 text-white'>+</p>
                                    </div>
                                </Td>
                                <Td>{val?.itemsPkg}</Td>
                                <Td className={'!border-none'}>
                                            <button 
                                                className='flex justify-between w-[100px] hover:border-gray-700 cursor-pointer items-center border-1 border-6E6 dark:border-333 rounded-[5px] py-[6px] px-[10px]'
                                                onClick={(e)=> {
                                                    e.stopPropagation()
                                                    setToggleMenu(toggleMenu ? null : e.currentTarget)
                                                    setMenuIndex(val?.id)
                                                }}
                                            >
                                                {val?.measurementUnitId 
                                                    ? measurementUnitsList.filter(x => x.id === val?.measurementUnitId)[0]?.name 
                                                    : 'choose'
                                                }
                                                <AiOutlineCaretDown className='text-[12px] text-gray-500'/>
                                            </button>
                                           

                                        {menuIndex === val?.id &&<CustomPopper openPopper={toggleMenu} handleClose={()=>setToggleMenu(null)} className='z-[1500]' placement='bottom'>
                                            <div className='max-h-[150px] w-[100px] mt-1 py-3 font-bold flex flex-col gap-1 text-[#738583] text-[12px] bg-white'>

                                                {measurementUnitsList?.map(unit => (
                                                    <ClickableItem 
                                                        className={'py-1'} 
                                                        text={unit.name} 
                                                        handleClick={() => {
                                                            const v =  items.map(x => {
                                                                if(x?.id === val?.id){
                                                                    x.measurementUnitId = unit.id
                                                                }
                                                                setToggleMenu(null)
                                                                return x
                                                            })
                                                            setItems(v)
                                                        }} 
                                                    />
                                                ))}
                                               
                                            </div>          
                                        </CustomPopper>}
                                </Td>
                                

                            </Tr>
                        ))
                        :
                        <tr>
                           <td colSpan={6} className='text-A5A text-center pt-10'> Get items from the previous page</td>
                        </tr>
                    }
                </tbody>
            </table>
        </TableWrapper>
    </div>
  )
}

export default ConfirmRequest